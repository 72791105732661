import React, { useEffect, useState } from 'react';
import { firestore } from 'firebase/app';
import { ReactSortable } from 'react-sortablejs';
import {
	Heading,
	Loader,
	Icon,
	Modal,
	Input,
	Button,
	DeleteModal,
	Toast,
} from 'common/components';
import { Camera } from 'common/icons';
import {
	deleteGalleryItem,
	updateGallery,
} from 'common/utils/firebase-tools.js';
import AddPhotoModal from './AddPhotoModal';
import './GalleryAdmin.scss';

const GalleryAdmin = () => {
	const [pictures, setPictures] = useState([]);
	const [editDescription, setEditDescription] = useState({
		show: false,
		picture: null,
		description: '',
	});
	const [loading, setLoading] = useState(false);
	const [deleteModal, setDeleteModal] = useState({
		show: false,
		picture: {},
	});
	const [toast, setToast] = useState({
		show: false,
		type: '',
		message: '',
	});
	const [showAddModal, setShowAddModal] = useState(false);
	const [canSubmit, setCanSubmit] = useState(false);

	useEffect(() => {
		const gallerySubscription = firestore()
			.collection('gallery')
			.orderBy('index', 'desc')
			.onSnapshot((querySnapshot) => {
				if (querySnapshot) {
					const tempGalleryItems = [];
					querySnapshot.forEach((snapshot) => {
						tempGalleryItems.push({
							id: snapshot.id,
							...snapshot.data(),
						});
					});
					setPictures(tempGalleryItems);
				}
			});

		return gallerySubscription;
	}, []);

	const handleOrderSubmit = async () => {
		setLoading(true);
		await updateGallery(pictures);
		setLoading(false);
	};

	const handleCloseToast = () => {
		setToast({
			show: false,
			type: '',
			message: '',
		});
	};

	const handleAddToast = (success) => {
		setToast({
			show: true,
			type: success ? 'success' : 'error',
			message: success ? 'Image added!' : 'Error adding image',
		});
		setShowAddModal(false);
	};

	const handleDescriptionEdit = (picture) => {
		setEditDescription({
			show: true,
			pictureId: picture.id,
			description: picture.description,
		});
	};

	const handleDescriptionModalClose = () => {
		setEditDescription({
			show: false,
			pictureId: null,
			description: '',
		});
	};

	const handleDescriptionChange = (text) => {
		setEditDescription({
			...editDescription,
			description: text,
		});
	};

	const handleSubmitNewDescription = async () => {
		const { description, pictureId } = editDescription;
		try {
			const picIndex = pictures.findIndex((pic) => pic.id === pictureId);
			const picturesCopy = [...pictures];
			const newPic = {
				...picturesCopy[picIndex],
				description,
			};
			picturesCopy[picIndex] = newPic;
			setPictures(picturesCopy);
			if (!canSubmit) {
				setCanSubmit(true);
			}
			handleDescriptionModalClose();
		} catch (err) {
			console.log('ERROR!! ==', err);
		}
	};

	const handleDeleteModalOpen = (picture) => {
		setDeleteModal({
			show: true,
			picture,
		});
	};

	const handleDeleteModalClose = () => {
		setDeleteModal({
			show: false,
			picture: {},
		});
	};

	const handleDeleteImage = async (picture) => {
		try {
			const picIndex = pictures.findIndex((pic) => pic.id === picture.id);
			const picturesCopy = [...pictures];
			picturesCopy.splice(picIndex, 1);
			await deleteGalleryItem(picture, picturesCopy);
			setToast({
				show: true,
				type: 'success',
				message: 'Image successfully deleted',
			});
		} catch (err) {
			setToast({
				show: true,
				type: 'error',
				message: 'Error removing image',
			});
		}
	};

	const onDragEnd = () => {
		if (!canSubmit) {
			setCanSubmit(true);
		}
	};

	const renderDesriptionEditModal = () => {
		return (
			<Modal
				show={editDescription.show}
				title="Edit description"
				handleModalClose={handleDescriptionModalClose}
			>
				<Input
					className="gallery-admin-modal__description"
					label="Description"
					value={editDescription.description}
					onChange={handleDescriptionChange}
					defaultValue={editDescription.description}
					multiline
					rows={5}
				/>
				<Button
					className="gallery-admin-modal__submit"
					onClick={handleSubmitNewDescription}
					loading={loading}
				>
					{'Update'}
				</Button>
			</Modal>
		);
	};

	const renderCardCTAs = (item) => (
		<div className="content-box__cta-container">
			<Icon
				name="edit"
				color="#3c3c3c"
				size="1x"
				onClick={() => handleDescriptionEdit(item)}
			/>
			<Icon
				name="delete"
				color="#C70000"
				size="1x"
				onClick={() => handleDeleteModalOpen(item)}
			/>
		</div>
	);

	const renderPageCTAs = () => (
		<div className="gallery-admin__cta-container">
			<Button
				className="gallery-admin__add-btn"
				onClick={() => setShowAddModal(true)}
				primary
			>
				{'Add photo'}
			</Button>
			<Button
				onClick={handleOrderSubmit}
				green
				loading={loading}
				disabled={!canSubmit}
			>
				{'Save'}
			</Button>
		</div>
	);

	return (
		<div>
			{renderDesriptionEditModal()}
			<AddPhotoModal
				show={showAddModal}
				onClose={() => setShowAddModal(false)}
				length={pictures.length}
				onComplete={handleAddToast}
			/>
			<DeleteModal
				id={deleteModal.picture}
				showModal={deleteModal.show}
				onModalClose={handleDeleteModalClose}
				onDelete={handleDeleteImage}
			/>
			<Toast show={toast.show} type={toast.type} onClose={handleCloseToast}>
				{toast.message}
			</Toast>
			<div className="gallery-admin">
				<Heading icon={<Camera />}>{'GalleryAdmin'}</Heading>
				{renderPageCTAs()}
				{pictures.length ? (
					<ReactSortable
						className="gallery-admin__grid"
						list={pictures}
						setList={setPictures}
						animation={300}
						onEnd={onDragEnd}
						easing="cubic-bezier(0.25, 1, 0.5, 1)"
					>
						{pictures.map((item) => (
							<div className="gallery-admin__item content-box" key={item.id}>
								<div className="inner">
									<img className="content-box__thumb" src={item.url} />
									<p className="content-box__description">{item.description}</p>
									{renderCardCTAs(item)}
								</div>
							</div>
						))}
					</ReactSortable>
				) : (
					<div className="gallery-admin--loading">
						<Loader type="bar" />
					</div>
				)}
			</div>
		</div>
	);
};

export default GalleryAdmin;
