export const HANDLE_MAP_REQUEST = 'HANDLE_MAP_REQUEST';
export const FETCH_DISTRICTS_SUCCESS = 'FETCH_DISTRICTS_SUCCESS';
export const FETCH_CHIEFDOMS_SUCCESS = 'FETCH_CHIEFDOMS_SUCCESS';
export const FETCH_MAP_FAILURE = 'FETCH_MAP_FAILURE';
export const HANDLE_DISTRICT_SELECT = 'HANDLE_DISTRICT_SELECT';
export const HANDLE_DISTRICT_VIEW = 'HANDLE_DISTRICT_VIEW';
export const SET_VIEWPORT_VIEW_CHANGE = 'SET_VIEWPORT_VIEW_CHANGE';
export const SELECT_EDIT_ACTION = 'SELECT_EDIT_ACTION';
export const SELECT_EDIT_DISTRICT = 'SELECT_EDIT_DISTRICT';
export const SELECT_EDIT_CHIEFDOM = 'SELECT_EDIT_CHIEFDOM';
export const SET_NEW_LAT_LNG = 'SET_NEW_LAT_LNG';
export const SELECT_ICON = 'SELECT_ICON';
export const SET_NEW_CHIEFDOM_LABEL = 'SET_NEW_CHIEFDOM_LABEL';
export const ADD_TEMP_ICON = 'ADD_TEMP_ICON';
export const CLEAR_EDITOR = 'CLEAR_EDITOR';
export const SET_TEMP_QUANTITY = 'SET_TEMP_QUANTITY';
export const FETCH_DISTRICT_BORDERS_SUCCESS = 'FETCH_DISTRICT_BORDERS_SUCCESS';
export const FETCH_CHIEFDOM_BORDERS_SUCCESS = 'FETCH_CHIEFDOM_BORDERS_SUCCESS';