import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from './store';

import './index.scss';
import App from './App';
import 'firebase/firestore';
import { startFirebaseApp } from 'common/utils/firebase-tools.js';

startFirebaseApp();

const rootElement = document.getElementById('root');
ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<BrowserRouter>
				<CookiesProvider>
					<App />
				</CookiesProvider>
			</BrowserRouter>
		</PersistGate>
	</Provider>,
	rootElement
);
