import {
	FETCH_USER_SUCCESS,
	SET_USER_LOGOUT,
} from '../types/userTypes';

export const fetchUserSuccess = user => ({
	type: FETCH_USER_SUCCESS,
	payload: user
});

export const setUserLogout = () => ({
	type: SET_USER_LOGOUT
});
