import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from 'common/components';
import './MapKeyItem.scss';

const MapKeyItem = ({
	isRegion,
	color,
	icon,
	description,
	districtLead,
	size,
}) => {
	const boxColorClassnames = classnames(
		'key-item__box',
		color === 'orange' && 'key-item__box--orange',
		color === 'blue' && 'key-item__box--blue',
		color === 'purple' && 'key-item__box--purple',
		color === 'yellow' && 'key-item__box--yellow'
	);

	const descriptionClasses = classnames(
		'key-item__text',
		icon && 'key-item__text--icon'
	);

	if (isRegion)
		return (
			<div className="key-item">
				<div className={boxColorClassnames} />
				<div className={descriptionClasses}>
					<span>{description}</span>
					<span>{districtLead}</span>
				</div>
			</div>
		);

	return (
		<div className="key-item key-item--icon">
			<Icon className="key-item__box" size={size} name={icon} />
			<div className={descriptionClasses}>
				<span>{description}</span>
			</div>
		</div>
	);
};

MapKeyItem.propTypes = {
	isRegion: PropTypes.bool,
	color: PropTypes.string,
	icon: PropTypes.string,
	description: PropTypes.string.isRequired,
	districtLead: PropTypes.string,
	size: PropTypes.string,
};

MapKeyItem.defaultProps = {
	isRegion: false,
	color: '',
	icon: '',
	districtLead: '',
	size: '',
};

export default MapKeyItem;
