import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { emailSchema } from 'common/yup/schemas';
import { SubHeading, Input, Chip } from 'common/components';

export const StepFour = ({
	emails,
	setEmails,
	notification,
	setNotification,
}) => {
	const [inputValue, setInputValue] = useState('');
	const [emailError, setEmailError] = useState('');

	const handleUserKeyPress = useCallback((event) => {
		const { key } = event;
		if ((key === 'Enter' || key === ',') && inputValue) {
			const newArray = [...emails, inputValue];
			yup
				.reach(emailSchema, 'email')
				.validate(newArray[newArray.length - 1])
				.then(() => {
					if (emailError) setEmailError(false);
					setEmails(newArray);
					setInputValue('');
				})
				.catch((err) => {
					setEmailError(err.message);
				});
		}
		return;
	});

	useEffect(() => {
		window.addEventListener('keydown', handleUserKeyPress);

		return () => {
			window.removeEventListener('keydown', handleUserKeyPress);
		};
	}, [handleUserKeyPress]);

	const handleInputChange = (e) => {
		setInputValue(e);
		if (emailError) {
			setEmailError(false);
		}
	};

	const handleEmailDelete = (index) => {
		setEmails(emails.filter((e, i) => i !== index));
	};

	const handleNotificationQuantityChange = (quantity) => {
		setNotification({
			...notification,
			quantity,
		});
	};

	const handleNotificationUnitChange = (e) => {
		setNotification({
			...notification,
			unit: e.target.value,
		});
	};

	return (
		<div className="add-event__step-four">
			<SubHeading>{'Email notifications (optional)'}</SubHeading>
			<Input
				value={inputValue}
				onChange={handleInputChange}
				placeholder={'email'}
				helperText={emailError || 'Press Enter or , to add the email'}
				error={!!emailError}
				fullWidth
				marginBottom={8}
			/>
			{!!emails.length && (
				<>
					<div className="add-event__step-four__emails">
						{emails.map((email, index) => (
							<Chip
								key={email}
								label={email}
								onDelete={() => handleEmailDelete(index)}
							/>
						))}
					</div>
					<SubHeading>{'Notification'}</SubHeading>
					<div className="add-event__step-four__notifications">
						<Input
							className="add-event__step-four__notifications__quantity"
							type="number"
							value={notification.quantity}
							onChange={handleNotificationQuantityChange}
						/>
						<select
							className="add-event__step-four__notifications__units"
							value={notification.unit}
							onChange={handleNotificationUnitChange}
						>
							<option value="minutes">{'minutes'}</option>
							<option value="hours">{'hours'}</option>
							<option value="weeks">{'weeks'}</option>
						</select>
					</div>
					<span className="add-event__step-four__notifications__message">
						{
							'All recipients will receive an email on creation of the event, and a notification '
						}
						<strong
							style={{ fontFamily: 'Courier New' }}
						>{`${notification.quantity} ${notification.unit} `}</strong>
						{'before.'}
					</span>
				</>
			)}
		</div>
	);
};

StepFour.propTypes = {
	emails: PropTypes.array.isRequired,
	setEmails: PropTypes.func.isRequired,
	notification: PropTypes.shape({
		quantity: PropTypes.number,
		unit: PropTypes.string,
	}).isRequired,
	setNotification: PropTypes.func.isRequired,
};
