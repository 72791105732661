import React from 'react';
import Heading from './Heading';
import { Twitter } from 'common/icons';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import './TwitterTimeline.scss';

const TwitterTimeline = () => (
	<div className="homepage-twitter-timeline">
		<Heading isSubHeading icon={<Twitter size={30} color="#1DA1F2" />}>
			{'Twitter Feed'}
		</Heading>
		<div className="homepage-twitter-timeline__container">
			<TwitterTimelineEmbed
				sourceType="profile"
				userId={2916323449}
				options={{ height: 650 }}
			/>
		</div>
	</div>
);

export default TwitterTimeline;
