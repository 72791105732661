import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setFilters } from 'store/actions/publicationActions';

import './Tag.scss';

const Tag = ({ tag, onClick }) => {
	const dispatch = useDispatch();
	const handleTagClick = () => {
		if (onClick) {
			onClick();
		} else {
			dispatch(
				setFilters({
					keywords: [tag],
				})
			);
		}
	};
	return (
		<a className="tag" onClick={handleTagClick}>
			{tag}
		</a>
	);
};

Tag.propTypes = {
	tag: PropTypes.string.isRequired,
	onClick: PropTypes.func,
};

Tag.defaultProps = {
	onClick: null,
};

export default Tag;
