const transformFirstLetter = word => (
	word.charAt(0).toUpperCase() + word.slice(1)
);

export const capitalizeFirstLetters = string => {
	if (/-/g.test(string)) {
		let stg = string
			.split('-')
			.map(word => transformFirstLetter(word));
		return stg.join(' ');
	}
	return transformFirstLetter(string);
};