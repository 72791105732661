import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { Toast } from 'common/components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichTextEditor.scss';

const editorLabels = {
	'components.controls.blocktype.h6': 'Image Caption',
};

const RichTextEditor = ({
	description,
	onChange,
	uploadImages,
	onAddImage,
}) => {
	const [imageSizeError, setImageSizeError] = useState(false);

	useEffect(() => {
		const html = stateToHTML(description.getCurrentContent());
		if (uploadImages.length)
			uploadImages.forEach((image, index) => {
				if (!html.includes(image.localSrc)) {
					const newArray = uploadImages;
					uploadImages.splice(index, 1);
					onAddImage(newArray);
				}
			});
	}, [description, uploadImages]);

	const testBlockType = (contentBlock) => {
		const type = contentBlock.getType();
		if (type === 'header-six') return 'image-caption';
	};

	const uploadImageCallback = (file) => {
		if (file.size > 4000000) {
			setImageSizeError(true);
			return;
		}
		let uploadedImages = uploadImages;
		const imageObject = {
			file,
			localSrc: URL.createObjectURL(file),
		};

		uploadedImages.push(imageObject);

		onAddImage(uploadedImages);

		return new Promise((resolve) => {
			resolve({ data: { link: imageObject.localSrc } });
		});
	};

	return (
		<div className="editor">
			<Toast
				show={imageSizeError}
				type="error"
				duration={3000}
				onClose={() => setImageSizeError(false)}
			>
				{'Image too large'}
			</Toast>
			<Editor
				localization={{ locale: 'en', translations: editorLabels }}
				editorState={description}
				wrapperClassName="editor__wrapper"
				toolbarClassName="editor__toolbar"
				editorClassName="editor__input-field"
				onEditorStateChange={onChange}
				stripPastedStyles={true}
				blockStyleFn={testBlockType}
				toolbar={{
					options: [
						'inline',
						'blockType',
						'fontSize',
						'image',
						'link',
						'list',
						'textAlign',
						'history',
					],
					inline: {
						className: 'editor__toolbar__inline',
					},
					fontSize: {
						options: [16, 20, 25, 30],
					},
					image: {
						previewImage: true,
						className: 'inline-image',
						uploadCallback: uploadImageCallback,
						inputAccept: 'image/jpeg, image/jpg, image/png',
						alignmentEnabled: false,
						alt: {
							present: true,
							mandatory: true,
						},
						defaultSize: {
							width: 500,
						},
					},
				}}
			/>
		</div>
	);
};

RichTextEditor.propTypes = {
	onChange: PropTypes.func.isRequired,
	description: PropTypes.shape({
		getCurrentContent: PropTypes.func,
	}).isRequired,
	uploadImages: PropTypes.array.isRequired,
	onAddImage: PropTypes.func.isRequired,
};

export default RichTextEditor;
