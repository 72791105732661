import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const MotherBaby = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={80}
		height={80}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 35.998 47.996"
	>
		<g data-name="Layer 2">
			<path d="M29.608 19.816a1.98 1.98 0 00-1.61-.82h-17.79a2 2 0 00-1.77 1.07l-8.21 16.52a2.008 2.008 0 00.42 2.41s12.2 9 17.35 9a2.826 2.826 0 002.25-1.13 3.267 3.267 0 00.75-1.87c0-1 0-3-3-4-4.15-1.59-7.07-2.41-8.17-3-.59-.31-1.03-.83-.72-1.42a18.548 18.548 0 001.89-7.58c0-2 1-3 2-3s2 1 2 3c0 3.36 5.04 7.42 6.62 8.64a1.078 1.078 0 01.37.89c0 1.47-.17 4.47 2.51 4.47 3.5 0 11.5-5 11.5-10 0-4.09-4.69-10.86-6.39-13.18zm-5.61 14.18a4 4 0 114-4 3.999 3.999 0 01-4 4z"></path>
			<circle cx="18.998" cy="7" r="7"></circle>
		</g>
	</svg>
);

export default MotherBaby;