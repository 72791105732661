import React from 'react';
import PropTypes from 'prop-types';
import { Redirect as RouterRedirect } from 'react-router-dom';

const Redirect = ({ shouldRedirect, to }) => {
	if (!shouldRedirect) return null;
	return <RouterRedirect to={to} />;
};

Redirect.propTypes = {
	shouldRedirect: PropTypes.bool.isRequired,
	to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired
};

export default Redirect;