import React, { useState, useEffect } from 'react';
import { firestore } from 'firebase/app';
import { Loader } from 'common/components';
import './Gallery.scss';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import ImageCarousel from 'common/components/ImageCarousel';

const Photos = () => {
	const [loadingPhotos, setLoadingPhotos] = useState(false);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [photos, setPhotos] = useState([]);

	const getImagesFromFirestore = async () => {
		setLoadingPhotos(true);
		const images = await firestore()
			.collection('gallery')
			.orderBy('index', 'desc')
			.get();

		const imageData = [];

		images.docs.forEach((doc) => {
			imageData.push({
				original: doc.data().url,
				thumbnail: doc.data().url,
				text: doc.data().description,
			});
		});

		setPhotos(imageData);
		setLoadingPhotos(false);
	};

	useEffect(() => {
		getImagesFromFirestore();
	}, []);

	return loadingPhotos ? (
		<Loader className="gallery-loader" />
	) : (
		<>
			{!!photos.length && (
				<>
					<p className="gallery__caption">{photos[currentIndex]?.text}</p>
					<ImageCarousel
						photos={photos}
						onSlide={(index) => setCurrentIndex(index)}
						showFullscreenButton={true}
						showPlayButton={true}
						showThumbnails={true}
					/>
				</>
			)}
		</>
	);
};

export default Photos;
