import React, { useEffect, useRef, useState } from 'react';
import Scrollspy from 'react-scrollspy';
import { SuccessList, DocumentsList, Modal } from 'common/components';
import { MagnifyingGlass } from 'common/icons';
import Search from './Search';
import './PublicationsContainer.scss';
import AnnualReviews from 'common/components/AnnualReviews/AnnualReviewsList';
import { DOCTYPE_DICTIONARY } from 'common/utils/docType-dictionary';
import { useParams } from 'react-router-dom';

const scrollToRef = (ref) =>
	window.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' });

const PublicationsContainer = () => {
	const { resourceType } = useParams();
	const [showToc, setShowToc] = useState(false);
	const successRef = useRef(null);
	const documentsRef = useRef(null);
	const searchRef = useRef(null);
	const annualReviewsRef = useRef(null);
	const [docLoadingState, setDocLoadingState] = useState({
		[DOCTYPE_DICTIONARY.dt1]: false,
		[DOCTYPE_DICTIONARY.dt2]: false,
		[DOCTYPE_DICTIONARY.dt3]: false,
	});
	const allDocsHaveLoaded = Object.values(docLoadingState).every(
		(val) => !!val
	);
	const isResourceTypeDoctype = [
		DOCTYPE_DICTIONARY.dt1,
		DOCTYPE_DICTIONARY.dt2,
		DOCTYPE_DICTIONARY.dt3,
	].includes(resourceType);

	const contentsClicked = (ref) => {
		scrollToRef(ref);
		if (showToc) setShowToc(false);
	};

	const onLoadComplete = (docType) => {
		setDocLoadingState((prevState) => ({
			...prevState,
			[docType]: true,
		}));
	};

	useEffect(() => {
		if (isResourceTypeDoctype && allDocsHaveLoaded) {
			const refTypes = {
				[DOCTYPE_DICTIONARY.dt1]: documentsRef,
				[DOCTYPE_DICTIONARY.dt2]: successRef,
				[DOCTYPE_DICTIONARY.dt3]: annualReviewsRef,
			};
			contentsClicked(refTypes[resourceType]);
		}
	}, [allDocsHaveLoaded, isResourceTypeDoctype]);

	const renderScrollSpy = () => (
		<Scrollspy
			className="publications__table-of-contents__list"
			items={['search', 'success-stories', 'documents']}
			currentClassName="is-active"
		>
			<li onClick={() => contentsClicked(searchRef)}>{'Search'}</li>
			<li onClick={() => contentsClicked(successRef)}>{'Success Stories'}</li>
			<li onClick={() => contentsClicked(documentsRef)}>{'Publications'}</li>
			<li onClick={() => contentsClicked(annualReviewsRef)}>
				{'Annual Reviews'}
			</li>
		</Scrollspy>
	);

	const renderToC = () => (
		<div className="publications__table-of-contents">
			<div className="publications__table-of-contents__fixed">
				<span className="publications__table-of-contents__title">
					{'Contents'}
				</span>
				{renderScrollSpy()}
			</div>
		</div>
	);

	const renderMobileToc = () => (
		<>
			<div
				className="publications__mobile-toc"
				onClick={() => setShowToc(true)}
			>
				<MagnifyingGlass size={25} />
			</div>
			<Modal
				title="Contents"
				handleModalClose={() => setShowToc(false)}
				show={showToc}
			>
				{renderScrollSpy()}
			</Modal>
		</>
	);

	return (
		<div className="publications__container">
			{renderToC()}
			{renderMobileToc()}
			<Search ref={searchRef} />
			<div className="publications__lists">
				<SuccessList
					heading="Success Stories"
					ref={successRef}
					limit={6}
					onLoadComplete={onLoadComplete}
				/>
				<DocumentsList ref={documentsRef} onLoadComplete={onLoadComplete} />
				<AnnualReviews ref={annualReviewsRef} onLoadComplete={onLoadComplete} />
			</div>
		</div>
	);
};

export default PublicationsContainer;
