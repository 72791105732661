import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { EditorState, ContentState } from 'draft-js';
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';
import { stateToHTML } from 'draft-js-export-html';
import {
	RichTextEditor,
	Button,
	Heading,
	DeleteModal,
	Toast,
	Redirect,
} from 'common/components';
import DocTypeToggle from './DocTypeToggle';
import PublicationInput from './PublicationInput';
import Upload from './Upload';
import Options from './Options';
import { Books } from 'common/icons';
import { DOCTYPES } from 'common/utils/docType-dictionary';
import { timestampFromDate } from 'common/utils/format-date';
import {
	removeStoredItem,
	getPublication,
	createPublication,
	updatePublication,
} from 'common/utils/firebase-tools';
import {
	uploadDocument,
	uploadSuccessStory,
} from './utils/submit-publications';

import './Publish.scss';

const initialPubState = {
	title: '',
	url: '',
	docType: DOCTYPES.STORY,
	organisation: '',
	tags: [],
	uploadImages: [],
	docDescription: '',
	thumbnail: null,
	file: null,
};

const isValidUrl = (url) => {
	try {
		return Boolean(new URL(url));
	} catch (error) {
		return false;
	}
};

const Publish = ({ match }) => {
	const { id: publicationId, docType: docTypeParam } = match.params;
	const publishTitle = publicationId
		? 'Edit a publication'
		: 'Create a Publication';
	const { successStories, documents } = useSelector(
		(state) => state.publications
	);
	const [
		{
			title,
			url,
			docType,
			organisation,
			tags,
			uploadImages,
			docDescription,
			thumbnail,
			file,
		},
		setPubState,
	] = useState(initialPubState);
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [description, setDescription] = useState(() =>
		EditorState.createEmpty()
	);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [redirect, setRedirect] = useState(false);
	const [showSuccessToast, setShowSuccessToast] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (publicationId) {
			const docsToSearch =
				docTypeParam === DOCTYPES.DOCUMENT ? documents : successStories;
			const foundDoc = docsToSearch.find((doc) => doc._id === publicationId);
			if (foundDoc) {
				prefillInputs(foundDoc);
			} else {
				getPublicationFromFirebase();
			}
		} else {
			clearInputs();
		}
	}, [publicationId, docTypeParam]);

	useEffect(() => {
		const required = [
			title,
			docType,
			organisation,
			...(docType !== DOCTYPES.REVIEWS ? [description, thumbnail] : []),
			...(docType === DOCTYPES.DOCUMENT ? [file] : []),
			...(docType === DOCTYPES.REVIEWS ? [isValidUrl(url)] : []),
		];
		if (isSubmitDisabled) {
			if (required.every((e) => e)) setIsSubmitDisabled(false);
		} else if (required.some((e) => !e)) setIsSubmitDisabled(true);
	}, [title, docType, organisation, description, isSubmitDisabled, url]);

	const getPublicationFromFirebase = async () => {
		try {
			const pub = await getPublication(docTypeParam, publicationId);
			if (!pub.exists) {
				throw new Error();
			}
			prefillInputs(pub.data());
		} catch (err) {
			setRedirect(true);
		}
	};

	const handleInputChange = (input, value) => {
		setPubState((prevState) => ({ ...prevState, [input]: value }));
	};

	const clearInputs = () => {
		setPubState({ ...initialPubState });
	};

	const prefillInputs = (pub) => {
		if (docTypeParam === DOCTYPES.STORY) {
			const processedHTML = DraftPasteProcessor.processHTML(pub.description);
			const contentState = ContentState.createFromBlockArray(processedHTML);
			setDescription(EditorState.createWithContent(contentState));
		}
		setPubState((prevState) => ({
			...prevState,
			title: pub.title,
			docType: docTypeParam,
			organisation: pub.organisation,
			tags: pub.tags,
			thumbnail:
				pub?.thumbnail?.downloadUrl || pub?.image
					? pub?.thumbnail || pub?.image
					: null,
			...(docTypeParam === DOCTYPES.DOCUMENT && {
				docDescription: pub.description,
				file: pub?.file,
			}),
			url: pub?.url || '',
		}));
	};

	const handleOnSubmit = async () => {
		setLoading(true);

		// DOCUMENT UPLOAD =====
		if (docType === DOCTYPES.DOCUMENT) {
			try {
				await uploadDocument({
					data: {
						organisation,
						publication_date: timestampFromDate(),
						title,
						tags,
						description: docDescription || '',
					},
					file,
					publicationId,
					thumbnail: thumbnail?.downloadUrl || thumbnail,
				});
				setShowSuccessToast(true);
				setRedirect(true);
			} catch (err) {
				console.log(err);
				setLoading(false);
				setError(true);
			}
		}

		// SUCCESS STORY UPLOAD =====
		if (docType === DOCTYPES.STORY) {
			try {
				await uploadSuccessStory({
					publicationId,
					html: stateToHTML(description.getCurrentContent()),
					uploadImages,
					thumbnail: thumbnail?.downloadUrl || thumbnail,
					title,
					organisation,
					tags,
				});
				setLoading(false);
				setShowSuccessToast(true);
				setRedirect(true);
			} catch (err) {
				console.log(err.message);
				setError(true);
				setLoading(false);
			}
		}
		// ANNUAL REVIEWS UPLOAD =====
		if (docType === DOCTYPES.REVIEWS) {
			try {
				const fields = {
					url,
					title,
					organisation,
					tags,
					publication_date: timestampFromDate(),
					description: docDescription || '',
				};

				if (publicationId) {
					await updatePublication(DOCTYPES.REVIEWS, publicationId, fields);
				} else {
					await createPublication(DOCTYPES.REVIEWS, fields);
				}

				setShowSuccessToast(true);
				setRedirect(true);
			} catch (err) {
				setError(true);
			} finally {
				setLoading(false);
			}
		}
	};

	const handleDeleteBtn = () => {
		setShowDeleteModal(true);
	};

	const handleDeleteModalClose = () => {
		setShowDeleteModal(false);
	};

	const handleDeletePublication = () => {
		return removeStoredItem(docType, publicationId, docType);
	};

	const renderToast = () => {
		if (showSuccessToast || error) {
			return (
				<Toast
					show={showSuccessToast || error}
					type={showSuccessToast ? 'success' : 'error'}
					duration={2000}
					onClose={() =>
						showSuccessToast ? setShowSuccessToast(false) : setError(false)
					}
				>
					{showSuccessToast
						? "Great! That's published"
						: 'Server error (code: 500) submiting this publication'}
				</Toast>
			);
		}
		return null;
	};

	const renderDeleteModal = () => {
		if (!showDeleteModal) return null;
		return (
			<DeleteModal
				id={publicationId}
				label={title}
				showModal={showDeleteModal}
				onModalClose={handleDeleteModalClose}
				onDelete={handleDeletePublication}
				handleRedirect={() => setRedirect(true)}
			/>
		);
	};

	const renderInputs = () => (
		<>
			<DocTypeToggle
				docType={docType}
				onChange={(value) => handleInputChange('docType', value)}
				disabled={!!publicationId}
			/>

			{docType !== DOCTYPES.REVIEWS && (
				<Upload
					docType={docType}
					thumbnail={thumbnail}
					file={file}
					onChooseThumbnail={(value) => handleInputChange('thumbnail', value)}
					onChooseFile={(value) => handleInputChange('file', value)}
				/>
			)}
			{docType === DOCTYPES.REVIEWS && (
				<PublicationInput
					title="URL"
					placeholder="Enter the URL for the Annual Review..."
					value={url}
					onChange={({ target }) => handleInputChange('url', target.value)}
				/>
			)}

			<PublicationInput
				title="TITLE"
				placeholder="Enter a title for your publication..."
				value={title}
				onChange={({ target }) => handleInputChange('title', target.value)}
			/>
			{[DOCTYPES.DOCUMENT, DOCTYPES.REVIEWS].includes(docType) && (
				<PublicationInput
					title="DESCRIPTION"
					placeholder={`Write a small description about this ${
						docType === DOCTYPES.DOCUMENT ? 'document' : 'review'
					}...`}
					value={docDescription}
					onChange={({ target }) =>
						handleInputChange('docDescription', target.value)
					}
				/>
			)}
			<Options
				selectedOrganisation={organisation}
				onSelectOrganisation={(value) =>
					handleInputChange('organisation', value)
				}
				tags={tags}
				onAddTags={(value) => handleInputChange('tags', value)}
			/>
			{docType === DOCTYPES.STORY && (
				<>
					<span className="publish-description__title">DESCRIPTION</span>
					<RichTextEditor
						description={description}
						uploadImages={uploadImages}
						onAddImage={(value) => handleInputChange('uploadImages', value)}
						onChange={setDescription}
					/>
				</>
			)}
		</>
	);

	const renderButtons = () => (
		<div className="publication-creator__btn-container">
			{publicationId && (
				<Button
					className="publication-creator__btn-container__btn publication-creator__btn-container__btn--delete"
					warning
					onClick={handleDeleteBtn}
				>
					{docType === DOCTYPES.DOCUMENT ? 'Delete Document' : 'Delete Article'}
				</Button>
			)}
			<Button
				className="publication-creator__btn-container__btn"
				onClick={handleOnSubmit}
				loading={loading}
				disabled={isSubmitDisabled}
				primary
			>
				{publicationId ? 'Update' : 'Submit'}
			</Button>
		</div>
	);

	return (
		<>
			<Redirect shouldRedirect={redirect} to="/resources" />
			{renderToast()}
			{renderDeleteModal()}
			<div className="publication-creator">
				<Heading icon={<Books size={40} />}>{publishTitle}</Heading>
				{renderInputs()}
				{renderButtons()}
			</div>
		</>
	);
};

Publish.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string,
			docType: PropTypes.string,
		}),
	}),
};

Publish.defaultProps = {
	match: {
		params: {},
	},
};

export default Publish;
