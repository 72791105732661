import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';
import { EditButton, Tag, Icon, Toast } from 'common/components';
import { logEvent } from 'common/utils/firebase-tools.js';
import { dateFromTimestamp } from 'common/utils/format-date';
import { DOCTYPES } from 'common/utils/docType-dictionary';
import './AnnualReviewsCard.scss';

const AnnualReviewsCard = ({
	id,
	title,
	organisation,
	publicationDate,
	tags,
	description,
	shareLink,
}) => {
	const { role } = useSelector((state) => state.user);
	const canEdit = (role === 'ADMIN' || role === 'EDITOR') && id;
	const date =
		typeof publicationDate === 'string'
			? publicationDate
			: dateFromTimestamp(publicationDate);
	const [showCopyToast, setShowCopyToast] = useState(false);

	const handleDocumentAction = async (action) => {
		logEvent('document', {
			type: action,
			['document title']: title,
		});
		if (action === 'download') {
			window.open(shareLink, '_blank');
		} else {
			await copy(shareLink);
			setShowCopyToast(true);
		}
	};

	const renderDocumentTags = () => {
		if (!tags.length) return null;
		return (
			<div>
				{tags.map((tag) => (
					<Tag key={tag} tag={tag} />
				))}
			</div>
		);
	};

	return (
		<>
			<Toast
				show={showCopyToast}
				type="success"
				duration={2000}
				onClose={() => setShowCopyToast(false)}
			>
				{'Link copied to clipboard'}
			</Toast>
			<div className="review">
				{canEdit && <EditButton id={id} docType={DOCTYPES.REVIEWS} />}
				<div className="review__content">
					<div className="review__content__info">
						<span className="review__content__info__title">{title}</span>
						<span className="review__content__info__key">
							{'Published: '}
							<strong className="review__content__info__value">{date}</strong>
						</span>
						<span className="review__content__info__key">
							{'Organisation: '}
							<strong className="review__content__info__value">
								{organisation}
							</strong>
						</span>
					</div>
					{!!description && (
						<span className="review__content__description"> {description}</span>
					)}
					{renderDocumentTags()}
				</div>
				<div className="review__actions">
					<div className="review__actions__cta-container">
						{!!shareLink && (
							<Icon
								className="review__actions__cta-container__share"
								name="share"
								color="3c3c3c"
								size="lg"
								onClick={() => handleDocumentAction('share')}
							/>
						)}
						<a
							className="review__actions__cta-container__download"
							to="#"
							onClick={() => handleDocumentAction('download')}
						>
							{'Open review'}
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

AnnualReviewsCard.propTypes = {
	id: PropTypes.string,
	organisation: PropTypes.string.isRequired,
	publicationDate: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
	title: PropTypes.string.isRequired,
	tags: PropTypes.arrayOf(PropTypes.string),
	file: PropTypes.shape({
		downloadUrl: PropTypes.string,
	}).isRequired,
	description: PropTypes.string,
	thumbnail: PropTypes.shape({
		downloadUrl: PropTypes.string,
	}),
	shareLink: PropTypes.string,
};

AnnualReviewsCard.defaultProps = {
	tags: [],
	publicationDate: undefined,
	id: undefined,
	description: '',
	thumbnail: {},
	shareLink: undefined,
};

export default AnnualReviewsCard;
