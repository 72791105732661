import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const Nurse = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={80}
		height={80}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 34 44"
	>
		<path d="M25 24.257v6.927a3 3 0 11-2 0v-7.628A14.974 14.974 0 0019 23h-4a14.975 14.975 0 00-4 .556V31h1.002A3.998 3.998 0 0116 34.998V41a1 1 0 01-1 1h-2a1 1 0 010-2h1.007v-5a2 2 0 00-2-2H7.998a2.01 2.01 0 00-2.005 2.005V40H7a1 1 0 010 2H5a1 1 0 01-1-1v-6a4 4 0 014-4h1v-6.744A14.997 14.997 0 000 38v6h34v-6a14.997 14.997 0 00-9-13.743z"></path>
		<circle cx="17" cy="10" r="10"></circle>
	</svg>
);

export default Nurse;