import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const TechSupport = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={80}
		height={80}
		fill={montroseBlue}
		x="0"
		y="0"
		enableBackground="new 0 0 48 48"
		viewBox="0 0 48 48"
	>
		<path d="M43.8 17.1C42.2 6.2 32.1-1.4 21.2.2 12.4 1.5 5.6 8.3 4.3 17.1 1.8 17.4 0 19.5 0 22v9c0 2.8 2.2 5 5 5h4c1.1 0 2-.9 2-2V19c0-1.1-.9-2-2-2h-.7C10 8.3 18.4 2.6 27 4.3c6.4 1.2 11.5 6.3 12.7 12.7H39c-1.1 0-2 .9-2 2v15c0 1.1.9 2 2 2h1c-.5 3.5-3.5 6-7 6h-3.5c-1.1-1.9-3.6-2.6-5.5-1.5s-2.6 3.6-1.5 5.5 3.6 2.6 5.5 1.5c.6-.4 1.1-.9 1.5-1.5H33c5.7 0 10.5-4.4 11.1-10.1 2.3-.5 4-2.5 4-4.9v-9c-.1-2.4-1.9-4.5-4.3-4.9z"></path>
	</svg>
);

export default TechSupport;