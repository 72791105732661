import { FETCH_USER_SUCCESS, SET_USER_LOGOUT } from '../types/userTypes';

const initialState = {
	email: '',
	role: 'GUEST',
	id: '',
	loading: false,
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_USER_SUCCESS:
			return {
				...state,
				loading: false,
				email: action.payload.email,
				role: action.payload.role,
				id: action.payload.id,
			};
		case SET_USER_LOGOUT:
			return initialState;
		default:
			return state;
	}
};

export default userReducer;
