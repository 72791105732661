import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';
import {
	AboutTheProgramme,
	Calendar,
	Gallery,
	GalleryAdmin,
	Home,
	Login,
	ManageAccounts,
	MapAdmin,
	Navigation,
	Partners,
	Publication,
	Resources,
	SetupAccount,
	WhatWeDo,
	VideoAdmin,
	Activities,
} from './pages';
import {
	PublishRoute,
	AdminRoute,
	SavingBanner,
	Footer,
	CookieBanner,
	Toast,
} from 'common/components';
import { fetchUserSuccess, setUserLogout } from 'store/actions/userActions';
import {
	setCurrentScreen,
	logEvent,
	getUser,
	onAuthStateChanged,
	initialiseAnalytics,
} from 'common/utils/firebase-tools.js';

import './App.scss';

moment.suppressDeprecationWarnings = true;

const App = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const { analytics } = useSelector((state) => state.gdpr);
	const { id: loggedId, email } = useSelector((state) => state.user);
	const [showUserToast, setShowUserToast] = useState(false);

	useEffect(() => {
		const authSubscription = onAuthStateChanged(onAuthChanged);
		moment.tz.setDefault('Africa/Freetown');
		return authSubscription;
	}, []);

	useEffect(() => {
		setCurrentScreen(location.pathname);
		logEvent('screen_view');
	}, [location]);

	useEffect(() => {
		if (analytics) {
			initialiseAnalytics();
		}
	}, [analytics]);

	const onAuthChanged = async (user) => {
		if (!user) dispatch(setUserLogout());
		if (user?.uid && user?.uid !== loggedId) {
			const userData = await getUser(user.uid);
			if (userData.data()) {
				dispatch(
					fetchUserSuccess({
						...userData.data(),
						id: user.uid,
					})
				);
			}
			setShowUserToast(true);
		}
	};

	return (
		<>
			<Helmet>
				<title>{'Saving lives in Sierra Leone'}</title>
				<meta
					property="og:image"
					content="https://firebasestorage.googleapis.com/v0/b/slisl-dev.appspot.com/o/static%2Fhomepage_1200.jpg?alt=media&token=65f237df-046e-4b51-aff4-a234f3dd3780"
				/>
			</Helmet>
			<Toast
				show={showUserToast}
				type="success"
				duration={3000}
				onClose={() => setShowUserToast(false)}
			>
				{`Welcome ${email}!`}
			</Toast>
			<CookieBanner />
			<div className="app">
				{process.env.REACT_APP_ENVIRONMENT === 'staging' && (
					<div id="staging-banner">
						{
							'This is a development version of the site, please do not share links from this environment'
						}
					</div>
				)}
				<Navigation />
				<SavingBanner />
				<div className="app__pages">
					<Switch>
						<PublishRoute path="/admin/publish/:id/:docType" />
						<PublishRoute path="/admin/publish" />
						<AdminRoute path="/admin/accounts">
							<ManageAccounts />
						</AdminRoute>
						<AdminRoute path="/admin/map">
							<MapAdmin />
						</AdminRoute>
						<AdminRoute path="/admin/events">
							<Calendar isEditor />
						</AdminRoute>
						<AdminRoute path="/admin/gallery">
							<GalleryAdmin />
						</AdminRoute>
						<AdminRoute path="/admin/video">
							<VideoAdmin />
						</AdminRoute>
						<Route path="/account-setup" component={SetupAccount} />
						<Route path="/login" component={Login} />
						<Route path="/about" component={AboutTheProgramme} />
						<Route path="/what-we-do" component={WhatWeDo} />
						<Route path="/events" component={Calendar} />
						<Route
							path={[
								'/resources/:docType/:id',
								'/resources/:resourceType',
								'/resources',
							]}
							component={Resources}
						/>
						<Route path="/partners" component={Partners} />
						<Route path="/activities" component={Activities} />
						<Route path="/" component={Home} />
					</Switch>
				</div>
				<Footer />
			</div>
		</>
	);
};

export default App;
