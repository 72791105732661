import React, { forwardRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Button, Autocomplete } from 'common/components';
import {
	timestampFromDate,
	areTimestampsEqual,
} from 'common/utils/format-date';
import { Chevron } from 'common/icons';
import { setFilters, clearFilters } from 'store/actions/publicationActions';
import { getOrganisations } from '../../../store/reducers/publicationReducer';
import { logEvent } from 'common/utils/firebase-tools.js';
import AdvancedFilters from './AdvancedFilters';
import './Search.scss';

const FROM = timestampFromDate('01/01/2020');
const TO = timestampFromDate();

const Search = (props, ref) => {
	const dispatch = useDispatch();
	const [keywords, setKeywords] = useState([]);
	const [fromDate, setFromDate] = useState(FROM);
	const [toDate, setToDate] = useState(TO);
	const [isFiltersOpen, setIsFiltersOpen] = useState(false);
	const [open, setOpen] = useState(false);
	const {
		from,
		to,
		keywords: storedKeywords,
	} = useSelector((state) => state.publications.filters);
	const organisations = useSelector(getOrganisations);
	const loading = open && !organisations.length;

	useEffect(() => {
		if (storedKeywords.length > 0 || from || to) {
			dispatch(clearFilters());
		}
	}, []);

	useEffect(() => {
		if (storedKeywords.length) {
			setKeywords(storedKeywords);
		}
	}, [storedKeywords]);

	const handleSearchClick = () => {
		if (keywords.length)
			logEvent('search filter', {
				keywords,
			});
		if (fromDate !== FROM || toDate !== moment())
			logEvent('date filter', {
				from: moment(fromDate).format('DD-MM-YYYY'),
				to: moment(toDate).format('DD-MM-YYYY'),
			});

		dispatch(
			setFilters({
				keywords,
				from: fromDate,
				to: toDate,
			})
		);
	};

	const handleReset = () => {
		setKeywords([]);
		setFromDate(FROM);
		setToDate(TO);
		dispatch(clearFilters());
	};

	const renderClearBtn = () => {
		if (
			keywords?.length > 0 ||
			fromDate !== FROM ||
			!areTimestampsEqual(toDate, moment()) ||
			from ||
			to
		)
			return (
				<span className="publication-search__btn__clear" onClick={handleReset}>
					{'Reset'}
				</span>
			);
	};

	return (
		<div ref={ref} id="search" className="publication-search">
			<div className="publication-search__filters">
				<Autocomplete
					options={organisations}
					onChange={setKeywords}
					value={keywords}
					loading={loading}
					label={'Search for keywords or organisations...'}
					width={500}
					isTags
					onOpen={() => setOpen(true)}
					onClose={() => setOpen(false)}
				/>
				<div
					className="publication-search__filters__more-toggle"
					onClick={() => setIsFiltersOpen(!isFiltersOpen)}
				>
					<span>{'Advanced search'}</span>
					<Chevron size={10} color={'#0072c7'} isOpen={isFiltersOpen} />
				</div>
				<AdvancedFilters
					isActive={isFiltersOpen}
					fromDate={fromDate.toDate()}
					onFromChange={(date) => setFromDate(timestampFromDate(date))}
					toDate={toDate.toDate()}
					onToChange={(date) => setToDate(timestampFromDate(date))}
				/>
			</div>
			<div className="publication-search__btn">
				<Button
					className="publication-search__btn__submit"
					primary
					onClick={handleSearchClick}
				>
					{'Search'}
				</Button>
				{renderClearBtn()}
			</div>
		</div>
	);
};

export default forwardRef(Search);
