import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { DatePicker } from 'common/components';
import './AdvancedFilters.scss';

const AdvancedFilters = ({
	isActive,
	fromDate,
	toDate,
	onFromChange,
	onToChange,
}) => {
	const advancedClassnames = classnames(
		'advanced-filters',
		isActive && 'advanced-filters--isOpen'
	);

	return (
		<div className={advancedClassnames}>
			<div className="advanced-filters__dates">
				<DatePicker label={'From'} date={fromDate} onChange={onFromChange} />
				<DatePicker label={'To'} date={toDate} onChange={onToChange} />
			</div>
		</div>
	);
};

AdvancedFilters.propTypes = {
	className: PropTypes.string,
	isActive: PropTypes.bool,
	fromDate: PropTypes.shape({}).isRequired,
	toDate: PropTypes.shape({}).isRequired,
	onFromChange: PropTypes.func.isRequired,
	onToChange: PropTypes.func.isRequired,
};

AdvancedFilters.defaultProps = {
	className: '',
	isActive: false,
};

export default AdvancedFilters;
