import { createSlice } from '@reduxjs/toolkit';
import { fetchEvents, modifyEvent, deleteEvent } from '../actions/events';

const initialState = {
	events: [],
	error: false,
};

const eventsSlice = createSlice({
	name: 'events',
	initialState,
	reducers: {
		clearError(state) {
			state.error = '';
		},
	},
	extraReducers: {
		[fetchEvents.fulfilled]: (state, { payload }) => {
			state.events = payload;
		},
		[fetchEvents.rejected]: (state, { payload }) => {
			state.error = payload;
		},
		[modifyEvent.fulfilled]: (state, { payload }) => {
			state.events = payload;
		},
		[deleteEvent.fulfilled]: (state, { payload }) => {
			state.events = payload;
		},
	},
});

export const { clearError } = eventsSlice.actions;
export default eventsSlice.reducer;
