import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './Heading.scss';

const Heading = ({ children, isSubHeading, icon, tabs, selectedTab }) => {
	const headingClassnames = classnames(
		'heading',
		isSubHeading && 'heading--sub'
	);

	const getTabClassnames = (tabId) => {
		return classnames({
			selected: tabId === selectedTab,
		});
	};

	return (
		<div className={headingClassnames}>
			<div className="heading__title">
				{icon && <span className="heading__title__icon">{icon}</span>}
				{children}
			</div>
			{!!tabs.length && (
				<div className="heading__tabs">
					{tabs.map(({ id, label, onClick }) => (
						<div
							key={id}
							className={getTabClassnames(id)}
							onClick={() => onClick(id)}
						>
							{label}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

Heading.propTypes = {
	children: PropTypes.node.isRequired,
	isSubHeading: PropTypes.bool,
	icon: PropTypes.node,
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			label: PropTypes.string,
			onClick: PropTypes.func,
		})
	),
	selectedTab: PropTypes.string,
};

Heading.defaultProps = {
	isSubHeading: false,
	icon: null,
	tabs: [],
	selectedTab: undefined,
};

export default Heading;
