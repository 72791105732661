import React from 'react';
import PropTypes from 'prop-types';

const Loupe = ({ size }) => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		x="0"
		y="0"
		width={size}
		enableBackground="new 0 0 503.467 503.467"
		version="1.1"
		viewBox="0 0 503.467 503.467"
		xmlSpace="preserve"
	>
		<path
			fill="#80D6FA"
			d="M487.96 440.173c13.653 13.653 13.653 34.987 0 48.64s-34.987 13.653-48.64 0l-59.733-59.733.853-.853 46.933-47.787 60.587 59.733z"
			transform="translate(1 1)"
		></path>
		<path
			fill="#ECF4F7"
			d="M199.533 37.4c89.6 0 162.133 72.533 162.133 162.133s-72.533 162.133-162.133 162.133S37.4 289.133 37.4 199.533 109.933 37.4 199.533 37.4"
			transform="translate(1 1)"
		></path>
		<g fill="#AAB1BA">
			<path
				d="M361.667 199.533c0-89.6-72.533-162.133-162.133-162.133S37.4 109.933 37.4 199.533s72.533 162.133 162.133 162.133 162.134-72.533 162.134-162.133m34.133 0c0 41.813-12.8 81.067-35.84 112.64a181.796 181.796 0 01-47.787 47.787c-31.573 22.187-70.827 35.84-112.64 35.84C91.16 395.8 3.267 307.907 3.267 199.534S91.16 3.267 199.533 3.267C307.907 3.267 395.8 91.16 395.8 199.533"
				transform="translate(1 1)"
			></path>
			<path
				d="M380.44 428.227l-67.413-67.413-.853-.853a181.796 181.796 0 0047.787-47.787h.853l67.413 67.413-46.933 47.787-.854.853z"
				transform="translate(1 1)"
			></path>
		</g>
		<path
			fill="#51565F"
			d="M465.067 503.467c-10.24 0-19.627-4.267-27.307-11.093L337.067 390.827c-1.707-1.707-1.707-4.267 0-5.973 1.707-1.707 4.267-1.707 5.973 0L443.733 486.4c5.973 5.973 12.8 8.533 21.333 8.533 7.68 0 15.36-3.413 21.333-8.533 5.973-5.973 8.533-12.8 8.533-21.333 0-8.533-3.413-15.36-8.533-21.333l-57.173-57.173-21.333 21.333c-1.707 1.707-4.267 1.707-5.973 0-1.707-1.707-1.707-4.267 0-5.973l23.893-23.893c.853-.853 1.707-.853 3.413-.853.853 0 2.56.853 3.413.853l59.733 59.733c7.68 6.827 11.093 17.067 11.093 27.307 0 10.24-4.267 19.627-11.093 27.307-7.679 6.825-17.065 11.092-27.305 11.092zm-264.534-102.4C89.6 401.067 0 311.467 0 200.533S89.6 0 200.533 0s200.533 89.6 200.533 200.533c0 41.813-12.8 80.213-34.133 111.787l42.667 42.667c.853.853.853 1.707.853 3.413s0 2.56-.853 3.413c-1.707 1.707-4.267 1.707-5.973 0l-40.96-40.96c-37.547 47.787-96.427 80.214-162.134 80.214zm0-392.534c-105.813 0-192 86.187-192 192s86.187 192 192 192 192-86.187 192-192-86.186-192-192-192zm0 358.4c-92.16 0-166.4-74.24-166.4-166.4s74.24-166.4 166.4-166.4c92.16 0 166.4 74.24 166.4 166.4s-74.24 166.4-166.4 166.4zm0-324.266c-87.04 0-157.867 70.827-157.867 157.867S113.493 358.4 200.533 358.4 358.4 287.573 358.4 200.533 287.573 42.667 200.533 42.667zm76.8 153.6c-.853 0-2.56 0-3.413-.853l-68.267-68.267c-.853-.853-.853-1.707-.853-3.413 0-1.707 0-2.56.853-3.413 1.707-1.707 4.267-1.707 5.973 0l68.267 68.267c.853.853.853 1.707.853 3.413s0 2.56-.853 3.413c-.853.853-1.706.853-2.56.853zm17.067-51.2c-.853 0-2.56 0-3.413-.853l-34.133-34.133c-.854-.854-.854-1.708-.854-3.414s0-2.56.853-3.413c1.707-1.707 4.267-1.707 5.973 0l34.133 34.133c.853.853.853 1.707.853 3.413s0 2.56-.853 3.413c-.852.854-1.706.854-2.559.854z"
		></path>
	</svg>
);

Loupe.propTypes = {
	size: PropTypes.number
};

Loupe.defaultProps = {
	size: 40
};

export default Loupe;