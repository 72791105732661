import React from 'react';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton } from '@material-ui/core';

const ClearInput = ({ canClear, className, onChange, isTags }) => {
	if (!canClear) return null;
	return (
		<IconButton
			className={className}
			onClick={() => onChange(isTags ? [] : '')}>
			<ClearIcon />
		</IconButton>
	);
};

ClearInput.propTypes = {
	canClear: PropTypes.bool,
	className: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	isTags: PropTypes.bool
};

ClearInput.defaultProps = {
	className: '',
	canClear: false,
	isTags: false
};

export default ClearInput;