import React from 'react';
import { Heading } from 'common/components';
import { Video } from 'common/icons';
import './VideoAdmin.scss';

const VideoAdmin = () => {
	return (
		<div className="video-admin">
			<Heading icon={<Video />}>{'Video Admin'}</Heading>
			<h2>How to add a video</h2>
			<ol>
				<li>
					Head to{' '}
					<a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
						YouTube
					</a>
				</li>
				<li>
					{"Make sure you're signed into the SLiSL gmail account. "}
					{
						"If you aren't then you can switch by clicking the avatar in the top right, and selecting "
					}
					<b>Switch Account</b>
				</li>
				<li>
					Go to the{' '}
					<a
						href="https://studio.youtube.com/channel/UCpUvSAdaar52g6yYxUJ16uQ"
						target="_blank"
						rel="noreferrer"
					>
						SLiSL YouTube Dashboard
					</a>
				</li>
				<li>
					Click <b>CREATE</b> in the top right
				</li>
				<li>
					Click <b>Upload videos</b>
				</li>
				<li>Select the files to upload</li>
				<li>Add a title</li>
				<li>
					<b>IMPORTANT</b>: Below the Title, Desription, and Thumbnails, you{' '}
					<i style={{ textDecoration: 'underline' }}>have</i> to select{' '}
					<b>SLiSL</b> from the playlist dropdown, otherwise it won't show on
					the website.
				</li>
				<li>
					Select <b>No, it's not 'Made for Kids'</b>
				</li>
				<li>
					In the final section, <b>Visibility</b>, you should select{' '}
					<b>Unlisted</b>
				</li>
				<li>
					Click <b>Save</b> and wait! It will now upload and do various checks.
					Once that is done it will be available to view on the SLiSL website.
				</li>
			</ol>

			<h2 style={{ marginTop: 40 }}>How to manage your videos</h2>
			<ol>
				<li>
					Make sure you're signed into the SLiSL gmail account. If you aren't
					then you can switch by clicking the avatar in the top right of the{' '}
					<a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
						YouTube
					</a>{' '}
					page, and selecting
					<b>Switch Account</b>
				</li>
				<li>
					Go to the{' '}
					<a
						href="https://studio.youtube.com/channel/UCpUvSAdaar52g6yYxUJ16uQ"
						target="_blank"
						rel="noreferrer"
					>
						SLiSL YouTube Dashboard
					</a>
				</li>
				<li>
					Click <b>Content</b> in the left navigation bar.
				</li>
				<li>
					Hover your mouse over any of the videos to see the editing options.
				</li>
			</ol>
		</div>
	);
};

export default VideoAdmin;
