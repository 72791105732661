import {
	createPublication,
	updatePublication,
	removeStoredItem,
} from 'common/utils/firebase-tools';
import { DOCTYPES } from 'common/utils/docType-dictionary';
import { timestampFromDate } from 'common/utils/format-date';
import CompressionService from '../../../../store/services/CompressionService';

const compressionService = new CompressionService();

const uploadThumbnail = async (thumbnail, id, docType) => {
	try {
		const thumbnailData = new FormData();
		thumbnailData.append('thumbnail', thumbnail);
		thumbnailData.append('publicationId', id);
		thumbnailData.append('docType', docType);
		const response = await compressionService.thumbnail(thumbnailData);
		return response.data.url;
	} catch (err) {
		throw new Error('Cannot upload thumbnail');
	}
};

const uploadFiles = async (thumbnail, document, id) => {
	try {
		const filesData = new FormData();
		filesData.append('thumbnail', thumbnail);
		filesData.append('document', document);
		filesData.append('id', id);
		const response = await compressionService.document(filesData);
		return response.data;
	} catch (err) {
		console.log('ERROR === ', err);
	}
};

export const uploadDocument = async ({
	data,
	file,
	publicationId,
	thumbnail,
}) => {
	if (!publicationId) {
		let id = publicationId;
		try {
			const newEntry = await createPublication(DOCTYPES.DOCUMENT, data);
			id = newEntry.id;
			const documentUrls = await uploadFiles(thumbnail, file, id);
			await updatePublication(DOCTYPES.DOCUMENT, id, {
				file: {
					downloadUrl: documentUrls.document,
				},
				thumbnail: {
					downloadUrl: documentUrls.thumbnail,
				},
				shareLink: documentUrls?.shareLink,
			});
		} catch (err) {
			await removeStoredItem(DOCTYPES.DOCUMENT, id, DOCTYPES.DOCUMENT);
			throw new Error(err.message);
		}
	} else {
		try {
			await updatePublication(DOCTYPES.DOCUMENT, publicationId, data);
		} catch (err) {
			throw new Error(err.message);
		}
	}
};

export const uploadSuccessStory = async ({
	publicationId,
	html,
	uploadImages,
	thumbnail,
	...rest
}) => {
	try {
		let pubID = publicationId;
		let thumbnailUrl = thumbnail;
		if (!pubID) {
			const newEntry = await createPublication(DOCTYPES.STORY, {
				publication_date: timestampFromDate(),
			});
			pubID = newEntry.id;
		}

		if (uploadImages.length) {
			const imageSources = {};
			const formData = new FormData();
			formData.append('publicationId', pubID);
			formData.append('docType', DOCTYPES.STORY);
			formData.append('html', html);
			uploadImages.forEach((image) => {
				imageSources[image.file.name.replace(/ /g, '_')] = image.localSrc;
				formData.append('image', image.file);
			});

			// MUST come after the image appending
			formData.append('imageSources', JSON.stringify(imageSources));

			const response = await compressionService.publicationImages(formData);
			html = response.data.html;
		}

		if (thumbnail && !thumbnail?.downloadUrl && typeof thumbnail !== 'string') {
			thumbnailUrl = await uploadThumbnail(thumbnail, pubID, DOCTYPES.STORY);
		}

		const data = {
			...rest,
			...(pubID && { updated_at: timestampFromDate() }),
			thumbnail: {
				downloadUrl: thumbnailUrl,
			},
			description: html,
		};
		await updatePublication(DOCTYPES.STORY, pubID, data);
	} catch (err) {
		throw new Error(err);
	}
};
