export const ICON_DICTIONARY = {
	'ic1': 'Borehole',
	'ic2': 'Well',
	'ic3': 'Support to CHCs', 
	'ic4': 'Support to district hospital',
	'ic5': 'Support to tertiary hospital',
	'ic6': 'Support to midwifery school', 
	'ic7': 'SBCU in place', 
	'ic9': 'Surgical Assistant CHO training',
	'ic10': 'Nurse Anaesthetist training',
	'ic11': 'EmONC Construction phase 1 (completed)'
};