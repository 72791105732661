import React from 'react';
import Proptypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import MaterialButton from '@material-ui/core/Button';
import Loader from './Loader';
import { borderRadius } from '../scss/variables/shapes.scss';
import { montroseBlue, montroseGreen } from '../scss/variables/colors.scss';

const useStyles = makeStyles({
	root: {
		fontWeight: 600,
		borderRadius
	},
	primary: {
		background: montroseBlue,
		opacity: 0.9,
		color: '#fff',
		'&:hover': {
			background: montroseBlue,
			opacity: 1
		}
	},
	secondary: {
		backgroundColor: '#f44336',
		color: '#fff',
		'&:hover': {
			backgroundColor: '#ba000d'
		}
	},
	lightBlue: {
		backgroundColor: '#53a2be',
		color: '#fff',
		'&:hover': {
			backgroundColor: '#418aa3'
		}
	},
	green: {
		backgroundColor: montroseGreen,
		opacity: 0.9,
		color: '#fff',
		'&:hover': {
			backgroundColor: montroseGreen,
			opacity: 1
		}
	}
});

const Button = ({ 
	className,
	children, 
	onClick,
	loading,
	warning,
	lightBlue,
	disabled,
	primary,
	green,
	outlined
}) => {
	const styles = useStyles();
	const buttonClasses = classNames(
		styles.root,
		primary && styles.primary,
		warning && styles.secondary,
		lightBlue && styles.lightBlue,
		green && styles.green
	);

	return (
		<div className={className}>
			<MaterialButton 
				className={buttonClasses}
				variant={outlined ? 'outlined' : 'contained'}
				disabled={disabled}
				color="primary"
				onClick={onClick}
			>
				{loading ? 
					<Loader color={'#fff'} /> : 
					children
				}
			</MaterialButton>
		</div>
	);
};

Button.propTypes = {
	className: Proptypes.string,
	right: Proptypes.bool,
	children: Proptypes.node.isRequired,
	onClick: Proptypes.func.isRequired,
	loading: Proptypes.bool,
	disabled: Proptypes.bool,
	warning: Proptypes.bool,
	lightBlue: Proptypes.bool,
	primary: Proptypes.bool,
	outlined: Proptypes.bool,
	green: Proptypes.bool
};

Button.defaultProps = {
	className: '',
	right: false,
	loading: false,
	disabled: false,
	warning: false,
	lightBlue: false,
	primary: false,
	outlined: false,
	green: false
};

export default Button;
