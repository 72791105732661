import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Input, DropDown, Button } from 'common/components';
import { ICON_DICTIONARY } from 'common/utils/icon-dictionary';
import {
	selectEditAction,
	setSelectedIcon,
	setTempQuantity,
} from 'store/actions/mapActions';
import './RegionEditor.scss';

const RegionEditor = ({ region, onSubmit }) => {
	const {
		zoom,
		edit: { action, selectedIcon, chiefdom, tempQuantity },
	} = useSelector((state) => state.map);
	const dispatch = useDispatch();
	const [regionName, setRegionName] = useState(region.name);
	const [edit, setEdit] = useState(false);

	useEffect(() => {
		if (region.name !== regionName) setRegionName(region.name);
	}, [region]);

	const handleEditSubmit = () => {
		onSubmit(regionName);
		setEdit(false);
	};

	const renderTitle = () => {
		if (zoom === 10 && !chiefdom) return 'Click a chiefdom to edit...';
		if (edit && action === 'edit-region-name')
			return (
				<Input
					className="region-edit__name"
					value={regionName}
					onChange={setRegionName}
					defaultValue={regionName}
				/>
			);
		return region.name;
	};

	const renderActionSelector = () => {
		if (zoom === 10 && !chiefdom) return null;
		const handleSelectAction = (action) => {
			if (action) {
				if (action === 'edit-region-name') setRegionName(region.name);
				setEdit(true);
			} else setEdit(false);

			dispatch(selectEditAction(action));
			dispatch(setSelectedIcon(null));
		};
		return (
			<div>
				<DropDown
					placeholder="Choose an action..."
					value={action || ''}
					options={['edit-region-name', 'edit-icon', 'remove-icon']}
					subGroup={{ label: 'Add icon', list: ICON_DICTIONARY }}
					onChange={handleSelectAction}
				/>
			</div>
		);
	};

	const renderIconNumberPicker = () => {
		const handleOnChange = (changed) => {
			if (action === 'edit-icon')
				dispatch(
					setSelectedIcon({ ...selectedIcon, quantity: parseInt(changed) })
				);
			else dispatch(setTempQuantity(parseInt(changed)));
		};
		return (
			<DropDown
				className="region-edit__actions__number-picker"
				placeholder="Pick a number..."
				value={selectedIcon?.quantity || tempQuantity}
				options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]}
				onChange={handleOnChange}
			/>
		);
	};

	const renderSubmitBtn = () => {
		if (edit) if (action === 'remove-icon' && !selectedIcon) return null;
		return (
			<Button primary onClick={handleEditSubmit}>
				{action === 'remove-icon' ? 'Delete' : 'Save'}
			</Button>
		);
	};

	const renderInfoText = () => {
		let message;
		if (action === 'edit-icon' && selectedIcon?._id)
			message =
				'Drag icon to re-position it, or click the destination location';
		else if (
			action === 'remove-icon' ||
			(action === 'edit-icon' && !selectedIcon)
		)
			message = 'Click an icon to select it';
		else message = 'To move a label, click and drag';
		return <span className="region-edit__submit__info-text">{message}</span>;
	};

	return (
		<div className="region-edit">
			<div className="region-edit__actions">
				<span>{renderTitle()}</span>
				{region && renderActionSelector()}
				{action &&
					action !== 'edit-region-name' &&
					action !== 'remove-icon' &&
					renderIconNumberPicker()}
			</div>
			{action && (
				<div className="region-edit__submit">
					{renderInfoText()}
					{edit && renderSubmitBtn()}
				</div>
			)}
		</div>
	);
};

RegionEditor.propTypes = {
	region: PropTypes.shape({
		name: PropTypes.string,
		_id: PropTypes.string,
		district: PropTypes.string,
		label_pos: PropTypes.shape({}),
	}).isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default RegionEditor;
