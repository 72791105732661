import React from 'react';
import PropTypes from 'prop-types';
import { SubHeading, DragAndDrop } from 'common/components';

export const StepThree = ({ setFile, ...props }) => {
	return (
		<div className="add-event__step-three">
			<SubHeading>{'Attach a document (optional)'}</SubHeading>
			<DragAndDrop
				onUpload={setFile}
				formats={['application/pdf']}
				title="File"
				noDrag
				{...props}
			/>
		</div>
	);
};

StepThree.propTypes = {
	setFile: PropTypes.func.isRequired,
};
