import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { BeatLoader, GridLoader, BarLoader } from 'react-spinners';
import './Loader.scss';

const Loader = ({ type, color, size, className }) => {
	const loaderClassnames = classnames(
		'loader-container',
		className
	)
	let loader = <BeatLoader color={color} size={size} />;

	if (type === 'grid')
		loader = <GridLoader color={color} size={15} />;
	else if (type === 'bar')
		loader = <BarLoader color={color} width={300} />;

	return (
		<div className={loaderClassnames}>
			{loader}
		</div>
	);
};

Loader.propTypes = {
	type: PropTypes.string,
	color: PropTypes.string,
	size: PropTypes.number,
	className: PropTypes.string
};

Loader.defaultProps = {
	size: 10,
	type: '',
	color: '#0072c7',
	className: ''
};

export default Loader;