import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { divIcon } from 'leaflet';
import PropTypes from 'prop-types';
import { Polygon, Polyline, Marker } from 'react-leaflet';
import MapIcon from './MapIcon';
import { logEvent } from 'common/utils/firebase-tools.js';
import './Region.scss';

const Region = ({
	region,
	isEditor,
	labelPosition,
	isLabelDraggable,
	onDragEnd,
	onRegionClick,
	border,
}) => {
	const {
		selectedDistrictId,
		chiefdoms,
		zoom,
		edit: { chiefdom: chiefdomToEdit, tempIcon },
	} = useSelector((state) => state.map);
	const isSelectedDistrict = selectedDistrictId === region._id;
	const isChiefdomToEdit = chiefdomToEdit?._id === region._id;
	const isChiefdom =
		chiefdoms.length && chiefdoms.find((c) => c._id === region._id);
	const [opacity, setOpacity] = useState(1);

	const markerClassnames = classnames(
		isChiefdom ? 'chiefdom__marker' : 'district__marker',
		zoom < 8 && 'district__marker--hide',
		{
			'district__marker--western':
				region.name === 'Western Area Urban' ||
				region.name === 'Western Area Rural' ||
				region.name === 'Port Loko',
		}
	);
	const text = divIcon({
		html: region.name,
		className: markerClassnames,
	});

	const handleMouseOver = () => {
		if (isChiefdom) return;
		if (!isEditor) setOpacity(0.8);
		else if (!isSelectedDistrict) setOpacity(0.8);
	};

	const handleMouseOut = () => {
		if (isChiefdom) return;
		if (opacity < 1) setOpacity(1);
	};

	const handleRegionClick = (event) => {
		if (!isSelectedDistrict && !isChiefdomToEdit)
			logEvent('region click', {
				['region name']: region.name,
				district: !isChiefdom,
			});

		if (!isChiefdom) setOpacity(1);
		const { latlng } = event;
		onRegionClick({
			isChiefdom: !!isChiefdom,
			isSelectedRegion: isSelectedDistrict || isChiefdomToEdit,
			region,
			border,
			latlng,
		});
	};

	const renderRegionLabel = () => {
		if (
			(!isChiefdom && (zoom === 8 || zoom === 9)) ||
			(isChiefdom && zoom === 10)
		)
			return (
				<Marker
					id={region._id}
					position={labelPosition(
						isSelectedDistrict || isChiefdomToEdit,
						region.label_pos,
						border
					)}
					draggable={isLabelDraggable(isSelectedDistrict || isChiefdomToEdit)}
					ondragend={onDragEnd}
					onclick={handleRegionClick}
					onmouseover={handleMouseOver}
					onmouseout={handleMouseOut}
					icon={text}
				/>
			);
		return null;
	};

	const renderRegionIcons = () => {
		const { icons } = region;
		if (
			((isSelectedDistrict && zoom === 9 && !isChiefdom) ||
				(isChiefdom && zoom === 10)) &&
			icons.length
		)
			return icons.map((icon) => {
				if (!icon || icon.type === 'ic8') return null;
				return <MapIcon key={icon._id} icon={icon} isEditor={isEditor} />;
			});
		return null;
	};

	const renderEditorIcons = () => {
		if (tempIcon.length)
			return tempIcon.map((icon) => (
				<MapIcon key={1} icon={icon} isEditor={isEditor} />
			));
	};

	return (
		<>
			<Polygon
				id={region._id}
				onclick={handleRegionClick}
				onmouseover={handleMouseOver}
				onmouseout={handleMouseOut}
				fillColor={region?.color}
				fillOpacity={isChiefdom ? 0 : opacity}
				positions={border}
				color={'#fff'}
				weight={1}
			>
				{renderRegionLabel()}
				{renderRegionIcons()}
				{isEditor && isSelectedDistrict && renderEditorIcons()}
			</Polygon>
			{region.pointer_pos && zoom >= 8 && (
				<Polyline positions={region.pointer_pos} color={'#fff'} weight={1} />
			)}
		</>
	);
};

Region.propTypes = {
	region: PropTypes.shape({
		_id: PropTypes.string,
		name: PropTypes.string,
		label_pos: PropTypes.shape({}),
		color: PropTypes.string,
		pointer_pos: PropTypes.array,
		icons: PropTypes.array,
	}).isRequired,
	border: PropTypes.array.isRequired,
	isEditor: PropTypes.bool,
	labelPosition: PropTypes.func.isRequired,
	isLabelDraggable: PropTypes.func.isRequired,
	onDragEnd: PropTypes.func.isRequired,
	onRegionClick: PropTypes.func.isRequired,
};

Region.defaultProps = {
	isEditor: false,
};

export default Region;
