import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const Document = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={30}
		height={30}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 36 48"
	>
		<g data-name="Layer 2">
			<path d="M33 0H3a3 3 0 00-3 3v42a3 3 0 003 3h30a3 3 0 003-3V3a3 3 0 00-3-3zm-1 44H4V4h28z"></path>
			<path d="M22.56 13.56l.44-.439V20.5a1.5 1.5 0 003 0v-11a1.5 1.5 0 00-2.56-1.06l-3 3a1.5 1.5 0 002.12 2.12zM14.074 26.114a1.497 1.497 0 00-1.635.325l-3 3a1.5 1.5 0 002.122 2.122l.439-.44V38.5a1.5 1.5 0 003 0v-11a1.5 1.5 0 00-.926-1.386zM22 40h3a3 3 0 003-3v-8a3 3 0 00-3-3h-3a3 3 0 00-3 3v8a3 3 0 003 3zm0-11h3v8h-3zM11 22h3a3 3 0 003-3v-8a3 3 0 00-3-3h-3a3 3 0 00-3 3v8a3 3 0 003 3zm0-11h3v8h-3z"></path>
		</g>
	</svg>
);

export default Document;