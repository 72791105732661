import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
	SubHeading,
	Checkbox,
	DatePicker,
	TimePicker,
} from 'common/components';

export const NOW = moment().tz('Africa/Freetown').minutes(Math.ceil(moment().minute() / 5) * 5);

export const StepTwo = ({
	allDay,
	setAllDay,
	starts,
	setStarts,
	ends,
	setEnds,
}) => {
	const handleDateSelection = (date, label) => {
		if (label === 'Starts') {
			const time = moment(starts).tz('Africa/Freetown').format('HH:mm').split(':');
			const [hour, minutes] = time;
			const timeToSet = moment(date).tz('Africa/Freetown').set({
				hour,
				minutes,
			});
			setStarts(timeToSet);
			if (moment(timeToSet).tz('Africa/Freetown').isAfter(ends)) {
				setEnds(timeToSet);
			}
		}

		if (label === 'Ends') {
			const time = moment(ends).tz('Africa/Freetown').format('HH:mm').split(':');
			const [hour, minutes] = time;
			const timeToSet = moment(date).tz('Africa/Freetown').set({ hour, minutes });
			const newTime = allDay ? moment(date).tz('Africa/Freetown').endOf('day') : timeToSet;
			setEnds(newTime);
		}
	};

	const handleTimeSelection = ({ target }) => {
		const timeSplit = target.value.split(':');
		const [hour, minutes] = timeSplit;
		const when = target.id === 'starts' ? starts : ends;
		const time = moment(when).tz('Africa/Freetown').set({ hour, minutes });
		if (target.id === 'starts') {
			setStarts(time);
		} else {
			setEnds(time);
		}
	};

	const handleAllDayClick = (isChecked) => {
		if (isChecked) {
			const start = moment(starts).tz('Africa/Freetown');
			const end = moment(ends).tz('Africa/Freetown');
			setStarts(start.set({ hour: 0, minutes: 1 }));
			setEnds(end.set({ hour: 23, minute: 59 }));
		}
		setAllDay(isChecked);
	};

	return (
		<div className="add-event__step-two">
			<SubHeading>{'Date and time'}</SubHeading>
			<Checkbox
				label="all-day"
				isChecked={allDay}
				onChange={handleAllDayClick}
			/>
			<div className="add-event__step-two__date">
				<DatePicker
					label={'Starts'}
					date={starts}
					onChange={handleDateSelection}
					minDate={NOW}
				/>
				{!allDay && (
					<TimePicker
						id="starts"
						className="add-event__step-two__date__time"
						label={'At'}
						value={moment(starts).tz('Africa/Freetown').format('HH:mm')}
						onChange={handleTimeSelection}
					/>
				)}
			</div>
			<div className="add-event__step-two__date">
				<DatePicker
					label={'Ends'}
					date={ends}
					onChange={handleDateSelection}
					minDate={starts}
				/>
				{!allDay && (
					<TimePicker
						id="ends"
						className="add-event__step-two__date__time"
						label={'At'}
						value={moment(ends).tz('Africa/Freetown').format('HH:mm')}
						onChange={handleTimeSelection}
					/>
				)}
			</div>
		</div>
	);
};

StepTwo.propTypes = {
	allDay: PropTypes.bool.isRequired,
	setAllDay: PropTypes.func.isRequired,
	starts: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
		.isRequired,
	setStarts: PropTypes.func.isRequired,
	ends: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
		.isRequired,
	setEnds: PropTypes.func.isRequired,
};
