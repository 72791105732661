export const DOCTYPE_DICTIONARY = {
	'dt1': 'document',
	'dt2': 'success-story',
	'dt3': 'annual-reviews'
};

export const DOCTYPES = {
	DOCUMENT: 'dt1',
	STORY: 'dt2',
	REVIEWS: 'dt3'
};