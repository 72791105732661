import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import './Image-Carousel.scss';
import Button from './Button';

const CarouselCallToAction = (props) => {
	let history = useHistory();

	const handleCarouselClick = (id) => {
		history.push(`/resources/success-story/${id}`);
	};

	return (
		<div className="block">
			<div className="carousel-cta">
				<p className="carousel-cta__lead">{props.title.one}</p>
				<p className="carousel-cta__lead">{props.title.two}</p>
			</div>
			<Button
				className="carousel-button"
				primary
				onClick={() => handleCarouselClick(props.id)}
			>
				Learn More
			</Button>
		</div>
	);
};

const ImageCarousel = (props) => {
	const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

	return (
		<ImageGallery
			items={props.photos}
			slideInterval={6000}
			lazyLoad
			onSlide={setCurrentPhotoIndex}
			showFullscreenButton={props.showFullscreenButton}
			showPlayButton={props.showPlayButton}
			showThumbnails={props.showThumbnails}
			renderCustomControls={() => (
				<CarouselCallToAction
					title={props.photos[currentPhotoIndex].title}
					id={props.photos[currentPhotoIndex].id}
				/>
			)}
		/>
	);
};

ImageCarousel.propTypes = {
	photos: PropTypes.arrayOf(
		PropTypes.shape({
			original: PropTypes.string,
			title: PropTypes.shape({
				one: PropTypes.string,
				two: PropTypes.string,
			}),
			id: PropTypes.string,
		})
	),
	index: PropTypes.arrayOf(
		PropTypes.shape({
			original: PropTypes.number,
		})
	),
	showFullscreenButton: PropTypes.bool,
	showPlayButton: PropTypes.bool,
	showThumbnails: PropTypes.bool,
};

ImageCarousel.defaultProps = {
	photos: [],
	index: [],
	showFullscreenButton: false,
	showPlayButton: false,
	showThumbnails: false,
};

CarouselCallToAction.propTypes = {
	title: PropTypes.shape({
		one: PropTypes.string,
		two: PropTypes.string,
	}),
	id: PropTypes.string.isRequired,
};

CarouselCallToAction.defaultProps = {
	title: {
		one: '',
		two: '',
	},
};

export default ImageCarousel;
