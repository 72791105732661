import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const Books = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={40}
		height={40}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 48 48"
	>
		<g data-name="Layer 2">
			<path d="M2 11h2.23a8.993 8.993 0 0017.54 0h4.46a8.993 8.993 0 0017.54 0H46a2 2 0 000-4h-2.23a8.993 8.993 0 00-17.54 0h-4.46A8.993 8.993 0 004.23 7H2a2 2 0 000 4zm28.424-4A4.931 4.931 0 1130 9a5.003 5.003 0 01.424-2zm-22 0A4.931 4.931 0 118 9a5.003 5.003 0 01.424-2zM47 23H17a1.003 1.003 0 00-1 1v3.99h15A3.013 3.013 0 0134.01 31v17H48V24a1.003 1.003 0 00-1-1z"></path>
			<path d="M30 30H2a2.006 2.006 0 00-2 2v15.93l.07.07h31.92l.01-.01V32a2.006 2.006 0 00-2-2zm-4.5 15h-19a1.5 1.5 0 010-3h19a1.5 1.5 0 010 3zm0-6h-19a1.5 1.5 0 010-3h19a1.5 1.5 0 010 3z"></path>
		</g>
	</svg>
);

export default Books;