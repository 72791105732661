import React, { useState } from 'react';
import { Heading, Map, DropDown, Button } from 'common/components';
import { Location } from 'common/icons';
import { getDocUrl, logEvent } from 'common/utils/firebase-tools';
import MapLegend from './MapKey';
import './Activities.scss';

const districts = {
	Bo: 'Bo.pdf',
	Bombali: 'Bombali.pdf',
	Bonthe: 'Bonthe.pdf',
	Kailahun: 'Kailahun.pdf',
	Kambia: 'Kambia.pdf',
	Kenema: 'Kenema.pdf',
	Koinadugu: 'Koinadugu.pdf',
	Kono: 'Kono.pdf',
	Moyamba: 'Moyamba.pdf',
	'Port Loko': 'Port_Loko.pdf',
	Pujehun: 'Pujehun.pdf',
	Tonkolili: 'Tonkolili.pdf',
	'Western Area Rural': 'Western_Area_Rural.pdf',
	'Western Area Urban': 'Western_Area_Urban.pdf',
};

const Activities = () => {
	const [selectedDistrict, setSelectedDistrict] = useState('');

	const onDownloadClick = async () => {
		logEvent('district fact sheet download', {
			district: selectedDistrict,
		});
		const downloadUrl = await getDocUrl(
			`fact-sheets/${districts[selectedDistrict]}`
		);
		window.open(downloadUrl, '_blank');
	};

	return (
		<div className="activities">
			<Heading icon={<Location />}>{'Activity Map'}</Heading>
			<span className="fact-sheet__info">
				{
					'Saving Lives in Sierra Leone works across all districts. You can download a district fact sheet of our programme activities below.'
				}
			</span>
			<div className="fact-sheet">
				<DropDown
					className="fact-sheet__dropdown"
					placeholder="District fact sheet"
					options={Object.keys(districts)}
					value={selectedDistrict}
					onChange={setSelectedDistrict}
				/>
				<Button
					disabled={!selectedDistrict}
					className="fact-sheet__btn"
					onClick={onDownloadClick}
				>
					{'Download'}
				</Button>
			</div>
			<span className="activities__note">
				{'Note: Map currently showing 14 districts in Sierra Leone'}
			</span>
			<Map />
			<MapLegend />
		</div>
	);
};

export default Activities;
