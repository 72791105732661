import React from 'react';
import PropTypes from 'prop-types';
import MUIPagination from '@material-ui/lab/Pagination';

const Pagination = ({ pageCount, currentPage, onChange }) => (
	<MUIPagination
		count={pageCount} 
		page={currentPage}
		onChange={onChange}
		variant="outlined"
		shape="rounded"
		color="primary" 
	/>
);

Pagination.propTypes = {
	pageCount: PropTypes.number.isRequired,
	currentPage: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired
};

export default Pagination;
