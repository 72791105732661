import React from 'react';
import PropTypes from 'prop-types';
import './Image.scss';

const Image = ({ src, caption }) => (
	<>
		<img
			className="common-image"
			src={src}
			alt={caption}
		/>
		<span className="common-image-caption">
			{caption}
		</span>
	</>
);

Image.propTypes = {
	src: PropTypes.string.isRequired,
	caption: PropTypes.string.isRequired
};

export default Image;
