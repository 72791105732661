import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

function Icon() {
	return (
		<svg
			width={40}
			height={40}
			x="0"
			y="0"
			viewBox="0 0 48 32"
			fill={montroseBlue}
		>
			<g data-name="Layer 2">
				<path d="M24 26a9.008 9.008 0 01-8.94-8H0v12a2.006 2.006 0 002 2h44a2.006 2.006 0 002-2V18H32.94A9.008 9.008 0 0124 26zM46 6H33V2a2.006 2.006 0 00-2-2H17a2.006 2.006 0 00-2 2v4h-5V4a1 1 0 00-1-1H6a1 1 0 00-1 1v2H2a2.006 2.006 0 00-2 2v8h15.06a8.995 8.995 0 0117.88 0H48V8a2.006 2.006 0 00-2-2zm-3.5 6h-5a1.5 1.5 0 010-3h5a1.5 1.5 0 010 3z"></path>
				<path d="M24 24a6.989 6.989 0 006.77-5.23 6.926 6.926 0 000-3.54 6.997 6.997 0 00-13.54 0 6.926 6.926 0 000 3.54A6.989 6.989 0 0024 24zm-3.87-8a3.996 3.996 0 017.74 0 3.911 3.911 0 010 2 3.996 3.996 0 01-7.74 0 3.911 3.911 0 010-2z"></path>
			</g>
		</svg>
	);
}

export default Icon;
