/* eslint-disable linebreak-style */
import React from 'react';
import PropTypes from 'prop-types';

const Chevron = ({ isOpen, className, color, size }) => (
	<div className={className}>
		<svg
			width={size}
			transform={`rotate(${isOpen ? 180 : 0})`}
			x="0"
			y="0"
			enableBackground="new 0 0 256 256"
			version="1.1"
			viewBox="0 0 256 256"
			xmlSpace="preserve"
			fill={color || '#3c3c3c'}
		>
			<path d="M225.813 48.907L128 146.72 30.187 48.907 0 79.093 128 207.093 256 79.093z"></path>
		</svg>
	</div>
);

Chevron.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	className: PropTypes.string,
	color: PropTypes.string,
	size: PropTypes.number
};

Chevron.defaultProps = {
	className: '',
	color: '',
	size: 14
};

export default Chevron;
