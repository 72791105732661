import axios from 'axios';

class MapService {
	constructor() {
		this.service = axios.create({
			baseURL: `${process.env.REACT_APP_API_URL}/map`,
			withCredentials: true,
			headers: {
				Authorization: process.env.REACT_APP_AUTHENTICATION,
			},
		});
	}

	getAllDistricts = () => this.service.get('/districts');

	getChiefdoms = (chiefdomIds) =>
		this.service.get('/chiefdoms', {
			params: {
				ids: chiefdomIds,
			},
		});
}

export default MapService;
