import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './FileUpload.scss';

const FileUpload = ({
	placeholder,
	name,
	onChooseFile,
	fileTypes,
	disabled,
}) => {
	const handleFileSelect = (event) => {
		event.persist();
		const file = event.target.files[0];
		onChooseFile(file);
	};

	const uploadClassnames = classnames(
		'image-upload__select-container',
		disabled && 'image-upload__select-container--disabled'
	);

	return (
		<label className="image-upload">
			<div className={uploadClassnames}>{name || placeholder}</div>
			<input
				type="file"
				style={{ display: 'none' }}
				accept={fileTypes}
				onChange={handleFileSelect}
				disabled={disabled}
			/>
		</label>
	);
};

FileUpload.propTypes = {
	name: PropTypes.string,
	onChooseFile: PropTypes.func.isRequired,
	fileTypes: PropTypes.string,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
};

FileUpload.defaultProps = {
	name: '',
	placeholder: 'Select an image...',
	fileTypes: 'image/png, image/jpeg, image/jpg',
	disabled: false,
};

export default FileUpload;
