import * as React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const AnnualReview = () => (
	<svg
		width={30}
		height={30}
		fill={montroseBlue}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 32 44"
	>
		<g fill="#418fde" data-name="Layer 2">
			<path d="M30 0H2a2.006 2.006 0 0 0-2 2v40a2.006 2.006 0 0 0 2 2h19v-7a3.999 3.999 0 0 1 4-4h7V2a2.006 2.006 0 0 0-2-2zm-4.5 28h-19a1.5 1.5 0 0 1 0-3h19a1.5 1.5 0 0 1 0 3zm0-8h-19a1.5 1.5 0 0 1 0-3h19a1.5 1.5 0 0 1 0 3zm0-8h-19a1.5 1.5 0 0 1 0-3h19a1.5 1.5 0 0 1 0 3z" />
			<path d="M23 37v7l9-9h-7a2.006 2.006 0 0 0-2 2z" />
		</g>
	</svg>
);
export default AnnualReview;
