import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {
	sendSignInLinkToEmail,
	sendPasswordResetEmail,
	updateUser,
	setTempAccount,
} from 'common/utils/firebase-tools.js';
import { Input, Button, Modal } from 'common/components';
import { userSchema } from 'common/yup/schemas';
import './CreateUpdateModal.scss';

const CreateUpdateModal = ({ showModal, onModalClose, user, setToast }) => {
	const [email, setEmail] = useState(user?.email || '');
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [role, setRole] = useState(user?.role || '');
	const [roleError, setRoleError] = useState(false);
	const [loading, setLoading] = useState(false);

	const validateInput = (fieldName, input) => () => {
		yup
			.reach(userSchema, fieldName)
			.validate(input)
			.then(() => {
				if (fieldName === 'email' && emailError) setEmailError(false);
				else if (fieldName === 'password' && passwordError)
					setPasswordError(false);
				else if (fieldName === 'role' && roleError) setRoleError(false);
			})
			.catch(() => {
				if (fieldName === 'email') setEmailError(true);
				else if (fieldName === 'password') setPasswordError(true);
				else if (fieldName === 'role') setRoleError(true);
			});
	};

	const handleSubmit = async () => {
		setLoading(true);
		if (user) {
			await updateUser(user.id, { role });
		} else {
			try {
				await setTempAccount(email, role);
				let root = '';
				const env = process.env.REACT_APP_ENVIRONMENT;
				if (env === 'dev') {
					root = 'http://localhost:3000';
				} else if (env === 'staging') {
					root = 'https://montrose.herokuapp.com';
				} else if (env === 'production') {
					root = 'https://www.savinglivesinsierraleone.org';
				}
				const actionCodeSettings = {
					url: `${root}/account-setup`,
					handleCodeInApp: true,
				};
				await sendSignInLinkToEmail(email, actionCodeSettings);
				setToast({
					show: true,
					type: 'success',
					message: `Login details have been sent to ${email}`,
				});
			} catch (err) {
				console.log(err);
				setToast({
					show: true,
					type: 'error',
					message: 'Error submitting changes',
				});
			}
		}
		setLoading(false);
		onModalClose();
	};

	const handleResetPassword = () => {
		const confirmed = window.confirm(
			`Are you sure you want to reset for ${email}`
		);
		if (confirmed) {
			sendPasswordResetEmail(email);
		}
		onModalClose();
	};

	const isSubmitDisabled = () => {
		if (email && role) return false;
		return true;
	};

	return (
		<Modal
			show={showModal}
			title={'Create a new user account'}
			handleModalClose={onModalClose}
		>
			<div className="create-modal">
				<Input
					className="create-modal__email-input"
					label={'Email'}
					required
					defaultValue={email}
					value={email}
					onChange={setEmail}
					error={emailError && 'Invalid email address'}
					onBlur={validateInput('email', email)}
					disabled={!!user}
				/>
				{!!user && (
					<span className="create-modal__reset" onClick={handleResetPassword}>
						{'Reset password'}
					</span>
				)}
				<label className="create-modal__role__label">{'Role*'}</label>
				<select
					className="create-modal__role"
					value={role}
					onChange={(e) => setRole(e.target.value)}
				>
					<option value="EDITOR">{'EDITOR'}</option>
					<option value="ADMIN">{'ADMIN'}</option>
				</select>
				{/* <DropDown
					className="create-modal__role"
					placeholder="Role"
					required
					options={['EDITOR', 'ADMIN']}
					value={role}
					onChange={setRole}
					error={roleError}
					errorMessage={'Choose a role'}
					onBlur={validateInput('role', role)}
				/> */}
				<Button
					primary
					className="create-modal__btn"
					onClick={handleSubmit}
					disabled={isSubmitDisabled()}
					loading={loading}
				>
					{user ? 'UPDATE' : 'CREATE'}
				</Button>
			</div>
		</Modal>
	);
};

CreateUpdateModal.propTypes = {
	showModal: PropTypes.bool.isRequired,
	onModalClose: PropTypes.func.isRequired,
	user: PropTypes.shape({
		id: PropTypes.string,
		role: PropTypes.string,
	}),
	setToast: PropTypes.func.isRequired,
};

CreateUpdateModal.defaultProps = {
	user: null,
};

export default CreateUpdateModal;
