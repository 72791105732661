import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import EventAddEdit from './EventAddEdit';
import { Modal, Toast } from 'common/components';
import { PdfIcon } from 'common/icons';
import { logEvent } from 'common/utils/firebase-tools.js';
import './EventModal.scss';
import copy from 'copy-to-clipboard';

const EventModal = ({ event, isEditor, onClose, monthInView }) => {
	const [showCopyToast, setShowCopyToast] = useState(false);
	if (isEditor)
		return (
			<EventAddEdit
				show={event ? true : false}
				onClose={onClose}
				event={event}
				monthInView={monthInView}
			/>
		);

	const { title, start, end, allDay, description, documents } = event;

	const getFileName = (file) => {
		if (!file?.name && typeof file === 'string') {
			return file.split('/').pop();
		}
		return file?.name;
	};

	const onPdfClick = async ({ downloadUrl }) => {
		try {
			logEvent('download event document', {
				['event name']: event.title,
			});
			await copy('https://' + downloadUrl);
			setShowCopyToast(true);
		} catch (err) {
			console.log(err);
		}
	};

	const renderPdfIcon = (doc) => {
		return (
			<span
				className="event-detail__documents__doc"
				key={doc.name}
				onClick={() => onPdfClick(doc)}
			>
				<PdfIcon />
				<span>{getFileName(doc)}</span>
			</span>
		);
	};

	const timeFormat = allDay ? 'dddd Do MMMM YYYY' : 'h:mm a, dddd Do MMMM YYYY';

	return (
		<Modal
			handleModalClose={() => onClose(null)}
			title={title}
			show={event ? true : false}
		>
			{' '}
			<Toast
				show={showCopyToast}
				type="success"
				duration={2000}
				onClose={() => setShowCopyToast(false)}
			>
				{'Link copied to clipboard'}
			</Toast>
			<div className="event-detail">
				<div className="event-detail__time">
					<div>
						<strong>Starting: </strong>
						{moment(start).format(timeFormat)}
					</div>
					<div>
						<strong>Ending: </strong>
						{moment(end).format(timeFormat)}
					</div>
				</div>
				{description && (
					<div className="event-detail__description">{description}</div>
				)}
				{!!documents.length && (
					<>
						<span className="event-detail__documents__title">
							{'Documents:'}
						</span>
						<div className="event-detail__documents__attachments">
							{documents.map((doc) => renderPdfIcon(doc))}
						</div>
					</>
				)}
			</div>
		</Modal>
	);
};

EventModal.propTypes = {
	event: PropTypes.shape({
		title: PropTypes.string,
		start: PropTypes.shape({}),
		end: PropTypes.shape({}),
		allDay: PropTypes.bool,
		description: PropTypes.string,
		documents: PropTypes.array,
	}).isRequired,
	isEditor: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	monthInView: PropTypes.func.isRequired,
};

EventModal.defaultProps = {
	isEditor: false,
};

export default EventModal;
