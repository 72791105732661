import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const Drugs = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={80}
		height={80}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 48 48"
	>
		<g data-name="Layer 2">
			<path d="M31.51 14.51A8.5 8.5 0 1019.49 2.49l-8.5 8.5-8.5 8.5a8.5 8.5 0 1012.02 12.02l8.5-8.5zm-18.4-1.4l8.5-8.5a5.504 5.504 0 017.78 0 5.517 5.517 0 010 7.78l-8.5 8.5zM35 22a12.986 12.986 0 00-10.49 20.66l18.15-18.15A12.892 12.892 0 0035 22zM45.49 27.34L27.34 45.49a12.986 12.986 0 0018.15-18.15z"></path>
		</g>
	</svg>
);

export default Drugs;