import React from 'react';
import PropTypes from 'prop-types';
import { formatTableDate } from 'common/utils/format-date';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';

import './AccountTable.scss';

const useStyles = makeStyles({
	root: {
		marginRight: 12,
	},
});

const AccountTable = ({
	users,
	loggedId,
	handleTrashClick,
	handleEditClick,
}) => {
	const classes = useStyles();
	return (
		<table className="table">
			<tbody className="table__body">
				<tr className="table__body__header">
					<th>{'Email'}</th>
					<th>{'Creation date'}</th>
					<th>{'Role'}</th>
					<th>{'Actions'}</th>
				</tr>
				{users.length
					? users.map((user) => (
							<tr key={user.id}>
								<td>{user.email}</td>
								<td>{formatTableDate(user.created)}</td>
								<td>{user.role}</td>
								<td>
									<Fab
										className={classes.root}
										color="primary"
										aria-label="edit"
										size="small"
										onClick={handleEditClick(user)}
									>
										<Edit />
									</Fab>
									{(user.id === loggedId || user._id === loggedId) && (
										<Fab
											color="secondary"
											aria-label="delete"
											size="small"
											onClick={handleTrashClick(user)}
										>
											<Delete />
										</Fab>
									)}
								</td>
							</tr>
					  ))
					: null}
			</tbody>
		</table>
	);
};

AccountTable.propTypes = {
	users: PropTypes.array.isRequired,
	loggedId: PropTypes.string,
	handleTrashClick: PropTypes.func.isRequired,
	handleEditClick: PropTypes.func.isRequired,
};

AccountTable.defaultProps = {
	loggedId: '',
};

export default AccountTable;
