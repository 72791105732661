import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Input.scss';

const Input = ({
	onChange,
	fullWidth,
	label,
	helperText,
	marginBottom,
	error,
	className,
	required,
	multiline,
	...props
}) => {
	const handleChange = (e) => {
		onChange(e.target.value);
	};

	const containerClasses = classnames('shared-input', className);

	const inputClassnames = classnames(
		'shared-input__input',
		!multiline && 'shared-input__input--with-height',
		fullWidth && 'shared-input__input--full-width',
		error && 'shared-input__input--error'
	);

	const helperTextClasses = classnames(
		'shared-input__helper-text',
		error && 'shared-input__helper-text--error'
	);

	const requiredAsterix = required ? '*' : '';

	return (
		<div className={containerClasses} style={{ marginBottom }}>
			{label && (
				<label className="shared-input__label">{`${label}${requiredAsterix}`}</label>
			)}
			{multiline ? (
				<textarea
					className={inputClassnames}
					onChange={handleChange}
					{...props}
				/>
			) : (
				<input className={inputClassnames} onChange={handleChange} {...props} />
			)}
			{error ||
				(helperText && (
					<span className={helperTextClasses}>{error || helperText}</span>
				))}
		</div>
	);
};

Input.propTypes = {
	onChange: PropTypes.func.isRequired,
	fullWidth: PropTypes.bool,
	label: PropTypes.string,
	helperText: PropTypes.string,
	marginBottom: PropTypes.number,
	error: PropTypes.bool,
	className: PropTypes.string,
	required: PropTypes.bool,
	multiline: PropTypes.bool,
};

Input.defaultProps = {
	fullWidth: false,
	label: '',
	helperText: '',
	marginBottom: 0,
	error: false,
	className: '',
	required: false,
	multiline: false,
};

export default Input;
