import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import EventModal from './EventModal';
import { logEvent } from 'common/utils/firebase-tools.js';
import Toast from '../../common/components/Toast';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.scss';

moment.tz.setDefault('Africa/Freetown')
const localizer = momentLocalizer(moment);

const Calendar = ({
	isEditor,
	error,
	events,
	handleNavigate,
	handleClearError,
	monthInView,
}) => {
	const [selectedEvent, setSelectedEvent] = useState(null);

	const handleEventSelected = (event) => {
		logEvent('click event', {
			['event name']: event.title,
		});
		setSelectedEvent(event);
	};

	return (
		<>
			<Toast
				show={!!error}
				type={error ? 'error' : 'success'}
				onClose={handleClearError}
			>
				{error}
			</Toast>
			<div className="calendar">
				{selectedEvent && (
					<EventModal
						event={selectedEvent}
						onClose={setSelectedEvent}
						isEditor={isEditor}
						monthInView={monthInView}
					/>
				)}
				<span>{'All events are shown in Sierra Leone local time'}</span>
				<BigCalendar
					style={{ minHeight: 700 }}
					localizer={localizer}
					culture="en-GB"
					events={events}
					startAccessor="start"
					endAccessor="end"
					onSelectEvent={handleEventSelected}
					onNavigate={handleNavigate}
				/>
			</div>
		</>
	);
};

Calendar.propTypes = {
	events: PropTypes.array,
	onSelectEvent: PropTypes.func,
	isEditor: PropTypes.bool,
	error: PropTypes.string,
	handleNavigate: PropTypes.func.isRequired,
	handleClearError: PropTypes.func.isRequired,
	monthInView: PropTypes.func.isRequired,
};

Calendar.defaultProps = {
	events: [],
	onSelectEvent: () => {},
	isEditor: false,
	error: false,
};

export default Calendar;
