import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DOCTYPES } from 'common/utils/docType-dictionary';
import './DocTypeToggle.scss';

const DocTypeToggle = ({ docType, onChange, disabled }) => {
	const handleTypeClick = (e) => {
		if (disabled) return;
		if (docType === e.target.id) return;
		onChange(e.target.id);
	};

	const leftClassnames = classnames(
		'doc-type-toggle__box',
		'doc-type-toggle__box--left',
		docType === DOCTYPES.STORY && 'doc-type-toggle__box--selected'
	);

	const centerClassnames = classnames(
		'doc-type-toggle__box',
		docType === DOCTYPES.DOCUMENT && 'doc-type-toggle__box--selected'
	)

	const rightClassnames = classnames(
		'doc-type-toggle__box',
		'doc-type-toggle__box--right',
		docType === DOCTYPES.REVIEWS && 'doc-type-toggle__box--selected'
	);



	return (
		<div className="doc-type-toggle">
			<div className="doc-type-toggle__container">
				<div id="dt2" className={leftClassnames} onClick={handleTypeClick}>
					Success Story
				</div>
				<div id="dt1" className={centerClassnames} onClick={handleTypeClick}>
					Document
				</div>
				<div id='dt3' className={rightClassnames} onClick={handleTypeClick}>
					Annual Review
				</div>
			</div>
		</div>
	);
};

DocTypeToggle.propTypes = {
	docType: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
};

export default DocTypeToggle;
