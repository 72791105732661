import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './Gallery.scss';

const Video = () => {
	const [activeItem, setActiveItem] = useState('');
	const [playlistItems, setPlaylistItems] = useState([]);
	const fetchPlaylist = async () => {
		try {
			const response = await axios.get(
				'https://www.googleapis.com/youtube/v3/playlistItems',
				{
					params: {
						key: process.env.REACT_APP_YOUTUBE_ID,
						part: 'snippet',
						playlistId: 'PLAgVnsGjW2JLSwvzwq4OhMOPbqxnJyiwS',
						maxResults: 50,
					},
				}
			);
			if (response.data.items.length > 0) {
				const mappedItems = response.data.items
					.map((item) => {
						if (item.snippet.title === 'Deleted video') return;
						return {
							id: item.id,
							videoId: item.snippet.resourceId.videoId,
							title: item.snippet.title,
							description: item.snippet.description,
							publishedAt: item.snippet.publishedAt,
							thumbnail: item.snippet.thumbnails.medium,
						};
					})
					.filter(Boolean);
				setPlaylistItems(mappedItems);
				setActiveItem(mappedItems[0]);
			}
		} catch (error) {
			console.log('ERROR ==== ', error);
		}
	};

	useEffect(() => {
		fetchPlaylist();
	}, []);

	return (
		<div>
			{!!activeItem && (
				<iframe
					src={`https://www.youtube.com/embed/${activeItem.videoId}`}
					frameBorder="0"
					allow="autoplay; encrypted-media"
					allowFullScreen
					title="video"
					width="100%"
					height={500}
				/>
			)}
			{!!playlistItems.length && (
				<ul
					style={{
						listStyle: 'none',
						display: 'flex',
						padding: 0,
						overflowX: 'scroll',
					}}
					className="video"
				>
					{playlistItems.map((item) => (
						<li
							key={item.id}
							className="playlist-item"
							style={{
								width: item.thumbnail.width / 2,
							}}
							onClick={() => setActiveItem(item)}
						>
							<img width="100%" src={item.thumbnail.url} alt={item.title} />
							<p>{item.title}</p>
							<p>Published: {moment(item.publishedAt).format('DD MMM YYYY')}</p>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default Video;
