/* eslint-disable react/prop-types */
import React, { forwardRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Heading, Loader, Unavailable, Pagination } from 'common/components';
import { Megaphone } from 'common/icons';
import { setPublications } from 'store/actions/publicationActions';
import { getCollection } from 'common/utils/firebase-tools';
import { DOCTYPES, DOCTYPE_DICTIONARY } from 'common/utils/docType-dictionary';
import SuccessCard from './SuccessCard';
import './SuccessList.scss';

const SuccessList = (
	{ className, limit, hideTags, hidePagination, heading, onLoadComplete },
	ref
) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const { successStories: data, filters } = useSelector(
		(state) => state.publications
	);

	const fetchData = async () => {
		setLoading(true);
		const stories = await getCollection(DOCTYPES.STORY);
		await dispatch(setPublications({ stories: stories.data }));
		setLoading(false);
		if (onLoadComplete) {
			onLoadComplete(DOCTYPE_DICTIONARY.dt2);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const renderStories = (stories) =>
		stories.map((story, index) => {
			const lowestIndex = limit * currentPage - limit;
			const maxIndex = limit * currentPage - 1;
			if (index >= lowestIndex && index <= maxIndex) {
				return (
					<SuccessCard key={story._id} story={story} hideTags={hideTags} />
				);
			}
			return null;
		});

	const renderAlternative = () =>
		loading ? (
			<Loader className="success__grid__alternative" type="bar" />
		) : (
			<Unavailable className="success__grid__alternative" />
		);

	const listClassnames = classnames('success', className);

	const filteredStories = data.filter((story) => {
		const { keywords, from, to } = filters;
		if (keywords.length) {
			const keywordIncluded = keywords.some((keyword) => {
				if (
					story.title.match(new RegExp(keyword, 'i')) ||
					story.description.match(new RegExp(keyword), 'i') ||
					story.tags.includes(keyword) ||
					story.organisation === keyword
				) {
					return true;
				}
			});
			if (keywordIncluded) return true;
		} else if (from && to) {
			const { publication_date } = story;
			if (publication_date.valueOf() >= from && publication_date <= to)
				return true;
		} else {
			return true;
		}
		return false;
	});

	const renderPagination = () => {
		if (
			hidePagination ||
			loading ||
			Math.ceil(filteredStories.length / limit) <= 1
		)
			return null;
		return (
			<div className="success__pagination">
				<Pagination
					pageCount={Math.ceil(filteredStories.length / limit)}
					currentPage={currentPage}
					onChange={(event, page) => setCurrentPage(page)}
				/>
			</div>
		);
	};

	return (
		<div ref={ref} id="success-stories" className={listClassnames}>
			<Heading isSubHeading icon={<Megaphone />}>
				{heading}
			</Heading>
			{renderPagination()}
			<div className="success__grid">
				{filteredStories.length
					? renderStories(filteredStories)
					: renderAlternative()}
			</div>
		</div>
	);
};

export default forwardRef(SuccessList);
