import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { polygon } from 'leaflet';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';
import { firestore } from 'firebase/app';
import 'leaflet/dist/leaflet.css';
import { Button, Toast, Loader } from 'common/components';
import Region from './Region';
import {
	setViewportZoomChange,
	clearEditor,
	setNewLatLng,
	handleDistrictSelect,
	handleDistrictView,
	selectDistrictToEdit,
	fetchChiefdomsSuccess,
	addTempIcon,
	selectChiefdomToEdit,
	handleMapRequest,
	fetchDistrictsSuccess,
	fetchMapFailure,
	fetchAllDistricts,
	fetchChiefdoms,
} from 'store/actions/mapActions';
import { ICON_DICTIONARY } from 'common/utils/icon-dictionary';
import './MapContainer.scss';

const Map = ({ isEditor }) => {
	const dispatch = useDispatch();
	const {
		loading,
		error,
		districts,
		districtBorders,
		chiefdomBorders,
		chiefdoms,
		zoom,
		center,
		selectedDistrictId,
		edit: { action, newLatLng, tempQuantity },
	} = useSelector((state) => state.map);

	useEffect(() => {
		dispatch(fetchAllDistricts());
		dispatch(handleMapRequest());
		const districtSubscriber = firestore()
			.collection('districts')
			.onSnapshot((querySnapshot) => {
				const queryDistricts = [];
				if (querySnapshot) {
					querySnapshot.forEach((documentSnapshot) => {
						queryDistricts.push({
							...documentSnapshot.data(),
							_id: documentSnapshot.id,
						});
					});
					dispatch(fetchDistrictsSuccess(queryDistricts));
				} else {
					dispatch(fetchMapFailure());
				}
			});
		return districtSubscriber;
	}, []);

	useEffect(() => {
		if (selectedDistrictId && zoom === 10) {
			const { chiefdoms: chiefdomIds } = districtBorders.find(
				(district) => district._id === selectedDistrictId
			);
			dispatch(fetchChiefdoms(chiefdomIds));
			dispatch(handleMapRequest());
			const chiefdomSubscriber = firestore()
				.collection('chiefdoms')
				.where('district', '==', selectedDistrictId)
				.onSnapshot((querySnapshot) => {
					if (querySnapshot) {
						const queryChiefdoms = [];
						querySnapshot.forEach((docSnapshot) => {
							queryChiefdoms.push({
								...docSnapshot.data(),
								border: [],
								_id: docSnapshot.id,
							});
						});
						dispatch(fetchChiefdomsSuccess(queryChiefdoms));
					}
				});
			return chiefdomSubscriber;
		}
	}, [selectedDistrictId, zoom]);

	const handleChange = (event) => {
		if (event.zoom !== zoom) dispatch(setViewportZoomChange(event.zoom));
	};

	const handleMapReset = () => {
		dispatch(clearEditor());
	};

	const getLabelPosition = (isSelectedRegion, label_pos, border) => {
		if (isSelectedRegion && action === 'edit-region-name' && newLatLng)
			return newLatLng;
		if (label_pos) return label_pos;
		return polygon(border).getBounds().getCenter();
	};

	const isLabelDraggable = (isSelectedRegion) => {
		if (isEditor && isSelectedRegion && action === 'edit-region-name')
			return true;
		return false;
	};

	const onDragEnd = (event) => {
		dispatch(setNewLatLng(event.target._latlng));
	};

	const handleEditorAction = (latlng) => {
		if (ICON_DICTIONARY[action])
			dispatch(
				addTempIcon([
					{
						lat: latlng.lat,
						lng: latlng.lng,
						type: action,
						quantity: tempQuantity || 1,
					},
				])
			);
	};

	const handleRegionClick = ({
		isChiefdom,
		isSelectedRegion,
		region,
		latlng,
		border,
	}) => {
		if (isChiefdom) {
			if (!isEditor) return;
			if (!isSelectedRegion) {
				dispatch(selectChiefdomToEdit(region));
				return;
			}
			if (action && !ICON_DICTIONARY[action]) {
				dispatch(setNewLatLng(latlng));
				return;
			}
			handleEditorAction(latlng);
			return;
		} else {
			const { lat, lng } = polygon(border).getBounds().getCenter();
			if (!isSelectedRegion) {
				dispatch(
					handleDistrictView({
						coords: [lat, lng],
						id: region._id,
					})
				);
				if (isEditor) dispatch(selectDistrictToEdit(region));
			} else {
				if (isEditor && action) {
					handleEditorAction(latlng);
					return;
				}
				dispatch(handleDistrictSelect([lat, lng]));
			}
		}
	};

	const renderDistricts = () =>
		districts.map((district) => {
			const { border } = districtBorders.find((el) => el._id === district._id);
			if (!border) return null;
			return (
				<Region
					key={district._id}
					region={district}
					isEditor={isEditor}
					labelPosition={getLabelPosition}
					isLabelDraggable={isLabelDraggable}
					onDragEnd={onDragEnd}
					onRegionClick={handleRegionClick}
					border={border}
				/>
			);
		});

	const renderSelectedChiefdoms = () =>
		chiefdoms.map((chiefdom) => {
			const border = chiefdomBorders.find(
				(el) => el._id === chiefdom._id
			)?.border;
			if (!border) return null;
			return (
				<Region
					key={chiefdom._id}
					region={chiefdom}
					isEditor={isEditor}
					labelPosition={getLabelPosition}
					isLabelDraggable={isLabelDraggable}
					onDragEnd={onDragEnd}
					onRegionClick={handleRegionClick}
					border={border}
				/>
			);
		});

	const renderInfoBox = () => (
		<div className="map__info-box">
			{selectedDistrictId
				? 'Click again to see Chiefdoms...'
				: 'Click a district to see details...'}
		</div>
	);

	const renderLoadingOverlay = () => (
		<Loader
			className="map__loading-screen"
			type={'grid'}
			color={'#0072c7'}
			size={14}
		/>
	);

	return (
		<div className="map__container">
			<Toast type="error" show={error}>
				{'Error loading data. Please try again later.'}
			</Toast>
			{loading && renderLoadingOverlay()}
			{!isEditor && !chiefdoms.length && renderInfoBox()}
			<LeafletMap
				onViewportChange={handleChange}
				center={center}
				zoom={zoom}
				maxZoom={10}
				attributionControl
				zoomControl
				scrollWheelZoom={false}
				doubleClickZoom={false}
				animate
				zoomAnimation
				easeLinearity={0.35}
				gestureHandling
			>
				<TileLayer
					url={`https://api.mapbox.com/styles/v1/${process.env.REACT_APP_MAPBOX_USER}/${process.env.REACT_APP_MAPBOX_STYLE}/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
				/>
				{districts.length && districtBorders.length && renderDistricts()}
				{chiefdoms.length &&
					chiefdomBorders.length &&
					zoom === 10 &&
					renderSelectedChiefdoms()}
				{selectedDistrictId && (
					<Button primary className="map__reset-btn" onClick={handleMapReset}>
						{'Reset'}
					</Button>
				)}
			</LeafletMap>
		</div>
	);
};

Map.propTypes = {
	isEditor: PropTypes.bool,
};

Map.defaultProps = {
	isEditor: false,
};

export default Map;
