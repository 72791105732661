import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUICheckbox from '@material-ui/core/Checkbox';

const Checkbox = ({ isChecked, onChange, label }) => (
	<FormControlLabel
		control={
			<MUICheckbox
				checked={isChecked}
				onChange={event => onChange(event.target.checked)}
				color="primary"
			/>
		}
		label={label}
	/>
);

Checkbox.propTypes = {
	isChecked: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	label: PropTypes.string
};

Checkbox.defaultProps = {
	label: ''
};

export default Checkbox;
