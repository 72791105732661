import React from 'react';
import { StaticContainer, Image } from 'common/components';
import { Camp } from 'common/icons';
import aboutImage from 'common/images/about_the_programme.jpg';
import './AboutTheProgramme.scss';

const AboutTheProgramme = () => (
	<StaticContainer
		title="About the programme"
		icon={<Camp />}
		image={aboutImage}
	>
		<Image src={aboutImage} caption="Photo courtesy of UNFPA Sierra Leone" />
		<div className="about-text">
			<span className="about-text__section">
				{
					'The Saving Lives in Sierra Leone (SLiSL) programme aims to save women’s and children’s lives by improving the quality, availability and accessibility of Reproductive, Maternal, Newborn, Child and Adolescent Health (RMNCAH) services.'
				}
			</span>
			<span className="about-text__section">
				{
					'Funded by the Foreign Commonwealth and Development Office (FCDO),the programme aims to improve:'
				}
			</span>
			<ul className="about-text__list">
				<li>
					{
						'Basic and Comprehensive Emergency Obstetric and Newborn Care (BEmONC/CEmONC)'
					}
				</li>
				<li>{'Reproductive health services, including for adolescents'}</li>
				<li>
					{
						'Family planning through training health workers; increasing demand for family planning; and tackling key barriers to access by providing outreach services'
					}
				</li>
				<li>
					{
						'Availability of essential medicines for the Government of Sierra Leone’s (GoSL) Free Health Care (FHC) initiative, which also ensures a continuous supply of family planning commodities'
					}
				</li>
				<li>
					{
						'Proper nutrition through child malnutrition screening and nutrition supplements for pregnant women and children, as part of a new multi-sector nutrition strategy developed with support from SLiSL.'
					}
				</li>
			</ul>
			<span className="about-text__section">
				{
					' The five-year programme is divided into two phases: The first phase which ran from September 2016 to September 2018  focused on increasing access to preventive services including support for family planning, water, sanitation and long-lasting insecticide-treated bednets. The second phase begun in October 2018 and will ran until March 2021 building on the gains of the first phase to increase equitable access to the improved RMNCAH services, whilst strengthening priority health systems for more sustainable service delivery.'
				}
			</span>
			<span className="about-text__section">
				{
					'The programme is aligned to the Government of Sierra Leone’s (GoSL) health strategy and seeks to support priority, cost-effective, interventions to improve health service delivery and save child and maternal lives.'
				}
			</span>
		</div>
	</StaticContainer>
);

export default AboutTheProgramme;
