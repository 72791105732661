import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MaterialAutocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Loader from './Loader';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Autocomplete = ({
	options,
	onChange,
	value,
	loading,
	width,
	label,
	isTags,
	withMargin,
	error,
	required,
	helperText,
	onOpen,
	onClose,
}) => {
	const [inputText, setInputText] = useState('');

	const handleOnBlur = () => {
		if (inputText && isTags) {
			onChange([...value, inputText]);
		}
	};

	const handleChange = (event, newValue) => {
		onChange(newValue);
	};

	const handleInputChange = (event, newInput) => {
		const wordBreakSymbols = [',', ' '];
		setInputText(newInput);
		if (isTags && newInput && wordBreakSymbols.includes(newInput.slice(-1))) {
			const editedText = newInput.slice(0, -1);
			onChange([...value, editedText]);
		}
	};

	const renderTextField = (params) => (
		<TextField
			{...params}
			variant="outlined"
			margin={withMargin ? 'normal' : 'none'}
			label={label}
			required={required}
			error={error}
			helperText={helperText}
			InputProps={{
				...params.InputProps,
				endAdornment: (
					<>
						{loading && <Loader />}
						{params.InputProps.endAdornment}
					</>
				),
			}}
		/>
	);

	const optionItem = (option, { selected }) => (
		<>
			<Checkbox
				icon={icon}
				checkedIcon={checkedIcon}
				style={{ marginRight: 8 }}
				checked={selected}
			/>
			{option}
		</>
	);

	return (
		<MaterialAutocomplete
			style={{ width: width || '100%', maxWidth: '100%' }}
			freeSolo
			multiple={isTags}
			options={options}
			disableCloseOnSelect
			onChange={handleChange}
			value={value}
			loading={loading}
			onInputChange={handleInputChange}
			onBlur={handleOnBlur}
			renderOption={optionItem}
			renderInput={renderTextField}
			onOpen={onOpen}
			onClose={onClose}
		/>
	);
};

Autocomplete.propTypes = {
	options: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	loading: PropTypes.bool,
	width: PropTypes.number,
	label: PropTypes.string.isRequired,
	isTags: PropTypes.bool,
	withMargin: PropTypes.bool,
	required: PropTypes.bool,
	error: PropTypes.bool,
	helperText: PropTypes.string,
};

Autocomplete.defaultProps = {
	options: [],
	loading: false,
	width: undefined,
	value: '',
	isTags: false,
	withMargin: false,
	required: false,
	error: false,
	helperText: '',
};

export default Autocomplete;
