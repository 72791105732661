import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const Calendar = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={40}
		height={40}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 42 48"
	>
		<g data-name="Layer 2">
			<path d="M40 6h-5v2.5a3.5 3.5 0 01-7 0V6H15v2.5a3.5 3.5 0 01-7 0V6H2a1.993 1.993 0 00-2 2v38a1.993 1.993 0 002 2h38a1.993 1.993 0 002-2V8a1.993 1.993 0 00-2-2zm-2 38H4V18h34z"></path>
			<path d="M11.5 10A1.498 1.498 0 0013 8.5v-7a1.5 1.5 0 00-3 0v7a1.498 1.498 0 001.5 1.5zM31.5 10A1.498 1.498 0 0033 8.5v-7a1.5 1.5 0 00-3 0v7a1.498 1.498 0 001.5 1.5zM18.74 30.52l-5.27 4.95a1.444 1.444 0 00-.47 1.06 1.46 1.46 0 00.47 1.06 1.685 1.685 0 002.26 0L21 32.64l5.27 4.95a1.7 1.7 0 001.14.44 1.632 1.632 0 001.12-.44 1.425 1.425 0 000-2.12l-5.27-4.95 5.27-4.95a1.46 1.46 0 00.47-1.06 1.444 1.444 0 00-.47-1.06 1.664 1.664 0 00-2.26 0L21 28.4l-5.27-4.96A1.628 1.628 0 0014.6 23a1.611 1.611 0 00-1.13.44 1.425 1.425 0 000 2.12z"></path>
		</g>
	</svg>
);

export default Calendar;