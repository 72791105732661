import {
	HANDLE_MAP_REQUEST,
	FETCH_DISTRICTS_SUCCESS,
	FETCH_CHIEFDOMS_SUCCESS,
	FETCH_MAP_FAILURE,
	HANDLE_DISTRICT_SELECT,
	HANDLE_DISTRICT_VIEW,
	SET_VIEWPORT_VIEW_CHANGE,
	SELECT_EDIT_ACTION,
	SELECT_EDIT_DISTRICT,
	SELECT_EDIT_CHIEFDOM,
	SET_NEW_LAT_LNG,
	SELECT_ICON,
	ADD_TEMP_ICON,
	CLEAR_EDITOR,
	SET_TEMP_QUANTITY,
	FETCH_DISTRICT_BORDERS_SUCCESS,
	FETCH_CHIEFDOM_BORDERS_SUCCESS
} from '../types/mapTypes';

const DEFAULT_CENTER = [8.457699, -11.777027];
const DEFAULT_ZOOM = window.innerWidth < 600 ? 7 : 8;

const initialState = {
	districts: [],
	districtBorders: [],
	chiefdoms: [],
	chiefdomBorders: [],
	center: DEFAULT_CENTER,
	zoom: DEFAULT_ZOOM,
	selectedDistrictId: '',
	edit: {
		district: null,
		chiefdom: null,
		action: null,
		newLatLng: null,
		selectedIcon: null,
		tempIcon: [],
		tempQuantity: 1
	},
	loading: false,
	error: false
};

const getRegionToEdit = (region, action, state) => {
	if (action.type === FETCH_DISTRICTS_SUCCESS) 
		return action.payload.find(e => e._id === state.edit[region]?._id) || null;
};

const mapReducer = (state = initialState, action) => {
	switch (action.type) {
	case HANDLE_MAP_REQUEST:
		return {
			...state,
			loading: true,
			error: false
		};
	case FETCH_DISTRICTS_SUCCESS:
		return {
			...state,
			loading: false,
			error: false,
			districts: action.payload,
			edit: {
				...state.edit,
				district: getRegionToEdit('district', action, state)
			}
		};
	case FETCH_CHIEFDOMS_SUCCESS:
		return {
			...state,
			loading: false,
			error: false,
			chiefdoms: action.payload,
			edit: {
				...state.edit,
				chiefdom: getRegionToEdit('chiefdom', action, state)
			}
		};
	case FETCH_DISTRICT_BORDERS_SUCCESS:
		return {
			...state,
			districtBorders: action.payload,
			loading: false
		};
	case FETCH_CHIEFDOM_BORDERS_SUCCESS:
		return {
			...state,
			chiefdomBorders: action.payload,
			loading: false
		};
	case FETCH_MAP_FAILURE:
		return {
			...state,
			loading: false,
			error: true
		};
	case HANDLE_DISTRICT_SELECT:
		return {
			...state,
			zoom: 10,
			center: action.payload
		};
	case HANDLE_DISTRICT_VIEW:
		return {
			...state,
			zoom: 9,
			center: action.payload.coords,
			selectedDistrictId: action.payload.id,
			chiefdoms: []
		};
	case SET_VIEWPORT_VIEW_CHANGE:
		return {
			...state,
			zoom: action.payload
		};
	case SELECT_EDIT_ACTION:
		return {
			...state,
			edit: {
				...state.edit,
				action: action.payload
			}
		};
	case SELECT_EDIT_DISTRICT:
		return {
			...state,
			edit: {
				...state.edit,
				district: action.payload,
				chiefdom: null,
				action: null,
				newLatLng: null,
				selectedIcon: null,
				tempQuantity: 1
			}
		};
	case SELECT_EDIT_CHIEFDOM:
		return {
			...state,
			edit: {
				...state.edit,
				chiefdom: action.payload,
				action: null,
				newLatLng: null,
				selectedIcon: null,
				tempQuantity: 1
			}
		};
	case SET_NEW_LAT_LNG:
		return {
			...state,
			edit: {
				...state.edit,
				newLatLng: action.payload
			}
		};
	case SELECT_ICON:
		return {
			...state,
			edit: {
				...state.edit,
				selectedIcon: action.payload
			}
		};
	case ADD_TEMP_ICON:
		return {
			...state,
			edit: {
				...state.edit,
				tempIcon: action.payload
			}
		};
	case SET_TEMP_QUANTITY:
		return {
			...state,
			edit: {
				...state.edit,
				tempQuantity: action.payload
			}
		};
	case CLEAR_EDITOR:
		return{
			...state,
			center: DEFAULT_CENTER,
			zoom: DEFAULT_ZOOM,
			selectedDistrictId: '',
			chiefdoms: [],
			edit: {
				district: null,
				chiefdom: null,
				action: null,
				newLatLng: null,
				selectedIcon: null,
				tempIcon: [],
				tempQuantity: 1
			},
		};
	default:
		return state;
	}
};

export default mapReducer;
