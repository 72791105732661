import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { firestore, auth } from 'firebase/app';
import AccountTable from './AccountTable';
import CreateUpdateModal from './CreateUpdateModal';
import { DeleteModal, Heading, Toast } from 'common/components';
import { Network } from 'common/icons';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import './ManageAccounts.scss';

const useStyles = makeStyles({
	root: {
		marginBottom: 12,
		backgroundColor: '#53a2be',
		'&:hover': {
			backgroundColor: '#418aa3',
		},
	},
});

const ManageAccounts = () => {
	const classes = useStyles();
	const [selectedUser, setSelectedUser] = useState(null);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showDeletemodal, setShowDeleteModal] = useState(false);
	const [allUsers, setAllUsers] = useState([]);
	const [toast, setToast] = useState({ show: false });
	const { id } = useSelector((state) => state.user);

	useEffect(() => {
		const usersSubscription = firestore()
			.collection('users')
			.onSnapshot((querySnapshot) => {
				const users = [];
				querySnapshot.forEach((snapshot) => {
					const isAdmin = snapshot.data().email === 'devadmin@test.com';
					if (!isAdmin) {
						users.push({
							...snapshot.data(),
							id: snapshot.id,
						});
					}
				});
				setAllUsers(users);
			});
		return usersSubscription;
	}, []);

	const handleTrashClick = (user) => () => {
		setSelectedUser(user);
		setShowDeleteModal(true);
	};

	const handleEditClick = (user) => () => {
		setSelectedUser(user);
		setShowCreateModal(true);
	};

	const handleUserDelete = async (id) => {
		try {
			await firestore().collection('users').doc(id).delete();
			await auth().currentUser.delete();
			setToast({
				show: true,
				type: 'success',
				message: 'User successfully removed',
			});
		} catch (err) {
			setToast({ show: true, type: 'error', message: 'Error removing user' });
		}
	};

	const handleModalClose = () => {
		setSelectedUser(null);
		setShowDeleteModal(false);
	};

	const renderToast = () => (
		<Toast
			show={toast.show}
			type={toast?.type}
			onClose={() => setToast({ show: false })}
		>
			{toast.message}
		</Toast>
	);

	return (
		<div className="manage-account">
			{toast.show && renderToast()}
			{showCreateModal && (
				<CreateUpdateModal
					showModal={showCreateModal}
					onModalClose={() => {
						setSelectedUser(null);
						setShowCreateModal(false);
					}}
					user={selectedUser}
					setToast={setToast}
				/>
			)}
			{showDeletemodal && selectedUser && (
				<DeleteModal
					id={selectedUser.id}
					label={selectedUser.email}
					showModal={showDeletemodal}
					onModalClose={handleModalClose}
					onDelete={handleUserDelete}
				/>
			)}
			<Heading icon={<Network size={40} />}>{'Manage Accounts'}</Heading>
			<Fab
				className={classes.root}
				color="primary"
				aria-label="create new user"
				size="small"
				onClick={() => setShowCreateModal(true)}
			>
				<AddIcon />
			</Fab>
			<AccountTable
				users={allUsers}
				loggedId={id}
				handleTrashClick={handleTrashClick}
				handleEditClick={handleEditClick}
			/>
		</div>
	);
};

export default ManageAccounts;
