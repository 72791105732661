import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const Family = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={80}
		height={80}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 48.001 34"
	>
		<g data-name="Layer 2">
			<g data-name="Editable Icons">
				<path d="M1.503 18.979a1.5 1.5 0 001.5-1.5 6.218 6.218 0 013-5.36V21a4 4 0 008 0v-8.879a6.218 6.218 0 012.996 5.358 1.5 1.5 0 003 0l-.001-.026.001-.013a4.754 4.754 0 011.005-2.937V18a2.999 2.999 0 005.997 0v-3.5a4.754 4.754 0 011.005 2.939l-.002.039a1.5 1.5 0 003 0 6.64 6.64 0 013.997-5.693L33.073 19a5.018 5.018 0 109.843 0l-1.915-7.214a6.644 6.644 0 014 5.693 1.5 1.5 0 003 0c0-5.389-4.298-9.453-9.998-9.453a9.916 9.916 0 00-8.652 4.594 6.704 6.704 0 00-10.697.001A9.916 9.916 0 0010 8.026C4.3 8.026.003 12.09.003 17.48a1.5 1.5 0 001.5 1.5z"></path>
				<circle cx="38.003" cy="3" r="3"></circle>
				<circle cx="10.002" cy="3" r="3"></circle>
				<path d="M24.002 8a2.505 2.505 0 10-2.505-2.505A2.505 2.505 0 0024.002 8zM46 30H2a2 2 0 000 4h44a2 2 0 000-4z"></path>
			</g>
		</g>
	</svg>
);

export default Family;