import axios from 'axios';

class CompressionService {
	constructor() {
		this.service = axios.create({
			baseURL: `${process.env.REACT_APP_API_URL}/compress`,
			withCredentials: true,
			headers: {
				Authorization: process.env.REACT_APP_AUTHENTICATION,
			},
		});
	}

	thumbnail = (data) => this.service.post('/thumbnail', data);

	publicationImages = (data) => this.service.post('/publicationImages', data);

	document = (data) => this.service.post('/document', data);

	galleryImage = (data) => this.service.post('/galleryImage', data);
}

export default CompressionService;
