import moment from 'moment-timezone';
import { firestore } from 'firebase/app';

export const dateNow = () => moment().set({ second: 0, millisecond: 0 });

const momentToTimestamp = (date) =>
	firestore.Timestamp.fromDate(new Date(date));

export const formatDisplayDate = (date) => moment(date).format('D MMM YYYY');

export const formatTableDate = (date) => moment(date).format('LL');

export const timestampFromDate = (date) => {
	const momentDate = date ? moment(date) : moment();
	const wholeDate = momentDate.set({ second: 0, millisecond: 0 });
	return firestore.Timestamp.fromDate(new Date(wholeDate));
};

export const dateFromTimestamp = (timestamp) => {
	const date = timestamp.toDate();
	return formatDisplayDate(date);
};

export const dateFromResponse = (timestamp) => {
	const { _seconds: seconds, _nanoseconds: nanoseconds } = timestamp;
	const time = new firestore.Timestamp(seconds, nanoseconds);
	return moment(time.toDate()).toDate();
};

export const areTimestampsEqual = (dateOne, dateTwo) => {
	const oneRounded = momentToTimestamp(
		moment(dateOne.toDate()).set({ second: 0, millisecond: 0 })
	).valueOf();
	const twoRounded = momentToTimestamp(
		moment(dateTwo.toDate()).set({ second: 0, millisecond: 0 })
	).valueOf();
	return oneRounded === twoRounded;
};

export const objectToTimestamp = (dateObject) =>
	new firestore.Timestamp(dateObject.seconds, 0);

export const momentToUnix = (date) => {
	const dateToMoment = moment(date).set({ second: 0, millisecond: 0 });
	return dateToMoment.unix();
};
