import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MUIModal from '@material-ui/core/Modal';
import Icon from './Icon';
import './Modal.scss';

const useStyles = makeStyles({
	root: {
		display: 'flex',
	},
});

const Modal = ({ children, show, title, handleModalClose }) => {
	const styles = useStyles();

	if (!show) return null;
	return (
		<MUIModal
			className={styles.root}
			open={show}
			onClose={handleModalClose}
			style={{ overflow: 'scroll' }}
		>
			<div className="modal">
				<div className="modal__title-container">
					<span className="modal__title-container__title">{title}</span>
					<Icon
						name="close"
						className="modal__title-container__close-btn"
						onClick={handleModalClose}
					/>
				</div>
				<div className="modal__child">{children}</div>
			</div>
		</MUIModal>
	);
};

Modal.propTypes = {
	children: PropTypes.node.isRequired,
	show: PropTypes.bool,
	handleModalClose: PropTypes.func.isRequired,
	title: PropTypes.string,
};

Modal.defaultProps = {
	show: false,
	title: '',
};

export default Modal;
