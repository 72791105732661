/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Heading, Loader, Unavailable, Pagination } from 'common/components';
import { getCollection } from 'common/utils/firebase-tools';
import { Document } from 'common/icons';
import { setPublications } from 'store/actions/publicationActions';
import { DOCTYPES, DOCTYPE_DICTIONARY } from 'common/utils/docType-dictionary';
import DocumentCard from './DocumentCard';
import './DocumentsList.scss';

const LIMIT = 3;

const DocumentsList = ({ onLoadComplete }, ref) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const { documents: data, filters } = useSelector(
		(state) => state.publications
	);
	const [factSheet, setFactSheet] = useState({});

	const fetchData = async () => {
		setLoading(true);
		const fetchedDocs = await getCollection(DOCTYPES.DOCUMENT);
		dispatch(setPublications({ docs: fetchedDocs.data }));
		setFactSheet(fetchedDocs.factSheet);
		setLoading(false);
		onLoadComplete(DOCTYPE_DICTIONARY.dt1);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const filteredDocs = data.filter((doc) => {
		const { keywords, from, to } = filters;
		if (keywords.length) {
			const keywordIncluded = keywords.some((keyword) => {
				if (
					doc.title.match(new RegExp(keyword, 'i')) ||
					doc.tags.includes(keyword)
				) {
					return true;
				}
			});
			if (keywordIncluded) return true;
		} else if (from && to) {
			const { publication_date } = doc;
			if (publication_date.valueOf() >= from && publication_date <= to)
				return true;
		} else {
			return true;
		}
		return false;
	});

	const renderAlternative = () =>
		loading ? <Loader type="bar" /> : <Unavailable />;

	const renderDocument = (docs) => {
		return docs.map((document, index) => {
			const lowestIndex = LIMIT * currentPage - LIMIT;
			const maxIndex = LIMIT * currentPage - 1;
			if (index >= lowestIndex && index <= maxIndex) {
				return (
					<DocumentCard
						key={document._id}
						id={document._id}
						document={document}
						title={document.title}
						file={document.file}
						organisation={document.organisation}
						publicationDate={document.publication_date}
						tags={document.tags}
						description={document?.description}
						thumbnail={document?.thumbnail}
						shareLink={document?.shareLink}
					/>
				);
			}
			return null;
		});
	};

	const renderPagination = () => {
		if (loading || Math.ceil(filteredDocs.length / 4) <= 1) return null;
		return (
			<div className="success__pagination">
				<Pagination
					pageCount={Math.ceil(filteredDocs.length / LIMIT)}
					currentPage={currentPage}
					onChange={(event, page) => setCurrentPage(page)}
				/>
			</div>
		);
	};

	if (!loading && !filteredDocs.length) return null;

	return (
		<div ref={ref} id="documents" className="documents">
			<Heading isSubHeading icon={<Document />}>
				{'Publications'}
			</Heading>
			{renderPagination()}
			<div className="documents__list">
				{filteredDocs.length
					? renderDocument(filteredDocs)
					: renderAlternative()}
			</div>
			{factSheet.file && (
				<DocumentCard
					id={factSheet._id}
					title={factSheet.title}
					file={factSheet.file}
					organisation={factSheet.organisation}
					publicationDate={factSheet.publication_date}
					description={factSheet?.description}
					tags={factSheet.tags}
					thumbnail={factSheet.thumbnail}
					shareLink={factSheet.shareLink}
				/>
			)}
		</div>
	);
};

export default forwardRef(DocumentsList);
