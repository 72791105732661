import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'common/components';

import './DeleteModal.scss';

const DeleteModal = ({
	label,
	id,
	onModalClose,
	showModal,
	onDelete,
	handleRedirect,
}) => {
	const [loading, setLoading] = useState(false);
	const handleDeleteUser = async () => {
		setLoading(true);
		try {
			if (id) {
				await onDelete(id);
			} else {
				await onDelete();
			}
			setLoading(false);
			onModalClose();
			if (handleRedirect) {
				handleRedirect();
			}
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};
	return (
		<Modal
			title={'Just checking...'}
			show={showModal}
			handleModalClose={onModalClose}
		>
			<div className="delete-modal">
				<div className="delete-modal__message">
					<span>{'Are you sure you want to delete '}</span>
					<span className={label ? 'delete-modal__message__email' : ''}>
						{label || 'this'}
					</span>
					<span>{'?'}</span>
				</div>
				<div className="delete-modal__buttons">
					<Button outlined onClick={onModalClose}>
						{'Cancel'}
					</Button>
					<Button warning onClick={handleDeleteUser} loading={loading}>
						{'Delete'}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

DeleteModal.propTypes = {
	user: PropTypes.shape({
		email: PropTypes.string,
	}),
	onModalClose: PropTypes.func.isRequired,
	showModal: PropTypes.bool.isRequired,
	label: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
	onDelete: PropTypes.func.isRequired,
	handleRedirect: PropTypes.func,
};

DeleteModal.defaultProps = {
	user: null,
	handleRedirect: null,
	label: '',
	id: '',
};

export default DeleteModal;
