import {
	HANDLE_PUBLICATION_REQUEST,
	SET_FILTERS,
	CLEAR_FILTERS,
	SET_ORGANISAIONS,
	SET_PUBLICATIONS,
} from '../types/publicationTypes';

export const fetchPublicationRequest = () => ({
	type: HANDLE_PUBLICATION_REQUEST,
});

export const setPublications = (publications) => ({
	type: SET_PUBLICATIONS,
	payload: publications,
});

export const setFilters = (filters) => ({
	type: SET_FILTERS,
	payload: filters,
});

export const clearFilters = () => ({
	type: CLEAR_FILTERS,
});

export const setOrganisations = (organisations) => ({
	type: SET_ORGANISAIONS,
	payload: organisations,
});
