import MapService from '../services/MapService';
import {
	HANDLE_MAP_REQUEST,
	FETCH_DISTRICTS_SUCCESS,
	FETCH_CHIEFDOMS_SUCCESS,
	FETCH_MAP_FAILURE,
	HANDLE_DISTRICT_SELECT,
	HANDLE_DISTRICT_VIEW,
	SET_VIEWPORT_VIEW_CHANGE,
	SELECT_EDIT_ACTION,
	SELECT_EDIT_DISTRICT,
	SELECT_EDIT_CHIEFDOM,
	SET_NEW_LAT_LNG,
	SELECT_ICON,
	SET_NEW_CHIEFDOM_LABEL,
	ADD_TEMP_ICON,
	CLEAR_EDITOR,
	SET_TEMP_QUANTITY,
	FETCH_DISTRICT_BORDERS_SUCCESS,
	FETCH_CHIEFDOM_BORDERS_SUCCESS,
} from '../types/mapTypes';

const service = new MapService();

export const handleDistrictSelect = (coords) => ({
	type: HANDLE_DISTRICT_SELECT,
	payload: coords,
});

export const handleDistrictView = ({ coords, id }) => ({
	type: HANDLE_DISTRICT_VIEW,
	payload: { coords, id },
});

export const setViewportZoomChange = (zoom) => ({
	type: SET_VIEWPORT_VIEW_CHANGE,
	payload: zoom,
});

export const selectEditAction = (action) => ({
	type: SELECT_EDIT_ACTION,
	payload: action,
});

export const selectDistrictToEdit = (district) => ({
	type: SELECT_EDIT_DISTRICT,
	payload: district,
});

export const selectChiefdomToEdit = (chiefdom) => ({
	type: SELECT_EDIT_CHIEFDOM,
	payload: chiefdom,
});

export const editChiefdomLabel = (chiefdom) => ({
	type: SET_NEW_CHIEFDOM_LABEL,
	payload: chiefdom,
});

export const setNewLatLng = (coords) => ({
	type: SET_NEW_LAT_LNG,
	payload: coords,
});

export const setSelectedIcon = (icon) => ({
	type: SELECT_ICON,
	payload: icon,
});

export const addTempIcon = (icon) => ({
	type: ADD_TEMP_ICON,
	payload: icon,
});

export const setTempQuantity = (quantity) => ({
	type: SET_TEMP_QUANTITY,
	payload: quantity,
});

export const clearEditor = () => ({
	type: CLEAR_EDITOR,
});

// ========= MAP API SERVICE ========= BELOW HERE

export const handleMapRequest = () => ({
	type: HANDLE_MAP_REQUEST,
});

const fetchDistrictBordersSuccess = (data) => ({
	type: FETCH_DISTRICT_BORDERS_SUCCESS,
	payload: data,
});

const fetchChiefdomBordersSuccess = (data) => ({
	type: FETCH_CHIEFDOM_BORDERS_SUCCESS,
	payload: data,
});

export const fetchDistrictsSuccess = (districts) => ({
	type: FETCH_DISTRICTS_SUCCESS,
	payload: districts,
});

export const fetchChiefdomsSuccess = (chiefdoms) => ({
	type: FETCH_CHIEFDOMS_SUCCESS,
	payload: chiefdoms,
});

export const fetchMapFailure = () => ({
	type: FETCH_MAP_FAILURE,
});

export const fetchAllDistricts = () => {
	return (dispatch) => {
		dispatch(handleMapRequest());
		return service
			.getAllDistricts()
			.then(({ data }) => {
				dispatch(fetchDistrictBordersSuccess(data));
			})
			.catch(() => {
				dispatch(fetchMapFailure());
			});
	};
};

export const fetchChiefdoms = (chiefdoms) => {
	return (dispatch) => {
		dispatch(handleMapRequest());
		return service
			.getChiefdoms(chiefdoms)
			.then(({ data }) => {
				dispatch(fetchChiefdomBordersSuccess(data));
			})
			.catch(() => {
				dispatch(fetchMapFailure());
			});
	};
};
