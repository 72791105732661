import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const Camp = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={40}
		height={40}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 48.001 44"
	>
		<g data-name="Layer 2">
			<path d="M4.489 17.605l19.513-11.15 19.51 11.15a3 3 0 002.977-5.21L25.49.395a3 3 0 00-2.976 0l-21.002 12a3 3 0 002.977 5.21zM38.002 27.026a9.916 9.916 0 00-8.652 4.594 6.704 6.704 0 00-10.697.001A9.916 9.916 0 0010 27.026c-5.7 0-9.998 4.064-9.998 9.453a1.5 1.5 0 003 0 6.218 6.218 0 013-5.36v8.88a4 4 0 008 0v-8.878a6.218 6.218 0 012.997 5.358 1.5 1.5 0 003 0l-.002-.026.002-.013a4.754 4.754 0 011.004-2.937V37a2.999 2.999 0 105.998 0v-3.5a4.755 4.755 0 011.005 2.939l-.002.039a1.5 1.5 0 003 0 6.257 6.257 0 013.6-5.693L33 38a5.09 5.09 0 1010.015 0L41.4 30.786a6.258 6.258 0 013.6 5.693 1.5 1.5 0 103 0c0-5.389-4.298-9.453-9.998-9.453z"></path>
			<circle cx="38.002" cy="22" r="3"></circle>
			<circle cx="10.001" cy="22" r="3"></circle>
			<path d="M21.496 24.495a2.505 2.505 0 102.505-2.506 2.505 2.505 0 00-2.505 2.506z"></path>
		</g>
	</svg>
);

export default Camp;