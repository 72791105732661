import React from 'react';
import { Publications, Publication, Gallery } from 'pages';
import { useParams } from 'react-router-dom';

const Resources = () => {
	const { resourceType, docType, id } = useParams();

	if (resourceType === 'images' || resourceType === 'videos') {
		return <Gallery />;
	}

	if (docType && id) {
		return <Publication id={id} />;
	}

	return <Publications />;
};

export default Resources;
