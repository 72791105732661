import React from 'react';
import PropTypes from 'prop-types';
import { slide as Menu } from 'react-burger-menu';
import './Burger.scss';

const Burger = ({ children, handleStateChange, isOpen }) => (
	<Menu isOpen={isOpen} onStateChange={handleStateChange} right>
		{children}
	</Menu>
);

Burger.propTypes = {
	children: PropTypes.node.isRequired,
	handleStateChange: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
};

export default Burger;
