import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const Training = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={80}
		height={80}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 48 48"
	>
		<g data-name="Layer 2">
			<circle cx="13" cy="4" r="4"></circle>
			<path d="M46 0H24a2 2 0 00-2 2v8h-9.024C5.578 10 0 15.16 0 22a2 2 0 004 0 7.453 7.453 0 014-6.75V29.1c0 .03.007.057.009.086-.002.03-.009.057-.009.087V46a2 2 0 004 0V31h2v15a2 2 0 004 0V29.273c0-.03-.007-.057-.009-.087.002-.029.009-.056.009-.086V14h4v7a2 2 0 002 2h22a2 2 0 002-2V2a2 2 0 00-2-2zM30 10h-4V4h18v15H26v-5h4a2 2 0 000-4z"></path>
		</g>
	</svg>
);

export default Training;