import React, { useEffect, useState } from 'react';
import { firestore, auth } from 'firebase/app';
import { Heading, Input, Button, Redirect } from 'common/components';
import { userSchema } from 'common/yup/schemas';
import './SetupAccount.scss';

const SetupAccount = () => {
	const [email, setEmail] = useState(email);
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [redirect, setRedirect] = useState(false);

	useEffect(() => {
		auth().signOut();
	}, []);

	const handleEmailConfirm = () => {
		userSchema
			.validate({
				email,
				password,
				confirmPassword,
				isLink: auth().isSignInWithEmailLink(window.location.href),
			})
			.then(() => {
				if (error) {
					setError('');
				}
				setLoading(true);
				auth()
					.signInWithEmailLink(email, window.location.href)
					.then(async (result) => {
						try {
							const tempData = await firestore()
								.collection('tempAccounts')
								.doc(result.user.email)
								.get();
							const { role } = await tempData.data();
							await firestore()
								.collection('users')
								.doc(result.user.uid)
								.set({ email, role });
							await firestore()
								.collection('tempAccounts')
								.doc(result.user.email)
								.delete();
							await auth().currentUser.updatePassword(password);
							await auth().signOut();
							const loggedUser = await auth().signInWithEmailAndPassword(
								email,
								password
							);
							if (loggedUser) {
								setLoading(false);
								setRedirect(true);
							}
						} catch (err) {
							console.log(err);
							setLoading(false);
						}
					})
					.catch((err) => {
						setLoading(false);
						console.log(err);
						if (err.code === 'auth/invalid-email') {
							setError(err.message);
						} else {
							setError(
								'This is not a valid url for signing in, or this link has expired'
							);
						}
					});
			})
			.catch((err) => {
				setError(err.message);
			});
	};
	return (
		<>
			<Redirect shouldRedirect={redirect} to="/" />
			<div id="setup-account">
				<Heading>{'Create your account'}</Heading>
				<div className="setup__container">
					{error && <span className="setup__error-message">{error}</span>}
					<Input
						className="setup__email-input"
						label="Confirm your email"
						value={email}
						onChange={setEmail}
					/>
					<Input
						className="setup__email-input"
						label="Password"
						value={password}
						onChange={setPassword}
						type="password"
						helperText="Must be between 4 and 8 digits long and include at least one numeric digit."
					/>
					<Input
						className="setup__email-input"
						label="Confirm password"
						value={confirmPassword}
						onChange={setConfirmPassword}
						type="password"
					/>
					<Button
						disabled={!email}
						onClick={handleEmailConfirm}
						loading={loading}
					>
						{'Submit'}
					</Button>
				</div>
			</div>
		</>
	);
};

export default SetupAccount;
