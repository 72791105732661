import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const Alert = props => (
	<MuiAlert elevation={6} variant="filled" {...props} />
);

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		}
	}
}));

const Toast = ({ show, type, children, onClose, duration }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Snackbar 
				open={show} 
				autoHideDuration={duration} 
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				onClose={onClose}
			>
				<Alert
					severity={type}
				>
					{children}
				</Alert>
			</Snackbar>
		</div>
	);
};

Toast.propTypes = {
	show: PropTypes.bool.isRequired,
	type: PropTypes.string,
	children: PropTypes.string.isRequired,
	duration: PropTypes.number,
	onClose: PropTypes.func
};

Toast.defaultProps = {
	duration: 5000,
	onClose: () => {},
	type: ''
};

export default Toast;
