import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import KeyItem from './MapKeyItem';
import Chevron from 'common/icons/Chevron';
import './MapKey.scss';

const MapKey = () => {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (window.innerWidth >= 950) setIsOpen(true);
	}, []);

	const legendClassnames = classnames(
		'map-key__legend',
		isOpen && 'map-key__legend--open'
	);

	return (
		<div className="map-key">
			<div className="map-key__title" onClick={() => setIsOpen(!isOpen)}>
				{'Legend'}
				<Chevron className="map-key__title__chevron" isOpen={isOpen} />
			</div>
			<div className={legendClassnames}>
				<div className="map-key__legend__regions">
					<KeyItem
						isRegion
						color="blue"
						description="Goal (Regional lead in Northern Region),"
						districtLead="district lead (incl. DHMT support)"
					/>
					<KeyItem
						isRegion
						color="orange"
						description="IRC (Regional lead in Eastern Region),"
						districtLead="district lead (incl. DHMT support)"
					/>
					<KeyItem
						isRegion
						color="purple"
						description="CUAMM (Regional lead Southern Region),"
						districtLead="district lead (incl. DHMT support)"
					/>
					<KeyItem
						isRegion
						color="yellow"
						description="Concern (Regional lead Western Region),"
						districtLead="district lead (incl. DHMT support)"
					/>
				</div>
				<div className="map-key__legend__icons">
					<KeyItem icon="ic1" description="Borehole" />
					<KeyItem icon="ic2" description="Hand dug well" />
					<KeyItem icon="ic3" description="Support to CHCs, no. of CHCs" />
					<KeyItem
						icon="ic4"
						description="Support to district hospital, no. of district hospitals"
					/>
					<KeyItem
						icon="ic5"
						description="Support to tertiary hospital, no. of tertiary hospitals"
					/>
					<KeyItem icon="ic6" description="Support to midwifery school" />
					<KeyItem icon="ic7" size={'2x'} description="SBCU in place" />
					<KeyItem icon="ic9" description="Surgical Assistant CHO training" />
					<KeyItem icon="ic10" description="Nurse Anaesthetist training" />
					<KeyItem
						icon="ic11"
						size="2x"
						description="EmONC construction in phase 1 (completed)"
					/>
				</div>
			</div>
		</div>
	);
};

export default MapKey;
