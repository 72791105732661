import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'common/components';
import './ImagePlaceholder.scss';
import { fadedMontroseBlue } from '../scss/variables/colors.scss';

const ImagePlaceholder = ({ containerClassName, className }) => (
	<div className={containerClassName}>
		<Icon
			className={className}
			name="image"
			size="5x"
			color={fadedMontroseBlue}
		/>
	</div>
);

ImagePlaceholder.propTypes = {
	className: PropTypes.string,
	containerClassName: PropTypes.string,
};

ImagePlaceholder.defaultProps = {
	className: '',
	containerClassName: '',
};

export default ImagePlaceholder;
