import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { firestore } from 'firebase/app';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import { Heading, Loader } from 'common/components';
import './Publication.scss';
import { useHistory } from 'react-router-dom';

const Publication = ({ id }) => {
	const { successStories } = useSelector((state) => state.publications);
	const history = useHistory();
	const [publication, setPublication] = useState(null);

	const fetchStoryFromFirebase = async () => {
		try {
			const doc = await firestore().collection('dt2').doc(id).get();
			if (!doc.exists) {
				history.replace('/resources');
			}
			setPublication(doc.data());
		} catch (err) {
			throw new Error();
		}
	};
	useEffect(() => {
		if (successStories.length > 0) {
			const pub = successStories.find((story) => story._id === id);
			if (pub) {
				setPublication(pub);
			} else {
				fetchStoryFromFirebase();
			}
		} else {
			fetchStoryFromFirebase();
		}
		window.scrollTo(0, 0);
	}, []);

	if (!publication)
		return <Loader className="publication--loading" type="bar" />;

	return (
		<div className="publication">
			<Helmet>
				<title>{publication?.title}</title>
			</Helmet>
			<Heading>{publication?.title}</Heading>
			{ReactHtmlParser(publication.description)}
		</div>
	);
};

Publication.propTypes = {
	// match: PropTypes.shape({
	// 	params: PropTypes.shape({
	// 		id: PropTypes.string,
	// 		docType: PropTypes.string,
	// 	}),
	// }).isRequired,
	id: PropTypes.string.isRequired,
};

export default Publication;
