import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'common/components';
import { Route } from 'react-router-dom';

const AdminRoute = ({ children, ...rest }) => {
	const { role, id } = useSelector((state) => state.user);
	const isAdmin = role === 'ADMIN';
	const isLogged = id;

	const handleRedirect = (path, location) => (
		<Redirect
			shouldRedirect
			to={{
				pathname: path,
				state: { from: location },
			}}
		/>
	);
	return (
		<Route
			{...rest}
			render={({ location }) =>
				isAdmin
					? children
					: isLogged
					? handleRedirect('/', location)
					: handleRedirect('/login', location)
			}
		/>
	);
};

AdminRoute.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AdminRoute;
