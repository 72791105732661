import React from 'react';
import PropTypes from 'prop-types';
import './PublicationInput.scss';

const PublicationInput = ({ title, ...props }) => (
	<div className="publish-title">
		<div className="publish-title__container">
			<span>{title}</span>
			<input {...props} />
		</div>
	</div>
);

PublicationInput.propTypes = {
	title: PropTypes.string.isRequired,
};

export default PublicationInput;
