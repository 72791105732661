import {
	firestore,
	analytics,
	auth,
	storage,
	initializeApp,
	functions,
	app,
} from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';

import { DOCTYPES } from 'common/utils/docType-dictionary';
import CompressionService from '../../store/services/CompressionService';
import Axios from 'axios';

const compressionService = new CompressionService();

const config = {
	apiKey: process.env.REACT_APP_FIREBASE_API,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export const startFirebaseApp = () => {
	initializeApp(config);
	analytics().setAnalyticsCollectionEnabled(false);
	if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
		functions().useFunctionsEmulator('http://localhost:5001');
	}
};

// FUNCTIONS

export const apiCall = (name, data) =>
	process.env.REACT_APP_ENVIRONMENT === 'dev'
		? functions().httpsCallable(name)(data)
		: app().functions('europe-west2').httpsCallable(name)(data);

// CALENDAR

export const uploadEventFiles = async (files, eventId) => {
	try {
		const storageRef = await storage().ref();
		const documents = [];
		for await (const file of files) {
			if (file) {
				if (file?.downloadUrl) {
					documents.push(file);
				} else {
					const documentRef = await storageRef.child(
						`events/${eventId}/${file.name}`
					);
					const {
						metadata: { fullPath, name, size },
					} = await documentRef.put(file);
					const downloadUrl = await storage().ref(fullPath).getDownloadURL();
					const rebrandlyResponse = await Axios.post(
						'https://api.rebrandly.com/v1/links',
						{
							destination: downloadUrl,
							domain: {
								id: 'e45734ff561a4334b9a1eb5a33afb3dd',
							},
						},
						{
							headers: {
								Accept: 'application/json',
								apikey: 'd78ce9218fd4403b88628c83e0cc14dd',
							},
						}
					);
					const shortUrl = rebrandlyResponse.data.shortUrl;
					documents.push({ path: fullPath, name, size, downloadUrl: shortUrl });
				}
			}
		}
		return documents;
	} catch (err) {
		throw new Error(err?.data, err);
	}
};

// ===== FIRESTORE =====

export const getUser = (userId) =>
	firestore().collection('users').doc(userId).get();

export const updateUser = (userId, data) =>
	firestore().collection('users').doc(userId).update(data);

export const setTempAccount = (email, role) =>
	firestore().collection('tempAccounts').doc(email).set({ role });

export const fetchOrganisations = async () => {
	const docs = await getCollection(DOCTYPES.DOCUMENT);
	const stories = await getCollection(DOCTYPES.STORY);
	return { docs: docs.data, stories: stories.data };
};

export const getCollection = async (collection) => {
	const firebaseCollection = await firestore()
		.collection(collection)
		.orderBy('publication_date', 'desc')
		.get();
	if (firebaseCollection.empty) {
		return;
	}
	const data = [];
	let factSheet = null;
	firebaseCollection.forEach((doc) => {
		if (!doc.data().title) return;
		if (collection === DOCTYPES.DOCUMENT && !doc.data().file) return;
		if (doc.id === 'fact-sheet') {
			factSheet = {
				...doc.data(),
				_id: doc.id,
			};
		} else {
			data.push({
				...doc.data(),
				_id: doc.id,
			});
		}
	});
	return { data, factSheet };
};

// ===== PUBLICATIONS =====

export const createPublication = async (collection, data) =>
	firestore().collection(collection).add(data);

export const getPublication = (collection, id) =>
	firestore().collection(collection).doc(id).get();

export const updatePublication = (collection, id, data) =>
	firestore().collection(collection).doc(id).update(data);

// ===== GALLERY =====

export const addGalleryItem = async ({ image, description, length }) => {
	try {
		const imageData = new FormData();
		imageData.append('image', image);
		const response = await compressionService.galleryImage(imageData);
		const { url, path } = response.data;
		await addGalleryItemToFirestore({
			url,
			description,
			index: length,
			path,
		});
	} catch (err) {
		throw new Error(err);
	}
};

export const updateGallery = (images) => {
	const reversedImages = images.reverse();
	const batch = firestore().batch();
	const gallery = firestore().collection('gallery');
	reversedImages.forEach((image, index) => {
		batch.update(gallery.doc(image.id), {
			index,
			description: image.description,
		});
	});
	return batch.commit();
};

export const addGalleryItemToFirestore = ({ url, description, index, path }) =>
	firestore().collection('gallery').add({
		url,
		description,
		index,
		path,
	});

export const updateGalleryDescription = (id, description) =>
	firestore().collection('gallery').doc(id).update({ description });

export const deleteGalleryItem = async ({ id, path }, remainingImages) => {
	try {
		const reversedImages = remainingImages.reverse();
		const batch = firestore().batch();
		const gallery = firestore().collection('gallery');
		reversedImages.forEach((image, index) => {
			batch.update(gallery.doc(image.id), { index });
		});
		batch.delete(gallery.doc(id));
		await batch.commit();
		await storage().ref().child(path).delete();
	} catch (err) {
		throw new Error(err);
	}
};

// ===== STORAGE =====

export const getDocUrl = async (reference) => {
	let url = '';
	if (reference) {
		try {
			url = await storage().ref(reference).getDownloadURL();
		} catch (err) {
			url = '';
		}
	}
	return url;
};

export const getAllGallery = async () => {
	const storage = await storage().ref().child('gallery').listAll();
	if (storage.items.length) {
		const references = [];
		for await (const item of storage.items) {
			const url = await storage().ref(item.location.path_).getDownloadURL();
			references.push({
				url,
				ref: item.location.path_,
			});
		}
		return references;
	}
	return [];
};

const removeStorage = async (id, bin) => {
	try {
		const docs = await storage().ref().child(`${bin}/${id}`).listAll();
		if (docs.items.length > 0) {
			for await (const item of docs.items) {
				storage().ref(item.location.path_).delete();
			}
		}
		return true;
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const removeStoredItem = async (collection, id, bin) => {
	try {
		await removeStorage(id, bin);
		const deleted = await firestore().collection(collection).doc(id).delete();
		if (deleted) {
			return true;
		}
	} catch (err) {
		console.log(err);
		throw new Error();
	}
};

// ===== AUTH =====

export const signIn = async (email, password) => {
	try {
		const loggedUser = await auth().signInWithEmailAndPassword(email, password);
		logEvent('login', {
			email,
		});
		return loggedUser.user.uid;
	} catch (err) {
		throw new Error(err);
	}
};

export const onAuthStateChanged = (callback) =>
	auth().onAuthStateChanged(callback);

export const sendSignInLinkToEmail = (email, actionCodeSettings) =>
	auth().sendSignInLinkToEmail(email, actionCodeSettings);

export const sendPasswordResetEmail = (email) =>
	auth().sendPasswordResetEmail(email);

// ===== ANALYTICS =====
export const logEvent = (label, data) => {
	analytics().logEvent(label, data);
};

export const setCurrentScreen = (screen) => {
	analytics().setCurrentScreen(screen);
};

export const initialiseAnalytics = () => {
	analytics();
	analytics().setAnalyticsCollectionEnabled(true);
};
