import React from 'react';
import PropTypes from 'prop-types';
import './Text.scss';

export const SubHeading = ({ children }) => (
	<p className="text-sub-heading">{children}</p>
);

SubHeading.propTypes = {
	children: PropTypes.string.isRequired,
};
