import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { ImagePlaceholder, EditButton, Tag } from 'common/components';
import { formatDisplayDate } from 'common/utils/format-date';
import { logEvent } from 'common/utils/firebase-tools.js';
import { DOCTYPES } from 'common/utils/docType-dictionary';
import './SuccessCard.scss';

const SuccessCard = ({ story, hideTags }) => {
	const [imageLoading, setImageLoading] = useState(true);
	const { role } = useSelector((state) => state.user);
	const { _id, organisation, title, tags } = story;
	const canEdit = role === 'ADMIN' || role === 'EDITOR';

	const imageClassnames = classnames(
		imageLoading ? 'content-box__thumb--hidden' : 'content-box__thumb'
	);

	const handleImageLoaded = () => {
		setImageLoading(false);
	};

	const handleCardClick = () => {
		logEvent('success story click', {
			['story title']: story.title,
		});
	};

	if ((!title, !_id, !organisation, !tags)) {
		return null;
	}

	const renderTags = () => {
		if (!story.tags.length || hideTags) return null;
		return (
			<div>
				{story.tags.map((tag) => (
					<Tag key={tag} tag={tag} />
				))}
			</div>
		);
	};

	return (
		<div className="success-grid-item content-box">
			{canEdit && <EditButton id={story._id} docType={DOCTYPES.STORY} />}
			<div className="inner">
				{imageLoading && (
					<ImagePlaceholder className="content-box__thumb placeholder" />
				)}
				<img
					className={imageClassnames}
					src={story.thumbnail?.downloadUrl}
					onLoad={handleImageLoaded}
				/>
				<p className="content-box__header">{story.organisation}</p>
				<p className="content-box__date">
					{formatDisplayDate(story.publication_date.toDate())}
				</p>
				<p className="content-box__blurb">{story.title}</p>
				<Link
					className="readmore-link"
					to={`/resources/success-story/${story._id}`}
					onClick={handleCardClick}
				>
					{'read more'}
				</Link>
				{renderTags()}
			</div>
		</div>
	);
};

SuccessCard.propTypes = {
	story: PropTypes.shape({
		_id: PropTypes.string,
		organisation: PropTypes.string,
		publication_date: PropTypes.shape({
			toDate: PropTypes.func,
		}),
		title: PropTypes.string,
		image: PropTypes.shape({
			path: PropTypes.string,
		}),
		tags: PropTypes.array,
		thumbnail: PropTypes.shape({
			downloadUrl: PropTypes.string,
		}),
	}).isRequired,
	hideTags: PropTypes.bool,
};

SuccessCard.defaultProps = {
	hideTags: false,
};

export default SuccessCard;
