import React from 'react';
import LazyLoad from 'react-lazy-load';
import { SuccessList, TwitterTimeline } from 'common/components';
import {
	UNFPA,
	UNICEF,
	WHO,
	MontroseTagline,
	UNITE,
} from 'common/images/logos';
import Picture1 from 'common/images/homepage/picture_1.png';
import Picture2 from 'common/images/homepage/picture_2.png';
import Picture3 from 'common/images/homepage/picture_3.png';
import './Home.scss';
import ImageCarousel from 'common/components/ImageCarousel';

const Home = () => {
	return (
		<div className="homepage">
			<div className="homepage__image-container">
				<ImageCarousel
					photos={[
						{
							original: Picture1,
							title: {
								one: 'Increasing technical capacity',
								two: 'to handle medical emergencies',
							},
							id: 'SActv9vEuRTU22ZXb6Bm',
							originalHeight: 720,
						},
						{
							original: Picture2,
							title: {
								one: 'Information sharing through clinical mentorship',
								two: 'and community sensitisation',
							},
							id: 'g6cjrPbFRzriL9qLxp6D',
							originalHeight: 720,
						},
						{
							original: Picture3,
							title: {
								one: 'High Dependency Units to deliver quality maternal',
								two: 'and new-born health services',
							},
							id: 'I9ieHDLd3yJway4brW8M',
							originalHeight: 720,
						},
					]}
				/>

				<div className="homepage__image-container__text">
					<span>
						{
							'The Saving Lives in Sierra Leone (SLiSL) programme aims to end the preventable deaths of mothers, adolescents and children in Sierra Leone, while building towards resilient Universal Health Coverage.'
						}
					</span>
				</div>
			</div>
			<LazyLoad>
				<div className="homepage__information">
					<SuccessList
						className="homepage__information__success-stories"
						heading="Latest Success Stories"
						limit={4}
						hideTags
						hidePagination
					/>
					<TwitterTimeline />
				</div>
			</LazyLoad>
			<div className="homepage__partners">
				<img
					id="unfpa-logo"
					src={UNFPA}
					alt="United Nations Population Fund (UNFPA) logo"
				/>
				<img src={WHO} alt="World Health Organisation (WHO) logo" />
				<img src={UNICEF} alt="United Nations Children’s Fund (UNICEF) logo" />
				<img id="unite-logo" src={UNITE} alt="UNITE logo" />
				<img src={MontroseTagline} alt="Montrose logo" />
			</div>
		</div>
	);
};

export default Home;
