import React from 'react';
import { StaticContainer, Image } from 'common/components';
import { Team } from 'common/icons';
import Outreach from 'common/images/outreach-group-restless.jpg';
import Lactating from 'common/images/lactating-mothers-UNFPA.jpg';
import ClinicalMentorship from 'common/images/clinical-mentorship-GOAL.jpg';
import './WhatWeDo.scss';

const WhatWeDo = () => (
	<StaticContainer title="What we do" icon={<Team />}>
		<div className="what-we-do">
			<h3 className="what-we-do__subtitle">
				{
					'The Saving Lives in Sierra Leone (SLiSL) programme aims to end the preventable deaths of mothers, adolescents and children in Sierra Leone, while building towards resilient Universal Health Coverage.'
				}
			</h3>
			<div className="what-we-do__section">
				<h3 className="what-we-do__title">{'Improve Quality of Care'}</h3>
				<p>
					{
						'Good Quality of Care (QoC) can reduce preventable deaths occurring either before, during or after giving birth. The SLiSL programme supported the establishment of a QoC programme in the  Ministry of Health and Sanitation (MoHS) , and supported this programme to develop a national QoC policy and strategy and to establish leadership structures at the national, district, and community levels.'
					}
				</p>
				<p>
					{
						'The SLiSL programme has supported the expansion of the practise of death reviews, surveillance and response so that any death of mothers, babies and children occurring should be used to learn why the death happened and how to make changes to ensure it does not happen again. The aim of these reviews is to see changes occurring to prevent any unsafe practices occurring to protect future patients.'
					}
				</p>
				<p>
					{
						'SLiSL also works to improve health facilities so that services can be delivered to a higher quality. Therefore, 8 Emergency Obstetric and Newborn Care (EmONC) facilities have been built or renovated so that they are now able to provide a better quality of care for mothers and children.'
					}
				</p>
				<p>
					{
						'To ensure a higher quality of care, it is important that health providers have access to safe, clean running water. Washing hands is important to prevent infections. Therefore, the SLiSL programme has also worked to install water, sanitation and hygiene facilities in health centres across the country.'
					}
				</p>
			</div>

			<Image
				src={Outreach}
				caption="Outreach to adolescent group members by Restless Development in Pujehun district. ©Montrose/Saving Lives in Sierra Leone"
			/>

			<div className="what-we-do__section">
				<h3 className="what-we-do__title">
					{
						'Increase Demand for and Availability of Family Planning Services for Adolescents and Young People'
					}
				</h3>
				<p>
					{
						'The majority of  maternal deaths in Sierra Leone are from adolescent pregnancies and so family planning outreach to adolescent girls is especially important. The SLiSL programme runs service provision and education outreach sessions and community awareness-raising and youth-directed media/communication initiatives to increase demand for family planning services. Health workers receive special trainings on a mix of family planning methods.'
					}
				</p>
			</div>

			<div className="what-we-do__section">
				<h3 className="what-we-do__title">
					{
						'Increase the Number of Hospitals able to Receive Complex Reproductive Maternal, Newborn, Child, and Adolescent Health (RMNCAH) Referrals'
					}
				</h3>
				<p>
					{
						'SLiSL is working with health care workers at all levels to understand when and how to refer complex cases to more specialised service providers. For example, health care providers need to know at what point during a difficult labour should a pregnant woman be referred from a community clinic to a district hospital and how to use the ambulances bought, by FCDO among others, during the Ebola epidemic to ensure this happens on time to save both the mother and baby. Referral coordinators supported by SLiSL assist with getting the patients to the right place of care, and ensuring the receiving facility is ready for them.'
					}
				</p>
				<p>
					{
						'Post-Partum Haemorrhage (PPH) is still the greatest contributor to maternal death in Sierra Leone and often requires urgent blood transfusion for survival. Also children with malaria often need blood transfusions. The current blood service is based on replacement blood, where families must find a donor if their relatives need blood. The programme has started blood drive campaigns to encourage voluntary blood donations. The SLiSL programme is in the process of procuring blood fridges and other infrastructure improvements to ensure blood banks can be established to save more women’s and children’s lives.'
					}
				</p>
				<p>
					{
						'SLiSL is also supporting quality improvement at maternal and child hospital wards through training and mentoring by senior colleagues. Special Care Baby Units for critically ill newborns were established in 6 hospitals, with 6 more units to follow.'
					}
				</p>
			</div>

			<Image
				src={ClinicalMentorship}
				caption="Outreach Officers conducting clinical mentorship. ©GOAL/ Saving Lives in Sierra Leone"
			/>

			<div className="what-we-do__section">
				<h3 className="what-we-do__title">
					{
						'Improve the Supply of Free Health Care drugs and Family Planning Commodities'
					}
				</h3>
				<p>
					{
						'Since the launch of the Free Health Care Initiative (FHCI) in 2010, UNICEF with support from FCDO and other donors has procured  FHC drugs. However, as part of the SLiSL programme, FCDO and the Government of Sierra Leone (GoSL) have developed a co-financing agreement to cover the procurement of Free Health Care drugs. SLiSL also support the procurement of nutrition supplies (through UNICEF) and FP commodities (through UNFPA) Furthermore, SLiSL partners are supporting the government National Medical Supplies Agency (NMSA) to take on drug procurement. SLiSL also supports the Directorate of Medical Supplies (DDMS) and the Central Medical Stores (CMS) in warehousing and distribution to ensure that the drugs bought reach all health facilities in communities across the country so that the citizens of Sierra Leone have access to essential life-saving medicines.'
					}
				</p>
			</div>

			<div className="what-we-do__section">
				<h3 className="what-we-do__title">
					{'Improve Human Resources for Health'}
				</h3>
				<p>
					{
						'With one of the lowest under 5 survival rates in the world, rapid assessment and treatment of childhood illnesses is critical in Sierra Leone. SLiSL has been able to equip over 650 government healthcare workers in 13 government hospitals with the tools and techniques needed for Emergency Triage and Treatment (ETAT). This methodology looks to reduce the time between patient presentation at a healthcare facility, diagnosis and treatment, as well as improving the accuracy of diagnosis and treatment. Each minute saved can save a life and through this mentorship programme the SLiSL programme has already seen a reduction in neonatal mortality. Similar mentoring is applied to the area of family planning, EmONC, IMNCI and IPC at community clinics and hospitals. SLiSL is also supporting the training of new midwives, surgical assistants and anaesthetic nurses – all vital for the saving lives of mothers and children. SLiSL also supports the CHW programme, with community health workers attached to community clinics throughout the country.'
					}
				</p>
			</div>

			<Image
				src={Lactating}
				caption="Lactating Mothers Await Post-Natal Care at UNFPA-supported Primary Health Unit. ©UNFPA/ Saving Lives in Sierra Leone"
			/>

			<div className="what-we-do__section">
				<h3 className="what-we-do__title">{'Health System Strengthening'}</h3>
				<p>
					{
						'SLiSL strengthens district health services through technical assistance including support to annual district health planning and data quality improvement; and provision of operational and flexible funds. SLiSL also helps to prepare the country better for emergencies such as disease outbreaks through support to communicable disease surveillance, emergency preparedness and response capacity building, multi-sectoral coordination and laboratory capacity strengthening.'
					}
				</p>
			</div>
		</div>
	</StaticContainer>
);

export default WhatWeDo;
