import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, FileUpload, Input, Button } from 'common/components';
import { addGalleryItem } from 'common/utils/firebase-tools.js';

const AddPhotoModal = ({ show, onClose, onComplete, length }) => {
	const [image, setImage] = useState({});
	const [description, setDescription] = useState('');
	const [loading, setLoading] = useState(false);

	const handleCloseModal = () => {
		setImage({});
		setDescription('');
		onClose();
	};

	const handleSubmitClick = async () => {
		setLoading(true);
		addGalleryItem({
			image,
			description,
			length,
		})
			.then(() => {
				onComplete(true);
				setLoading(false);
			})
			.catch(() => {
				onComplete(false);
				setLoading(false);
			});
	};

	return (
		<Modal
			show={show}
			title="Add a new photo"
			handleModalClose={handleCloseModal}
		>
			<FileUpload name={image?.name} onChooseFile={setImage} />
			<Input
				className="gallery-admin__modal__description"
				label="Description"
				value={description}
				onChange={setDescription}
				multiline
			/>
			<Button
				primary
				disabled={!image?.name || !description}
				onClick={handleSubmitClick}
				loading={loading}
			>
				{'Add photo'}
			</Button>
		</Modal>
	);
};

AddPhotoModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onComplete: PropTypes.func.isRequired,
	length: PropTypes.number.isRequired,
};

export default AddPhotoModal;
