import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const Truck = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={80}
		height={80}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 48 48"
	>
		<g data-name="Layer 2">
			<path d="M43.42 32H43v-3a5.002 5.002 0 00-5-5h-5v-2.99A3.013 3.013 0 0029.99 18H3.01A3.013 3.013 0 000 21.01V44h5a5.974 5.974 0 012.73-5.03A6 6 0 0117 44h12a6 6 0 0112 0h5.47A1.524 1.524 0 0048 42.47v-5.89A4.58 4.58 0 0043.42 32zM40 32h-7v-5h4.11A2.885 2.885 0 0140 29.89z"></path>
			<path d="M11 40a4 4 0 104 4 3.989 3.989 0 00-4-4zM35 40a4 4 0 104 4 3.99 3.99 0 00-4-4zM1.5 10h41.252l-4.35 4.35a1.552 1.552 0 102.195 2.195l6.948-6.947a1.552 1.552 0 000-2.195L40.597.455a1.552 1.552 0 00-2.195 2.195L42.752 7H1.5a1.5 1.5 0 000 3z"></path>
		</g>
	</svg>
);

export default Truck;