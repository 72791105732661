import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { firestore } from 'firebase/app';
import { Map, Heading } from 'common/components';
import { Location } from 'common/icons';
import RegionEditor from './RegionEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { v4 } from 'uuid';
import {
	selectEditAction,
	setSelectedIcon,
	addTempIcon,
	clearEditor,
} from 'store/actions/mapActions';
import './MapAdmin.scss';

const MapAdmin = () => {
	const dispatch = useDispatch();
	const {
		edit: { action, district, chiefdom, newLatLng, selectedIcon, tempIcon },
	} = useSelector((state) => state.map);

	useEffect(() => {
		dispatch(clearEditor());
	}, []);

	const handleGoBack = () => {
		dispatch(clearEditor());
	};

	const handleSubmit = (regionName) => {
		const chiefdomId = chiefdom?._id;
		const districtId = district._id;
		if (action === 'edit-region-name') {
			const region = chiefdom || district;
			firestore()
				.collection(chiefdom ? 'chiefdoms' : 'districts')
				.doc(chiefdom ? chiefdomId : districtId)
				.update({
					name: regionName,
					latLng: newLatLng || region.label_pos,
				});
		} else {
			let icons = chiefdom ? chiefdom.icons : district.icons;
			if (action === 'remove-icon') {
				const index = icons.findIndex((icon) => icon._id === selectedIcon._id);
				icons.splice(index, 1);
			} else if (action === 'edit-icon') {
				const index = icons.findIndex((icon) => icon._id === selectedIcon._id);
				icons[index] = selectedIcon;
			} else {
				icons = [
					...(chiefdom ? chiefdom.icons : district.icons),
					{ _id: v4(), ...tempIcon[0] },
				];
				dispatch(addTempIcon([]));
			}
			firestore()
				.collection(chiefdom ? 'chiefdoms' : 'districts')
				.doc(chiefdom ? chiefdomId : districtId)
				.update({ icons });
			dispatch(selectEditAction(null));
			dispatch(setSelectedIcon(null));
		}
	};

	return (
		<div className="map-admin">
			<Heading icon={<Location />}>MapAdmin</Heading>
			<div className="map-admin__editor">
				{!district && <span>{'Click a region to edit...'}</span>}
				{district && (
					<FontAwesomeIcon
						className="map-admin__editor__back-icon"
						icon={faUndo}
						onClick={handleGoBack}
					/>
				)}
				{district && (
					<RegionEditor region={chiefdom || district} onSubmit={handleSubmit} />
				)}
			</div>
			<Map isEditor />
		</div>
	);
};

export default MapAdmin;
