import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTint,
	faBaby,
	faHome,
	faFaucet,
	faImage,
	faEdit,
	faTrash,
	faShareAlt,
} from '@fortawesome/free-solid-svg-icons';
import './FaIcon.scss';

const FaIcon = ({ type, isSelectedIcon, number, size, color }) => {
	const getIconType = () => {
		let iconType;
		switch (type) {
			case 'ic1':
				iconType = faTint;
				break;
			case 'ic2':
				iconType = faFaucet;
				break;
			case 'ic7':
				iconType = faBaby;
				break;
			case 'ic11':
				iconType = faHome;
				break;
			case 'image':
				iconType = faImage;
				break;
			case 'edit':
				iconType = faEdit;
				break;
			case 'delete':
				iconType = faTrash;
				break;
			case 'share':
				iconType = faShareAlt;
				break;
			default:
				break;
		}

		return iconType;
	};

	const faClassnames = classnames(
		'fa',
		type === 'ic2' && 'fa__faucet',
		type === 'ic7' && 'fa__baby',
		type === 'ic11' && 'fa__home'
	);

	const textClassnames = classnames(
		'fa__number',
		isSelectedIcon && 'fa__number--selected'
	);

	const getIconColor = () => {
		if (isSelectedIcon) return '#ff0000';
		if (color) return color;
		return '#fff';
	};

	const getIconSize = () => {
		if (size) return size;
		const largerIcons = ['ic7', 'ic11'];
		if (largerIcons.includes(type)) return '3x';
		return '2x';
	};

	return (
		<div className={faClassnames}>
			<FontAwesomeIcon
				icon={getIconType()}
				size={getIconSize()}
				color={getIconColor()}
			/>
			{number && <span className={textClassnames}>{number}</span>}
		</div>
	);
};

FaIcon.propTypes = {
	number: PropTypes.number,
	isSelectedIcon: PropTypes.bool,
	type: PropTypes.string.isRequired,
	size: PropTypes.string,
	color: PropTypes.string,
};

FaIcon.defaultProps = {
	isSelectedIcon: false,
	number: null,
	size: '2x',
	color: '#fff',
};

export default FaIcon;
