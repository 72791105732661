import React from 'react';
import PropTypes from 'prop-types';
import { montroseBlue } from '../scss/variables/colors.scss';

const Network = ({ size }) => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={size}
		height={size}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 48 48.001"
	>
		<g data-name="Layer 2">
			<path d="M7.689 37.404l-7.11 7.19a2 2 0 102.843 2.813l7.11-7.19a2 2 0 10-2.843-2.813zM38.81 11.11a1.995 1.995 0 001.406-.577l7.19-7.11A2 2 0 0044.594.578l-7.19 7.11a2 2 0 001.406 3.422zM40.3 37.412a2 2 0 00-2.84 2.817l7.12 7.18a2 2 0 102.84-2.817zM7.772 10.54a2 2 0 002.816-2.84L3.408.58A2 2 0 10.592 3.42zM24 10c-7.411 0-13 5.16-13 12a2 2 0 004 0 7.449 7.449 0 014-6.744v30.745a2 2 0 004 0V31h2v15a2 2 0 004 0V15.256A7.449 7.449 0 0133 22a2 2 0 104 0c0-6.842-5.589-12-13-12z"></path>
			<circle cx="24" cy="4.001" r="4"></circle>
		</g>
	</svg>
);

Network.propTypes = {
	size: PropTypes.number,
};

Network.defaultProps = {
	size: 80,
};

export default Network;