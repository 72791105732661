import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'common/components';
import './Footer.scss';

const Footer = () => {
	const renderDivider = () => (
		<span className="footer__links__divider">{' | '}</span>
	);

	const onLinkClick = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};
	return (
		<div className="footer">
			<div className="footer__links">
				<Link to="/" onClick={onLinkClick}>
					{'Home'}
				</Link>
				{renderDivider()}
				<Link to="/about" onClick={onLinkClick}>
					{'About the programme'}
				</Link>
				{renderDivider()}
				<Link to="/what-we-do" onClick={onLinkClick}>
					{'What we do'}
				</Link>
				{renderDivider()}
				<Link to="/activities" onClick={onLinkClick}>
					{'Activity Map'}
				</Link>
				{renderDivider()}
				<Link to="/events" onClick={onLinkClick}>
					{'Events'}
				</Link>
				{renderDivider()}
				<Link to="/resources" onClick={onLinkClick}>
					{'Resources'}
				</Link>
				{renderDivider()}
				<Link to="/gallery" onClick={onLinkClick}>
					{'Gallery'}
				</Link>
				{renderDivider()}
				<Link to="/partners" onClick={onLinkClick}>
					{'Partners'}
				</Link>
			</div>
			<div className="footer__social-media">
				<Icon
					name="twitter"
					className="footer__social-media__icon"
					onClick={() => window.open('https://twitter.com/mohs_sl', '_blank')}
				/>
				<Icon
					name="facebook"
					className="footer__social-media__icon"
					onClick={() =>
						window.open('https://www.facebook.com/mohsict', '_blank')
					}
				/>
			</div>
		</div>
	);
};

export default Footer;
