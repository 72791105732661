import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import './EditButton.scss';

const EditButton = ({ id, docType }) => (
	<Link className="edit-button" to={`/admin/publish/${id}/${docType}`}>
		<Fab color="secondary" aria-label="edit" size="small">
			<EditIcon />
		</Fab>
	</Link>
);

EditButton.propTypes = {
	id: PropTypes.string.isRequired,
	docType: PropTypes.string.isRequired,
};

export default EditButton;
