import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

function Icon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={40}
			height={40}
			viewBox="0 0 256 256"
		>
			<path
				strokeMiterlimit="10"
				strokeWidth="0"
				fill={montroseBlue}
				d="M45 0C20.147 0 0 20.147 0 45s20.147 45 45 45 45-20.147 45-45S69.853 0 45 0zm17.251 46.633L37.789 60.756c-1.258.726-2.829-.181-2.829-1.633V30.877c0-1.452 1.572-2.36 2.829-1.634L62.25 43.366c1.258.726 1.258 2.541.001 3.267z"
				transform="matrix(2.81 0 0 2.81 1.407 1.407)"
			></path>
		</svg>
	);
}

export default Icon;
