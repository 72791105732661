import React from 'react';
import PropTypes from 'prop-types';
import { DragAndDrop } from 'common/components';
import { DOCTYPES } from 'common/utils/docType-dictionary';
import './Upload.scss';

const Upload = ({
	docType,
	thumbnail,
	file,
	onChooseThumbnail,
	onChooseFile,
}) => (
	<div className="publish-upload">
		<DragAndDrop
			onUpload={onChooseThumbnail}
			formats={['image/png', 'image/jpeg', 'image/jpg']}
			file={thumbnail}
			title={'Thumbnail'}
		/>
		{docType === DOCTYPES.DOCUMENT && (
			<DragAndDrop
				onUpload={onChooseFile}
				formats={['application/pdf']}
				file={file}
				title={'File'}
			/>
		)}
	</div>
);

Upload.propTypes = {
	docType: PropTypes.string,
	thumbnail: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
	file: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
	onChooseThumbnail: PropTypes.func.isRequired,
	onChooseFile: PropTypes.func.isRequired,
};

Upload.defaultProps = {
	docType: '',
	thumbnail: null,
	file: null,
};

export default Upload;
