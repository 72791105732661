import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './Shape.scss';

const Shape = ({ type, isSelectedIcon, number }) => {
	
	const getShape = () => {
		let shape;
		switch(type) {
		case 'ic3':
			shape = 'square';
			break;
		case 'ic4':
			shape = 'triangle';
			break;
		case 'ic5':
			shape = 'circle';
			break;
		case 'ic6':
			shape = 'diamond';
			break;
		case 'ic9':
			shape = 'star';
			break;
		case 'ic10':
			shape = 'octagon';
			break;
		default:
			shape = type;
		}
		return shape;
	};
	
	const iconClassnames = classnames(
		getShape(), 
		isSelectedIcon && `${getShape()}__selected`
	);

	if (getShape() === 'octagon') 
		return (
			<div className={iconClassnames}>
				<div>
					<span>
						{number}
					</span>
				</div>
			</div>
		);
	
	return (
		<div className={iconClassnames}>
			<span>
				{number}
			</span>
		</div>
	);};

Shape.propTypes = {
	isSelectedIcon: PropTypes.bool,
	number: PropTypes.number,
	type: PropTypes.string.isRequired
};

Shape.defaultProps = {
	isSelectedIcon: false,
	number: null
};

export default Shape;
