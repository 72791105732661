import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const Megaphone = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={30}
		height={30}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 47.966 33"
	>
		<g data-name="Layer 2">
			<path d="M31.927.239l-17.38 9.248a1.127 1.127 0 00-.58 1v12.027a1.127 1.127 0 00.58 1l17.38 9.247a2.108 2.108 0 003.04-1.999V2.238a2.108 2.108 0 00-3.04-2zM10.966 10H3A3 3 0 000 13v7a3 3 0 003 3h.966v6.5a2.5 2.5 0 005 0V23h2a1 1 0 001-1V11a1 1 0 00-1-1zM46.466 15h-7a1.5 1.5 0 000 3h7a1.5 1.5 0 000-3zM40.154 10.799l6.062-3.5a1.5 1.5 0 10-1.5-2.598l-6.062 3.5a1.5 1.5 0 101.5 2.598zM46.216 25.701l-6.062-3.5a1.5 1.5 0 10-1.5 2.598l6.062 3.5a1.5 1.5 0 101.5-2.598z"></path>
		</g>
	</svg>
);

export default Megaphone;