import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const Hospital = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={80}
		height={80}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 32 48"
	>
		<g data-name="Layer 2">
			<path d="M30 6H2a2.006 2.006 0 00-2 2v22h32V8a2.006 2.006 0 00-2-2zm-8 15h-3v3a3 3 0 01-6 0v-3h-3a3 3 0 010-6h3v-3a3 3 0 016 0v3h3a3 3 0 010 6zM6 4h20a2 2 0 000-4H6a2 2 0 000 4zM0 48h12V38a2.006 2.006 0 012-2h4a2.006 2.006 0 012 2v10h12V32H0z"></path>
		</g>
	</svg>
);

export default Hospital;