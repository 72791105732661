import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiCall, uploadEventFiles } from 'common/utils/firebase-tools.js';
import { dateFromResponse } from 'common/utils/format-date';
import moment from 'moment-timezone';

const formatEventsForCalendar = (dates) =>
	dates.map((event) => ({
		...event,
		start: dateFromResponse(event.start),
		end: dateFromResponse(event.end),
	}));

export const fetchEvents = createAsyncThunk(
	'events/fetchEvents',
	async (month, { rejectWithValue }) => {
		try {
			const response = await apiCall('fetchEvents', month);
			return formatEventsForCalendar(response.data);
		} catch (err) {
			return rejectWithValue(err.message);
		}
	}
);

export const modifyEvent = createAsyncThunk(
	'events/modifyEvent',
	async ({ data, method, monthInView }, { rejectWithValue }) => {
		try {
			const documents = await uploadEventFiles(data.documents, data.eventId);
			const response = await apiCall(`${method}Event`, {
				...data,
				documents,
				monthInView,
			});
			return formatEventsForCalendar(response.data);
		} catch (err) {
			return rejectWithValue(err.message);
		}
	}
);

export const deleteEvent = createAsyncThunk(
	'events/deleteEvent',
	async ({ eventId, monthInView }) => {
		try {
			const [month, year] = monthInView.split('-');
			const time = moment({ day: 1, month, year }).unix();
			const response = await apiCall('deleteEvent', {
				eventId,
				datesInView: {
					start: time,
				},
			});
			return formatEventsForCalendar(response.data);
		} catch (err) {
			console.log(err);
		}
	}
);
