import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const Pharmacy = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={80}
		height={80}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 34 48"
	>
		<g data-name="Layer 2">
			<path d="M21 3a3 3 0 00-6 0v3h6zM15 26h6v-2h5a8 8 0 000-16H11.64A4 4 0 009 7H4a4 4 0 000 8h5a4 4 0 002.64-1H26a2 2 0 010 4h-5v-2h-6v2h-3a8 8 0 000 16h10a2 2 0 010 4h-1v-2h-6v2h-2a3 3 0 000 6h2v1a2.772 2.772 0 00.18 1 2.654 2.654 0 00.18.42.818.818 0 00.12.2 1.303 1.303 0 00.1.15 2.9 2.9 0 001.36 1.03.493.493 0 00.17.06.83.83 0 00.21.06.857.857 0 00.17.03 2.627 2.627 0 001.02 0 .857.857 0 00.17-.03 2.952 2.952 0 001.74-1.15 1.303 1.303 0 00.1-.15.818.818 0 00.12-.2 2.654 2.654 0 00.18-.42 2.772 2.772 0 00.18-1v-1h1a8 8 0 000-16H12a2 2 0 010-4h3zM4 11a1 1 0 111-1 1.003 1.003 0 01-1 1z"></path>
		</g>
	</svg>
);

export default Pharmacy;