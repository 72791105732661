import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import './TimePicker.scss';

const TimePicker = ({ className, width, ...props }) => {
	return (
		<div className={className} style={{ width }}>
			<TextField type="time" {...props} />
		</div>
	);
};

TimePicker.propTypes = {
	onChange: PropTypes.func.isRequired,
	width: PropTypes.number,
	label: PropTypes.string.isRequired,
	className: PropTypes.string,
};

TimePicker.defaultProps = {
	width: null,
	className: '',
};

export default TimePicker;
