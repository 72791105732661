import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'common/components';
import EventAddEdit from './EventAddEdit';
import './EventEditor.scss';

const EventEditor = ({ monthInView }) => {
	const [showAddModal, setShowAddModal] = useState(false);

	return (
		<>
			{showAddModal && (
				<EventAddEdit
					show={showAddModal}
					onClose={setShowAddModal}
					monthInView={monthInView}
				/>
			)}
			<div className="event-editor">
				<Button primary onClick={() => setShowAddModal(true)}>
					{'Add Event'}
				</Button>
			</div>
		</>
	);
};

EventEditor.propTypes = {
	monthInView: PropTypes.shape({}).isRequired,
};

export default EventEditor;
