import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const Team = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={40}
		height={40}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 47.98 32"
	>
		<g data-name="Layer 2">
			<path d="M12.18 17.84a11.634 11.634 0 011.41-3.85 11.393 11.393 0 011.28-1.81 7.28 7.28 0 01.53-.58A9.848 9.848 0 009.99 10a9.99 9.99 0 00-5 18.64V30a2.006 2.006 0 002 2h6a2 2 0 002-2v-2.08A11.152 11.152 0 0113.6 26a11.99 11.99 0 01-1.42-8.16zM2.98 20a7.015 7.015 0 012.01-4.92v9.84A7.015 7.015 0 012.98 20z"></path>
			<circle cx="9.995" cy="4" r="4"></circle>
			<circle cx="37.99" cy="4" r="4"></circle>
			<path d="M37.99 10a9.906 9.906 0 00-5.44 1.61 7.996 7.996 0 01.55.61A11.736 11.736 0 0134.37 14a11.967 11.967 0 010 11.99 12.368 12.368 0 01-1.38 1.91V30a2.006 2.006 0 002 2h6a2.006 2.006 0 002-2v-1.36a9.99 9.99 0 00-5-18.64zm5 14.92v-9.84a7.026 7.026 0 010 9.84z"></path>
			<circle cx="23.99" cy="4" r="4"></circle>
			<path d="M33.98 20a9.833 9.833 0 00-.99-4.33c-.06-.13-.12-.25-.19-.38a10.203 10.203 0 00-1.81-2.42 9.955 9.955 0 00-7-2.87 11.16 11.16 0 00-1.89.17 9.798 9.798 0 00-5.12 2.69 9.575 9.575 0 00-1.81 2.42 3.016 3.016 0 00-.18.36 9.43 9.43 0 00-.84 2.55 10.014 10.014 0 00.84 6.15c.06.12.12.25.19.37a10.016 10.016 0 003.81 3.93v.46c0 .03.01.06.01.09s-.01.05-.01.08V30a2.006 2.006 0 002 2h6a2.006 2.006 0 002-2v-.73c0-.03-.01-.05-.01-.08s.01-.06.01-.09v-.46a9.872 9.872 0 002-1.52 10.008 10.008 0 001.81-2.42c.07-.12.13-.25.19-.38a9.822 9.822 0 00.99-4.32zm-14.99 4.91a1.698 1.698 0 01-.18-.2 6.83 6.83 0 01-1.82-4.35 3.247 3.247 0 000-.72 6.857 6.857 0 011.82-4.36c.05-.07.12-.13.18-.2v9.83zm10.18-.21a1.67 1.67 0 01-.18.21v-9.82a1.697 1.697 0 01.18.2 6.856 6.856 0 011.81 4.44 3.646 3.646 0 000 .54 6.852 6.852 0 01-1.81 4.43z"></path>
		</g>
	</svg>
);

export default Team;