import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Input, Button, Toast, Redirect } from 'common/components';
import { signIn } from 'common/utils/firebase-tools.js';
import './Login.scss';

const Login = () => {
	const { id } = useSelector((state) => state.user);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	let location = useLocation();
	let { from } = location.state || { from: { pathname: '/' } };

	const handleOnSubmit = async () => {
		setLoading(true);
		try {
			await signIn(email, password);
			setLoading(false);
		} catch (err) {
			setLoading(false);
			setError(true);
		}
	};
	if (id) return <Redirect shouldRedirect to={from} />;
	return (
		<div>
			<Toast
				type="error"
				show={error}
				duration={3000}
				onClose={() => setError(false)}
			>
				{'Invalid username or password'}
			</Toast>
			<div className="login">
				<span className="login__title">{'Login'}</span>
				<Input
					className="login__email"
					label="Email"
					value={email}
					onChange={setEmail}
				/>
				<Input
					label="Password"
					value={password}
					onChange={setPassword}
					type="password"
				/>
				<Button
					className="login__button"
					loading={loading}
					onClick={handleOnSubmit}
					primary
				>
					Login
				</Button>
			</div>
		</div>
	);
};

export default Login;
