import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { capitalizeFirstLetters } from '../utils';

const DropDown = ({
	placeholder,
	options,
	onChange,
	value,
	error,
	errorMessage,
	subGroup,
	className,
	...props
}) => {
	const isOptionsArray = Array.isArray(options);

	const getKeyByValue = (option, group) => {
		if (Array.isArray(group)) return option;
		return Object.keys(group.list).find((key) => group.list[key] === option);
	};

	const renderOptions = () => {
		let list = [];
		if (isOptionsArray) list = options;
		else if (options?.list) list = Object.values(options.list);
		return (
			list.length &&
			list.map((opt) => (
				<MenuItem key={opt} value={getKeyByValue(opt, options)}>
					{typeof opt === 'string' ? capitalizeFirstLetters(opt) : opt}
				</MenuItem>
			))
		);
	};

	const renderSublist = () => {
		let subList = [];
		if (subGroup) subList = Object.values(subGroup.list);
		return (
			subList.length &&
			subList.map((opt) => (
				<MenuItem
					key={opt}
					value={getKeyByValue(opt, subGroup)}
					style={{
						whiteSpace: 'normal',
					}}
				>
					{capitalizeFirstLetters(opt)}
				</MenuItem>
			))
		);
	};

	return (
		<div className={className} style={{ minWidth: 200 }}>
			<FormControl
				variant="outlined"
				margin="normal"
				style={{ width: '100%' }}
				{...props}
			>
				<InputLabel>{placeholder}</InputLabel>
				<Select
					value={value || ''}
					onChange={(e) => onChange(e.target.value)}
					label={placeholder}
				>
					{renderOptions()}
					{subGroup && <ListSubheader>{subGroup.label}</ListSubheader>}
					{subGroup && renderSublist()}
				</Select>
			</FormControl>
			{error && <span className="dropdown__error-message">{errorMessage}</span>}
		</div>
	);
};

DropDown.propTypes = {
	options: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})])
		.isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	error: PropTypes.bool,
	errorMessage: PropTypes.string,
	subGroup: PropTypes.shape({
		label: PropTypes.string,
		list: PropTypes.shape({}),
	}),
	className: PropTypes.string,
	width: PropTypes.number,
};

DropDown.defaultProps = {
	value: null,
	error: false,
	errorMessage: '',
	subGroup: null,
	className: '',
	width: 200,
	placeholder: '',
};

export default DropDown;
