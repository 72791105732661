import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Publish } from '../../pages';
import { Redirect } from 'common/components';
import { Route } from 'react-router-dom';

const PublishRoute = ({ path, ...rest }) => {
	const { role, id } = useSelector((state) => state.user);
	const isEditor = role === 'EDITOR' || role === 'ADMIN';
	const isLogged = id;

	const handleRedirect = (path, location) => (
		<Redirect
			shouldRedirect
			to={{
				pathname: path,
				state: { from: location },
			}}
		/>
	);

	if (isEditor) return <Route {...rest} path={path} component={Publish} />;

	return (
		<Route
			{...rest}
			render={({ location }) =>
				isLogged
					? handleRedirect('/', location)
					: handleRedirect('/login', location)
			}
		/>
	);
};

PublishRoute.propTypes = {
	path: PropTypes.string.isRequired,
};

export default PublishRoute;
