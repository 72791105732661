import React, { useState, useEffect } from 'react';
import { Heading } from 'common/components';
import { Camera } from 'common/icons';
import Photos from './Photos';
import Video from './Video';
import './Gallery.scss';
import { useParams } from 'react-router-dom';

const Gallery = () => {
	const { resourceType } = useParams();
	const isValidMediaType =
		resourceType === 'images' || resourceType === 'videos';
	const [selectedTab, setSelectedTab] = useState(
		isValidMediaType ? resourceType : 'images'
	);

	const handleTabClick = (id) => {
		setSelectedTab(id);
	};

	useEffect(() => {
		if (isValidMediaType && resourceType !== selectedTab) {
			setSelectedTab(resourceType);
		}
	}, [resourceType]);

	return (
		<div className="gallery">
			<Heading
				icon={<Camera />}
				tabs={[
					{
						id: 'images',
						label: 'Images',
						onClick: handleTabClick,
					},
					{
						id: 'videos',
						label: 'Videos',
						onClick: handleTabClick,
					},
				]}
				selectedTab={selectedTab}
			>
				{'Gallery'}
			</Heading>
			{selectedTab === 'images' && <Photos />}
			{selectedTab === 'videos' && <Video />}
		</div>
	);
};

export default Gallery;
