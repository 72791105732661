import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'common/components';
import {
	ConcernWorldwide,
	CrownAgents,
	CUAMM,
	GOAL,
	IRC,
	MarieStopes,
	MontroseTagline,
	RCPCH,
	Restless,
	UNFPA,
	UNICEF,
	WHO,
} from 'common/images/logos';
import {
	Drugs,
	Chat,
	Family,
	FirstAid,
	Team,
	Hospital,
	UnMagnifyingGlass,
	Network,
	Nurse,
	Pharmacy,
	MotherBaby,
	TechSupport,
	Training,
	Truck,
} from 'common/icons';
import Evidence from 'common/images/Evidence.png';
import Learning from 'common/images/Learning.png';
import Monitoring from 'common/images/Monitoring.png';
import Review from 'common/images/Review.png';
import './Partners.scss';

const InfoBox = ({ icon, description }) => (
	<div className="infobox">
		{icon}
		{description}
	</div>
);

const Partners = () => (
	<div className="partners">
		<Heading icon={<Team />}>Partners</Heading>
		<div className="partners__intro">
			<p>
				<strong>
					{
						'Saving lives in Sierra Leone (SLiSL) programme is implemented through a range of partners: a '
					}
					{/* <strong> */}
					{'United Nations Consortium, NGOs Consortium '}
					{/* </strong> */}
					{'known as '}
					{/* <strong> */}
					{'‘Unite fɔ Sev Layf na Salone’ (UNITE) '}
					{/* </strong> */}
					{'and '}
					{/* <strong> */}
					{'Montrose.'}
					{/* </strong> */}
				</strong>
			</p>
		</div>
		<div className="partners__section-intro">
			<p>
				{
					'Funded by the Foreign Commonwealth and Development Office (FCDO), the programme is implemented by a range of partners: a United Nations Consortium, NGO Consortium known as ‘Unite fɔ Sev Layf na Salone’ (UNITE) and Montrose.'
				}
			</p>
			<p>
				{
					'The programme is aligned to the Government of Sierra Leone’s (GoSL) health strategy and seeks to support priority, cost-effective, interventions to improve health service delivery and save lives of babies, children under five and mothers.'
				}
			</p>
			<p>
				{'The United Nations Consortium consists of the '}
				<strong>{'United Nations Population Fund (UNFPA)'}</strong>
				{', '}
				<strong>{'United Nations Childrens’ Fund (UNICEF)'}</strong>
				{' and the '}
				<strong>{'World Health Organisation (WHO)'}</strong>
				{'.'}
			</p>
		</div>
		<p>
			{
				'Working at National level the UN consortium provides the following services:'
			}
		</p>
		<div className="partners__activities">
			<InfoBox
				icon={<Drugs />}
				description={
					'Improving the efficiency of procurement and supply of FHC drugs and FP commodities and support for nutrition commodities.'
				}
			/>
			<InfoBox
				icon={<Family />}
				description={
					'Increasing demand for and availability of family planning services for adolescents and young people.'
				}
			/>
			<InfoBox
				icon={<Hospital />}
				description={
					'Improving the availability of functional hospitals to receive RMNCAH referrals according to standards.'
				}
			/>
			<InfoBox
				icon={<Training />}
				description={
					'Providing Human Resource for health training and capacity building.'
				}
			/>
			<InfoBox
				icon={<MotherBaby />}
				description={
					'Supporting Reproductive Maternal Newborn Child and Adolescent Health (RMNCAH) and Quality of Care.'
				}
			/>
			<InfoBox
				icon={<Nurse />}
				description={'Improving HRH capacity to conduct RMNCAH services.'}
			/>
			<InfoBox
				icon={<UnMagnifyingGlass />}
				description={
					'Strengthening and building capacity on functional emergencies/disease surveillance, preparedness and response.'
				}
			/>
		</div>
		<div className="partners__logos">
			<img src={UNFPA} alt="United Nations Population Fund (UNFPA) logo" />
			<img src={UNICEF} alt="United Nations Children’s Fund (UNICEF) logo" />
			<img src={WHO} alt="World Health Organisation (WHO) logo" />
		</div>
		<hr className="line-break" />
		<div className="partners__section-intro">
			<p>
				{'The NGO consortium is led by the '}
				<strong>{'International Rescue Committee (IRC). '}</strong>
				{'Other members of the consortium include '}
				<strong>
					{
						'Crown Agents, CUAMM, Goal, Restless Development, Concern Worldwide, Marie Stopes Sierra Leone '
					}
				</strong>
				{' and the '}
				<strong>{'Royal College of Paediatrics and Child Health'}</strong>
				{'.'}
			</p>
		</div>
		<p>
			{
				'Working at District level the NGO consortium provides the following services:'
			}
		</p>
		<div className="partners__activities">
			<InfoBox
				icon={<TechSupport />}
				description={
					'Providing technical, operational and management support to DHMTs to effectively and efficiently plan, manage and deliver quality RMNCAH services through district hospitals, PHUs and community network.'
				}
			/>
			<InfoBox
				icon={<Chat />}
				description={
					'Providing long and short term flexible and tailored technical assistance to DHMTs and district hospitals.'
				}
			/>
			<InfoBox
				icon={<Network />}
				description={
					'Helping to coordinate RMNCAH partners at district and centre (primarily the programme governance committees).'
				}
			/>
			<InfoBox
				icon={<FirstAid />}
				description={
					'Providing assistance to establish National Medical Supply Agency (NMSA).'
				}
			/>
			<InfoBox
				icon={<Pharmacy />}
				description={
					'Providing technical, operational and management support to the Directorate of Drugs and Medical.'
				}
			/>
			<InfoBox
				icon={<Truck />}
				description={'Supply to manage storage and distribution.'}
			/>
			<InfoBox
				icon={<UnMagnifyingGlass />}
				description={
					'Strengthening and building capacity on functional emergencies/disease surveillance, preparedness and response.'
				}
			/>
		</div>
		<div className="partners__logos">
			<img src={IRC} alt="International Rescue Committee (IRC) logo" />
			<img src={CrownAgents} alt="Crown Agents logo" />
			<img src={CUAMM} alt="CUAMM logo" />
			<img src={GOAL} alt="Goal logo" />
			<img src={Restless} alt="Restless Development logo" />
			<img src={ConcernWorldwide} alt="Concern Worldwide logo" />
			<img src={MarieStopes} alt="Marie Stopes Sierra Leone logo" />
			<img
				src={RCPCH}
				alt="Royal College of Paediatrics and Child Health logo"
			/>
		</div>
		<hr className="line-break" />
		<div className="partners__montrose">
			<span className="partners__montrose__title">
				{
					'Montrose delivers the Monitoring, Evidence, Learning and Review (MELR) of the programme.'
				}
			</span>
			<div className="partners__montrose__icons">
				<InfoBox
					icon={<img src={Monitoring} alt="Montrose monitoring icon" />}
					description={
						'Conducts research studies on cross-cutting issues such as disability, gender and quality of care.'
					}
				/>
				<InfoBox
					icon={<img src={Evidence} alt="Montrose evidence icon" />}
					description={
						'Supports design of the overall programme monitoring framework.'
					}
				/>
				<InfoBox
					icon={<img src={Learning} alt="Montrose learning icon" />}
					description={
						'Conducts qualitative fieldwork to enhance understanding of stakeholder perspectives and programme activities.'
					}
				/>
				<InfoBox
					icon={<img src={Review} alt="Montrose review icon" />}
					description={
						'Supports design of the overall programme monitoring framework.'
					}
				/>
			</div>
			<img
				className="partners__montrose__logo"
				src={MontroseTagline}
				alt={'Montrose logo on blue background'}
			/>
		</div>
	</div>
);

InfoBox.propTypes = {
	icon: PropTypes.node.isRequired,
	description: PropTypes.string.isRequired,
};

export default Partners;
