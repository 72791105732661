import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'common/components';
import './StaticContainer.scss';

const StaticContainer = ({ title, icon, children }) => (
	<div className="static-page">
		<Heading icon={icon}>{title}</Heading>
		{children}
	</div>
);

StaticContainer.propTypes = {
	title: PropTypes.string.isRequired,
	icon: PropTypes.node.isRequired,
	children: PropTypes.node.isRequired,
};

export default StaticContainer;
