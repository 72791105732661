import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Loader, Button, Tag } from 'common/components';
import { setPublications } from 'store/actions/publicationActions';
import { getOrganisations } from '../../../store/reducers/publicationReducer';
import { fetchOrganisations } from 'common/utils/firebase-tools';
import './Options.scss';

const Options = ({
	selectedOrganisation,
	onSelectOrganisation,
	tags,
	onAddTags,
}) => {
	const dispatch = useDispatch();
	const [newOrg, setNewOrg] = useState('');
	const [newTag, setNewTag] = useState('');
	const organisations = useSelector(getOrganisations);

	useEffect(() => {
		if (!organisations.length) {
			handleFetchOrganisations();
		}
	}, [organisations]);

	const handleFetchOrganisations = async () => {
		const publications = await fetchOrganisations();
		dispatch(setPublications(publications));
	};

	const handleAddNewOrg = () => {
		onSelectOrganisation(newOrg);
		setNewOrg('');
	};

	const handleAddTag = () => {
		onAddTags([...tags, newTag.toLowerCase()]);
		setNewTag('');
	};

	const handleTagInput = (e) => {
		if (e.target.value.slice(-1) === ' ' || e.target.value.slice(-1) === ',') {
			handleAddTag();
			return;
		}
		setNewTag(e.target.value);
	};

	const renderOrganisationItem = (org) => {
		const itemClassnames = classnames(
			'publish-options__organisations__list__item',
			selectedOrganisation === org &&
				'publish-options__organisations__list__item--selected'
		);
		return (
			<li
				key={org}
				className={itemClassnames}
				onClick={() => onSelectOrganisation(org)}
			>
				{org}
			</li>
		);
	};

	const renderNewOrg = () => {
		if (!selectedOrganisation || organisations.includes(selectedOrganisation))
			return null;
		return renderOrganisationItem(selectedOrganisation);
	};

	const handleChipClick = (index) => {
		let tagsCopy = tags;
		tagsCopy.splice(index, 1);
		onAddTags(tagsCopy);
	};

	return (
		<div className="publish-options">
			<div className="publish-options__section">
				<span className="publish-options__section__title">ORGANISATION</span>
				<div className="publish-options__section__new">
					<Button
						className="publish-options__section__new__submit"
						disabled={!newOrg}
						onClick={handleAddNewOrg}
					>
						{'Add'}
					</Button>
					<input
						value={newOrg}
						onChange={(e) => setNewOrg(e.target.value)}
						placeholder="Add a new organisation to the list..."
					/>
				</div>
				<ul className="publish-options__organisations__list">
					{renderNewOrg()}
					{organisations.length ? (
						organisations.map((org) => renderOrganisationItem(org))
					) : (
						<Loader className="publish-options__organisations__list--loading" />
					)}
				</ul>
			</div>
			<div className="publish-options__section">
				<span className="publish-options__section__title">TAGS</span>
				<div className="publish-options__section__new">
					<Button
						className="publish-options__section__new__submit"
						disabled={!newTag}
						onClick={handleAddTag}
					>
						{'Add'}
					</Button>
					<input
						value={newTag}
						onChange={handleTagInput}
						placeholder="Add some tags..."
					/>
				</div>
				<div className="publish-options__tags__list">
					{!!tags.length &&
						tags.map((tag, index) => (
							<Tag key={tag} tag={tag} onClick={() => handleChipClick(index)} />
						))}
				</div>
			</div>
		</div>
	);
};

Options.propTypes = {
	selectedOrganisation: PropTypes.string,
	onSelectOrganisation: PropTypes.func.isRequired,
	tags: PropTypes.arrayOf(PropTypes.string),
	onAddTags: PropTypes.func.isRequired,
};

Options.defaultProps = {
	selectedOrganisation: '',
	tags: [],
};

export default Options;
