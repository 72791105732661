import React from 'react';

const Close = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={15}
		viewBox="0 0 512.001 512.001"
		fill="#3c3c3c"
	>
		<path d="M512.001 84.853L427.148 0 256.001 171.147 84.853 0 0 84.853 171.148 256 0 427.148l84.853 84.853 171.148-171.147 171.147 171.147 84.853-84.853L340.853 256z"></path>
	</svg>
);

export default Close;