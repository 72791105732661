import { combineReducers } from 'redux';
import user from './userReducer';
import publications from './publicationReducer';
import map from './mapReducer';
import gdpr from './gdprReducer';
import calendar from './events';

export default combineReducers({
	user,
	publications,
	map,
	gdpr,
	calendar,
});
