import React from 'react';
import PropTypes from 'prop-types';
import { SubHeading, Input } from 'common/components';

export const StepOne = ({
	title,
	description,
	setTitle,
	setDescription,
	validationError,
	setValidationError,
}) => {
	const clearError = () => {
		if (validationError) {
			setValidationError('');
		}
	};

	const handleTitleChange = (e) => {
		clearError();
		setTitle(e);
	};

	const handleDescriptionChange = (e) => {
		clearError();
		setDescription(e);
	};
	return (
		<div className="add-event__step-one">
			<SubHeading>Details</SubHeading>
			<Input
				label="Title"
				value={title}
				onChange={handleTitleChange}
				required
			/>
			<Input
				multiline
				rows={3}
				label="Description"
				value={description}
				onChange={handleDescriptionChange}
				required
			/>
		</div>
	);
};

StepOne.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	setTitle: PropTypes.func.isRequired,
	setDescription: PropTypes.func.isRequired,
	validationError: PropTypes.string.isRequired,
	setValidationError: PropTypes.func.isRequired,
};
