import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Loupe } from 'common/icons';
import './Unavailable.scss';

const Unavailable = ({ className }) => {
	const classNames = classnames('unavailable', className);
	return (
		<div className={classNames}>
			<Loupe size={75} />
			<div className="unavailable__text">
				<span className="unavailable__text__primary">{'Oops!'}</span>
				<span className="unavailable__text__sub">
					{"We can't seem to find any of these."}
				</span>
			</div>
		</div>
	);
};

Unavailable.propTypes = {
	className: PropTypes.string,
};

Unavailable.defaultProps = {
	className: '',
};

export default Unavailable;
