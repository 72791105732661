import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const UnMagnifyingGlass = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={80}
		height={80}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 48 48"
	>
		<g data-name="Layer 2">
			<g data-name="Expanded Icons">
				<path d="M8.38 33.8L1.2 40.98a4.115 4.115 0 005.82 5.82l7.18-7.18a24.01 24.01 0 01-5.82-5.82zM22 20h-4a1.003 1.003 0 00-1 1v4.94a12.574 12.574 0 006 5.52V21a1.003 1.003 0 00-1-1zM38 14h-4a1.003 1.003 0 00-1 1v16.46a12.574 12.574 0 006-5.52V15a1.003 1.003 0 00-1-1zM30 17h-4a1.003 1.003 0 00-1 1v14.14a12.68 12.68 0 006 0V18a1.003 1.003 0 00-1-1z"></path>
				<path d="M28 0a20 20 0 1020 20A19.994 19.994 0 0028 0zm11 30.18A15 15 0 0113 20a15 15 0 1126 10.18z"></path>
			</g>
		</g>
	</svg>
);

export default UnMagnifyingGlass;