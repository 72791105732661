import { createSelector } from 'reselect';
import {
	HANDLE_PUBLICATION_REQUEST,
	SET_FILTERS,
	CLEAR_FILTERS,
	SET_ORGANISAIONS,
	SET_PUBLICATIONS,
} from '../types/publicationTypes';

const initialState = {
	documents: [],
	successStories: [],
	activities: {
		data: [],
	},
	events: {
		data: [],
	},
	filters: {
		keywords: [],
		from: null,
		to: null,
	},
	selectedPublication: null,
	organisations: [],
	tags: [],
	loading: false,
	error: false,
};

const publicationReducer = (state = initialState, action) => {
	switch (action.type) {
		case HANDLE_PUBLICATION_REQUEST:
			return {
				...state,
				loading: true,
				error: false,
			};
		case SET_PUBLICATIONS:
			return {
				...state,
				successStories: action.payload.stories || state.successStories,
				documents: action.payload.docs || state.documents,
			};
		case SET_FILTERS:
			return {
				...state,
				filters: action.payload,
			};
		case SET_ORGANISAIONS:
			return {
				...state,
				organisations: action.payload,
			};
		case CLEAR_FILTERS:
			return {
				...state,
				filters: initialState.filters,
			};
		default:
			return state;
	}
};

const storiesOrganisationsSelector = (state) => {
	const stories = state.publications.successStories;
	const organisations = [];
	if (stories.length) {
		stories.forEach((story) => {
			if (!organisations.includes(story.organisation)) {
				organisations.push(story.organisation);
			}
		});
	}
	return organisations;
};

const documentsOrganisationsSelector = (state) => {
	const docs = state.publications.documents;
	const organisations = [];
	if (docs.length) {
		docs.forEach((doc) => {
			if (!organisations.includes(doc.organisation)) {
				organisations.push(doc.organisation);
			}
		});
	}
	return organisations;
};
export const getOrganisations = createSelector(
	storiesOrganisationsSelector,
	documentsOrganisationsSelector,
	(storyOrgs, docOrgs) => [...new Set(storyOrgs.concat(docOrgs))].sort()
);

export default publicationReducer;
