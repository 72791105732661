import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { divIcon } from 'leaflet';
import { Icon } from 'common/components';
import { Marker } from 'react-leaflet';
import { setSelectedIcon } from 'store/actions/mapActions';
import './MapIcon.scss';

const MapIcon = ({ icon, isEditor }) => {
	const dispatch = useDispatch();
	const {
		edit: { selectedIcon, newLatLng, action, chiefdom, district },
	} = useSelector((state) => state.map);
	const isDistrictIcon = district?.icons.find((e) => e?._id === icon._id);
	const isSelectedIcon = selectedIcon && selectedIcon._id === icon._id;
	const marker = divIcon({
		html: ReactDOMServer.renderToString(
			<Icon
				isSelectedIcon={isSelectedIcon}
				name={icon.type}
				number={icon.quantity}
			/>
		),
		className: 'marker__icon',
	});

	const handleMarkerClick = () => {
		if (!chiefdom && !isDistrictIcon) return;
		if (action === 'remove-icon' || action === 'edit-icon') {
			dispatch(setSelectedIcon(icon));
		}
	};

	const isDraggable = () => {
		if (
			!isEditor ||
			action === 'edit-region-name' ||
			action === 'remove-icon' ||
			!isSelectedIcon
		)
			return false;
		return true;
	};

	const onDragEnd = (event) => {
		if (isSelectedIcon)
			dispatch(
				setSelectedIcon({
					...selectedIcon,
					lat: event.target._latlng.lat,
					lng: event.target._latlng.lng,
				})
			);
	};

	const getIconPosition = () => {
		if (isSelectedIcon)
			return newLatLng || { lat: selectedIcon?.lat, lng: selectedIcon?.lng };

		return {
			lat: icon.lat,
			lng: icon.lng,
		};
	};

	return (
		<Marker
			position={getIconPosition()}
			icon={marker}
			draggable={isDraggable()}
			ondragend={onDragEnd}
			onclick={handleMarkerClick}
		/>
	);
};

MapIcon.propTypes = {
	icon: PropTypes.shape({
		_id: PropTypes.string,
		lat: PropTypes.number,
		lng: PropTypes.number,
		type: PropTypes.string,
		quantity: PropTypes.number,
	}).isRequired,
	isEditor: PropTypes.bool,
};

MapIcon.defaultProps = {
	isEditor: false,
};

export default MapIcon;
