import React from 'react';
import PropTypes from 'prop-types';
import { Chevron, Close, Facebook, FaIcon, Shape, Twitter } from 'common/icons';

const Icon = ({
	name,
	className,
	onClick,
	isSelectedIcon,
	number,
	size,
	color,
	...rest
}) => {
	const renderIcon = () => {
		const faIcons = [
			'ic1',
			'ic2',
			'ic7',
			'ic11',
			'image',
			'edit',
			'delete',
			'share',
		];
		const shapeIcons = ['ic3', 'ic4', 'ic5', 'ic6', 'ic9', 'ic10'];

		if (faIcons.includes(name))
			return (
				<FaIcon
					type={name}
					isSelectedIcon={isSelectedIcon}
					number={number}
					size={size}
					color={color}
				/>
			);
		else if (shapeIcons.includes(name))
			return (
				<Shape type={name} isSelectedIcon={isSelectedIcon} number={number} />
			);
		else if (name === 'chevron') return <Chevron {...rest} />;
		else if (name === 'close') return <Close />;
		else if (name === 'facebook') return <Facebook />;
		else if (name === 'twitter') return <Twitter />;
	};

	return (
		<div className={className} onClick={onClick}>
			{renderIcon()}
		</div>
	);
};

Icon.propTypes = {
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
	isSelectedIcon: PropTypes.bool,
	onClick: PropTypes.func,
	number: PropTypes.number,
	size: PropTypes.string,
	color: PropTypes.string,
};

Icon.defaultProps = {
	className: '',
	isSelectedIcon: false,
	onClick: () => {},
	number: null,
	size: '',
	color: '',
};

export default Icon;
