import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const Chat = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={80}
		height={80}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 48 40.012"
	>
		<path d="M41 0H27a7 7 0 00-7 7v3a9.01 9.01 0 019 9v5h6l4.805 4.805A.7.7 0 0041 28.31V24a7 7 0 007-7V7a7 7 0 00-7-7z"></path>
		<path d="M20 35h-8l-4.805 4.805A.7.7 0 016 39.31V35a6 6 0 01-6-6V19a6 6 0 016-6h14a6 6 0 016 6v10a6 6 0 01-6 6z"></path>
	</svg>
);

export default Chat;