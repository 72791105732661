import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import Burger from './Burger';
import NavigationLink from './NavigationLink';
import { auth } from 'firebase/app';
import sierraLeoneLogo from 'common/images/logos/sierra-leone-gov.png';
import ukAidLogo from 'common/images/logos/UK_Aid.png';

import './NavigationBar.scss';
import { DOCTYPE_DICTIONARY } from 'common/utils/docType-dictionary';

const NavigationBar = () => {
	const { id, role } = useSelector((state) => state.user);
	const [burgerIsOpen, setBurgerIsOpen] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [_, __, removeCookie] = useCookies([]);
	const isLogged = id;

	const handleLogout = () => {
		auth()
			.signOut()
			.then(() => {
				removeCookie('SLiSL_user_logged');
			});
		handleCloseMenu();
	};

	const handleStateChange = (state) => {
		setBurgerIsOpen(state.isOpen);
	};

	const handleCloseMenu = () => {
		setBurgerIsOpen(false);
	};

	const renderAdmin = () => (
		<div className="admin">
			<span>{'Admin'}</span>
			<div className="admin__content">
				<NavigationLink
					to="/admin/publish"
					title="Publish"
					onClose={handleCloseMenu}
				/>
				{role === 'ADMIN' && (
					<div className="admin__admin-only">
						<NavigationLink
							to="/admin/accounts"
							title="Manage Accounts"
							onClose={handleCloseMenu}
						/>
						<NavigationLink
							to="/admin/map"
							title="Map Admin"
							onClose={handleCloseMenu}
						/>
						<NavigationLink
							to="/admin/events"
							title="Event Admin"
							onClose={handleCloseMenu}
						/>
						<NavigationLink
							to="/admin/gallery"
							title="Gallery Admin"
							onClose={handleCloseMenu}
						/>
						<NavigationLink
							to="/admin/video"
							title="Video Admin"
							onClose={handleCloseMenu}
						/>
					</div>
				)}
			</div>
		</div>
	);

	const renderLogin = () =>
		isLogged ? (
			<span className="logout" onClick={handleLogout}>
				{'Logout'}
			</span>
		) : (
			<NavigationLink to="/login" title="Login" onClose={handleCloseMenu} />
		);

	const renderLinks = () => (
		<div>
			<NavigationLink to="/" title="Home" onClose={handleCloseMenu} />
			<NavigationLink
				to="/about"
				title="About the programme"
				onClose={handleCloseMenu}
			/>
			<NavigationLink
				to="/what-we-do"
				title="What we do"
				onClose={handleCloseMenu}
			/>
			<NavigationLink
				to="/activities"
				title="Activity Map"
				onClose={handleCloseMenu}
			/>
			<NavigationLink to="/events" title="Events" onClose={handleCloseMenu} />
			<div className="media">
				<span>Resources</span>
				<div className="media__content">
					<NavigationLink
						to={`/resources/${DOCTYPE_DICTIONARY.dt2}`}
						title="Success Stories"
						onClose={handleCloseMenu}
					/>
					<NavigationLink
						to={`/resources/${DOCTYPE_DICTIONARY.dt1}`}
						title="Documents"
						onClose={handleCloseMenu}
					/>
					<NavigationLink
						to={`/resources/${DOCTYPE_DICTIONARY.dt3}`}
						title="Annual Reviews"
						onClose={handleCloseMenu}
					/>
					<NavigationLink
						to="/resources/images"
						title="Images"
						onClose={handleCloseMenu}
					/>
					<NavigationLink
						to="/resources/videos"
						title="Videos"
						onClose={handleCloseMenu}
					/>
				</div>
			</div>

			<NavigationLink
				to="/partners"
				title="Partners"
				onClose={handleCloseMenu}
			/>
			{(role === 'EDITOR' || role === 'ADMIN') && renderAdmin()}
			{renderLogin()}
		</div>
	);

	return (
		<div className="navigation">
			<div className="navigation__logos">
				<img
					className="navigation__logos__logo"
					src={sierraLeoneLogo}
					alt="sierra leone logo"
				/>
				<img
					className="navigation__logos__logo"
					src={ukAidLogo}
					alt="uk aid logo"
				/>
			</div>
			<div className="navigation__link-container">{renderLinks()}</div>
			<div className="navigation__burger">
				<Burger handleStateChange={handleStateChange} isOpen={burgerIsOpen}>
					{renderLinks()}
				</Burger>
			</div>
		</div>
	);
};

export default NavigationBar;
