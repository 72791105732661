import { ACCEPT_COOKIES } from '../types/gdprTypes';

const initialState = {
	analytics: false,
	loading: false,
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
	case ACCEPT_COOKIES:
		return {
			...state,
			loading: false,
			analytics: action.payload.analytics,
		};
	default:
		return state;
	}
};

export default userReducer;
