import * as yup from 'yup';

export const userSchema = yup.object().shape({
	email: yup
		.string()
		.email('Enter a valid email')
		.ensure()
		.required('Please enter an email'),
	password: yup
		.string()
		.required('Enter a password')
		.matches(
			/^(?=.*\d).{4,12}$/,
			'Password must be between 4 and 12 digits long and include at least one numeric digit.'
		)
		.ensure(),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref('password')], 'Passwords must match')
		.ensure(),
	isLink: yup
		.bool()
		.oneOf(
			[true],
			'You must arrive at this page from a link you have recieved by email'
		),
});

export const emailSchema = yup.object().shape({
	email: yup.string().email('Valid email required').ensure(),
});

export const eventSchema = yup.object().shape({
	title: yup.string().required('Missing title'),
	description: yup.string().required('Missing description'),
	start: yup.number(),
	end: yup
		.number()
		.moreThan(yup.ref('start'), 'Start date should be before end date'),
});
