import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Heading } from 'common/components';
import { Calendar as CalendarIcon } from 'common/icons';
import { clearError } from 'store/reducers/events';
import { getEvents, getEventError } from 'store/selectors/events';
import { fetchEvents } from 'store/actions/events';
import Calendar from './Calendar';
import EventEditor from './EventEditor';

const WrappedCalendar = ({ isEditor }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const events = useSelector(getEvents);
	const error = useSelector(getEventError);
	const [monthInView, setMonthInView] = useState(
		`${moment().month() + 1}-${moment().year()}`
	);

	const getQueryMonth = () => {
		var results = new RegExp('[?&]' + 'month' + '=([^&#]*)').exec(
			location.search
		);
		if (results == null) {
			return null;
		} else {
			return decodeURI(results[1]) || 0;
		}
	};

	const queryMonth = getQueryMonth();

	useEffect(() => {
		let month = queryMonth;
		if (!queryMonth) {
			month = `${moment().month() + 1}-${moment().year()}`;
		}
		dispatch(fetchEvents(month));
	}, [queryMonth]);

	const handleNavigate = (date) => {
		const navigatedMonth = `${moment(date).month() + 1}-${moment().year()}`;
		if (navigatedMonth !== monthInView) {
			setMonthInView(navigatedMonth);
			history.replace(`${history.location.pathname}?month=${navigatedMonth}`);
		}
	};

	const handleClearError = () => {
		dispatch(clearError());
	};

	return (
		<>
			<div className="events">
				<Heading icon={<CalendarIcon />}>
					{isEditor ? 'Event Admin' : 'Events'}
				</Heading>
			</div>
			{isEditor && <EventEditor monthInView={monthInView} />}
			<Calendar
				isEditor={isEditor}
				events={events}
				error={error}
				handleNavigate={handleNavigate}
				handleClearError={handleClearError}
				monthInView={monthInView}
			/>
		</>
	);
};

WrappedCalendar.propTypes = {
	isEditor: PropTypes.bool,
};

WrappedCalendar.defaultProps = {
	isEditor: false,
};

export default WrappedCalendar;
