import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const Location = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={40}
		height={40}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 32 47.867"
	>
		<path
			d="M16 0C7.16 0 0 7.6 0 16.96c0 8.06 11.83 25.67 15.15 30.45a1.018 1.018 0 001.7 0C20.17 42.63 32 25.02 32 16.96 32 7.6 24.84 0 16 0zm0 23.87a8 8 0 118-8 7.998 7.998 0 01-8 8z"
			data-name="Layer 2"
		></path>
	</svg>
);

export default Location;