import React from 'react';

const PDFIcon = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		x="0"
		y="0"
		width={40}
		enableBackground="new 0 0 503.467 503.467"
		version="1.1"
		viewBox="0 0 503.467 503.467"
		xmlSpace="preserve"
	>
		<path
			fill="#F2EDDA"
			d="M460.067 122.733v341.333c0 18.773-15.36 34.133-34.133 34.133h-358.4c-18.773 0-34.133-15.36-34.133-34.133V37.4c0-18.773 15.36-34.133 34.133-34.133H340.6V88.6c0 18.773 15.36 34.133 34.133 34.133h85.334z"
			transform="translate(5 1)"
		></path>
		<path
			fill="#FFD0A1"
			d="M460.067 122.733h-85.333c-18.773 0-34.133-15.36-34.133-34.133V3.267l119.466 119.466z"
			transform="translate(5 1)"
		></path>
		<path
			fill="#FF7474"
			d="M219.427 345.453c-20.48 33.28-40.96 58.88-40.96 58.88-21.333 21.333-59.733 0-34.133-25.6 16.213-17.066 44.373-27.306 75.093-33.28zm44.373-120.32c4.267 17.92-4.267 45.227-17.92 73.387-16.213-33.28-24.747-65.707-24.747-73.387 0-17.066 35.84-27.306 42.667 0zm-17.92 73.387c6.827 13.653 14.507 28.16 23.893 40.107-16.213 1.707-34.133 3.413-50.347 7.68 8.534-15.36 18.774-31.574 26.454-47.787zm94.72 37.547c17.067 0 31.573 42.667-8.533 42.667-23.893 0-45.227-17.067-62.293-40.107 36.693-3.414 66.559-2.56 70.826-2.56z"
			transform="translate(5 1)"
		></path>
		<path
			fill="#51565F"
			d="M430.933 503.467h-358.4c-21.333 0-38.4-17.067-38.4-38.4V38.4C34.133 17.067 51.2 0 72.533 0H345.6c.853 0 2.56.853 3.413.853l85.333 85.333c1.707 1.707 1.707 4.267 0 5.973-1.707 1.707-4.267 1.707-5.973 0l-84.48-84.48H72.533c-16.213 0-29.867 13.653-29.867 29.867v426.667c0 16.213 13.653 29.867 29.867 29.867h358.4c16.213 0 29.867-13.653 29.867-29.867V127.147h-81.067c-21.333 0-38.4-17.067-38.4-38.4V46.08c0-2.56 1.707-4.267 4.267-4.267s4.267 1.707 4.267 4.267v42.667c0 16.213 13.653 29.867 29.867 29.867h85.333c2.56 0 4.267 1.707 4.267 4.267v341.333c-.001 22.186-17.067 39.253-38.401 39.253zm-268.8-85.334c-7.68 0-15.36-2.56-20.48-7.68-6.827-6.827-10.24-19.627 3.413-34.133 17.067-17.067 44.373-27.307 75.093-34.133 8.533-13.653 17.067-29.013 24.747-43.52-16.213-34.133-23.893-66.56-23.893-73.387 0-11.093 11.947-19.627 23.04-21.333 10.24-.853 23.893 3.413 28.16 19.627 5.12 18.773-4.267 46.08-17.067 74.24 5.973 11.947 12.8 24.747 21.333 35.84 26.453-2.56 50.347-2.56 66.56-2.56h1.707c11.947 0 21.333 14.507 21.333 27.307 0 6.827-2.56 12.8-6.827 17.067-5.12 5.12-12.8 6.827-23.04 6.827-25.6 0-46.933-17.067-64.853-40.107-15.36 1.707-30.72 3.413-45.227 6.827-19.627 30.72-38.4 55.467-40.107 58.027-6.825 8.531-15.358 11.091-23.892 11.091zm52.907-64.853c-25.6 5.973-48.64 15.36-62.293 29.867-8.533 8.533-9.387 16.213-3.413 22.187 6.827 6.827 22.187 7.68 31.573-2.56 0-.854 16.213-21.334 34.133-49.494zm68.267-10.24c15.36 18.773 34.133 32.427 53.76 32.427 7.68 0 13.653-1.707 17.067-5.12 3.413-3.413 4.267-7.68 4.267-10.24 0-9.387-6.827-18.773-12.8-18.773h-2.56c-14.508-.001-35.841-.001-59.734 1.706zm-32.427-34.133c-5.973 11.093-11.947 21.333-17.92 31.573 11.093-1.707 22.187-3.413 33.28-4.267-5.12-8.533-11.093-17.92-15.36-27.306zm-2.56-95.574h-2.56c-8.533.853-15.36 6.827-15.36 12.8 0 7.68 7.68 34.133 20.48 63.147 11.093-23.893 17.92-46.933 13.653-62.293-2.56-11.947-11.946-13.654-16.213-13.654z"
		></path>
	</svg>
);

export default PDFIcon;