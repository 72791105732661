import React from 'react';
import PropTypes from 'prop-types';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import './DatePicker.scss';

const DatePicker = ({ label, date, onChange, minDate, className }) => (
	<div className={className}>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
				variant="inline"
				format="DD MMM yyyy"
				label={label}
				minDate={minDate}
				minDateMessage="This date has already passed"
				value={date}
				autoOk
				onChange={(date) => onChange(date, label)}
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
			/>
		</MuiPickersUtilsProvider>
	</div>
);

DatePicker.propTypes = {
	label: PropTypes.string.isRequired,
	date: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
	onChange: PropTypes.func.isRequired,
	width: PropTypes.number,
	minDate: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
	className: PropTypes.string,
};

DatePicker.defaultProps = {
	width: null,
	minDate: new Date('1900-01-01'),
	className: '',
};

export default DatePicker;
