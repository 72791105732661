import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImagePlaceholder from '../ImagePlaceholder';
import Toast from '../Toast';
import { getDocUrl } from 'common/utils/firebase-tools';
import './DragAndDrop.scss';

const COUNT = 1;
const DEFAULT_ERROR = { show: false, message: '' };

const DragAndDrop = ({ onUpload, formats, file, title, noDrag }) => {
	const drop = useRef(null);
	const drag = useRef(null);
	const [dragging, setDragging] = useState(false);
	const [imagePreview, setImagePreview] = useState('');
	const [error, setError] = useState(DEFAULT_ERROR);

	useEffect(() => {
		if (drop.current) {
			drop.current.addEventListener('dragover', handleDragOver);
			drop.current.addEventListener('drop', handleDrop);
			drop.current.addEventListener('dragenter', handleDragEnter);
			drop.current.addEventListener('dragleave', handleDragLeave);
		}
	}, []);

	useEffect(() => {
		if (file?.downloadUrl) {
			setImagePreview(file.downloadUrl);
		} else if (file?.path) {
			getDocUrl(file.path).then((url) => {
				setImagePreview(url);
			});
		} else if (file) {
			setImagePreview(URL.createObjectURL(file));
		}
	}, [file]);

	const handleDragEnter = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (e.target !== drag.current) {
			setDragging(true);
		}
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (e.target === drag.current) {
			setDragging(false);
		}
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setDragging(false);
		drop.current = null;

		const files = [...e.dataTransfer.files];

		if (COUNT < files.length) {
			console.log(
				`Only ${COUNT} file${COUNT !== 1 ? 's' : ''} can be uploaded at a time`
			);
			return;
		}

		if (
			files.some(
				(selectedFile) =>
					!formats.some((format) => selectedFile.type === format)
			)
		) {
			setError({
				show: true,
				message: `${title} can only be file types: ${fileTypes}`,
			});
			return;
		}

		if (files && files.length) {
			onUpload(files[0]);
		}
	};

	const getFileName = () => {
		if (!file?.name && typeof file === 'string') {
			return file.split('/').pop();
		}
		return file?.name;
	};

	const handleFileSelect = (event) => {
		event.persist();
		const selectedFile = event.target.files[0];
		onUpload(selectedFile);
	};

	const handleFileRemove = () => {
		onUpload(null);
	};

	const fileTypes = formats
		.map((type) => type.split('/')[1].toUpperCase())
		.join(', ');

	return (
		<div className="FilesDragAndDrop">
			<Toast
				show={error.show}
				type="warning"
				onClose={() => setError(DEFAULT_ERROR)}
			>
				{error.message}
			</Toast>
			<span className="FilesDragAndDrop__title">{title}</span>
			{file ? (
				<>
					{title === 'File' ? (
						<span>{getFileName()}</span>
					) : (
						<img className="FilesDragAndDrop__blob" src={imagePreview} />
					)}
					<span className="FilesDragAndDrop__remove" onClick={handleFileRemove}>
						REMOVE
					</span>
				</>
			) : (
				<label ref={drop}>
					{dragging && (
						<div ref={drag} className="FilesDragAndDrop__placeholder" />
					)}
					<div className="FilesDragAndDrop__area">
						<ImagePlaceholder />
						<strong>
							{!noDrag && `Drop your ${title.toLowerCase()} here, or `}
							<span className="FilesDragAndDrop__area__browse">browse</span>
						</strong>
						<span className="FilesDragAndDrop__area__formats">{`Supports: ${fileTypes}`}</span>
					</div>
					<input
						type="file"
						style={{ display: 'none' }}
						accept={formats}
						onChange={handleFileSelect}
					/>
				</label>
			)}
		</div>
	);
};

DragAndDrop.propTypes = {
	onUpload: PropTypes.func.isRequired,
	formats: PropTypes.arrayOf(PropTypes.string).isRequired,
	file: PropTypes.oneOfType([
		PropTypes.shape({
			name: PropTypes.string,
			path: PropTypes.string,
		}),
		PropTypes.string,
	]),
	title: PropTypes.string.isRequired,
};

DragAndDrop.defaultProps = {
	file: null,
};

export default DragAndDrop;
