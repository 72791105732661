import React, { forwardRef, useState, useEffect } from 'react';
import { Heading, Loader, Unavailable, Pagination } from 'common/components';
import { getCollection } from 'common/utils/firebase-tools';
import { AnnualReview } from 'common/icons';
import { DOCTYPES, DOCTYPE_DICTIONARY } from 'common/utils/docType-dictionary';
import './AnnualReviewsList.scss';
import AnnualReviewsCard from './AnnualReviewsCard';

const LIMIT = 3;

const AnnualReviews = ({ onLoadComplete }, ref) => {
	const [loading, setLoading] = useState(false);
	const [annualReviews, setAnnualReviews] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);

	const fetchData = async () => {
		setLoading(true);
		const reviewsResponse = await getCollection(DOCTYPES.REVIEWS);
		if (reviewsResponse) {
			setAnnualReviews(reviewsResponse.data);
		}
		setLoading(false);
		onLoadComplete(DOCTYPE_DICTIONARY.dt3);
	};

	const renderPagination = () => {
		if (loading || Math.ceil(annualReviews.length / 4) <= 1) return null;
		return (
			<div className="success__pagination">
				<Pagination
					pageCount={Math.ceil(annualReviews.length / LIMIT)}
					currentPage={currentPage}
					onChange={(event, page) => setCurrentPage(page)}
				/>
			</div>
		);
	};

	const renderAlternative = () =>
		loading ? <Loader type="bar" /> : <Unavailable />;

	const renderDocument = (docs) => {
		return docs.map((document, index) => {
			const lowestIndex = LIMIT * currentPage - LIMIT;
			const maxIndex = LIMIT * currentPage - 1;
			if (index >= lowestIndex && index <= maxIndex) {
				return (
					<AnnualReviewsCard
						key={document._id}
						id={document._id}
						document={document}
						title={document.title}
						file={document.file}
						organisation={document.organisation}
						publicationDate={document.publication_date}
						tags={document.tags}
						description={document?.description}
						thumbnail={document?.thumbnail}
						shareLink={document?.url}
					/>
				);
			}
			return null;
		});
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div ref={ref} id="reviews">
			<Heading isSubHeading icon={<AnnualReview />}>
				{'Annual Reviews'}
			</Heading>
			{renderPagination()}
			<div className="documents__list">
				{annualReviews.length
					? renderDocument(annualReviews)
					: renderAlternative()}
			</div>
		</div>
	);
};

export default forwardRef(AnnualReviews);
