import React from 'react';
import { montroseBlue } from '../scss/variables/colors.scss';

const FirstAid = () => (
	<svg
		xmlns="https://www.w3.org/2000/svg"
		width={80}
		height={80}
		fill={montroseBlue}
		x="0"
		y="0"
		viewBox="0 0 48 41"
	>
		<path
			d="M46 9H35V2a2.006 2.006 0 00-2-2H15a2.006 2.006 0 00-2 2v7H2a2.006 2.006 0 00-2 2v28a2.006 2.006 0 002 2h44a2.006 2.006 0 002-2V11a2.006 2.006 0 00-2-2zM18 5h12v4H18zm2.27 22.27a2.502 2.502 0 01-1.77.73H16v2.5a2.5 2.5 0 01-5 0V28H8.5a2.5 2.5 0 010-5H11v-2.5a2.5 2.5 0 115 0V23h2.5a2.503 2.503 0 011.77 4.27z"
			data-name="Layer 2"
		></path>
	</svg>
);

export default FirstAid;