import React from 'react';
import './SavingBanner.scss';

const SavingBanner = () => (
	<div className="saving-lives">
    Saving Lives in Sierra Leone
	</div>
);

export default SavingBanner;
