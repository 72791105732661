import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './NavigationLink.scss';

const NavigationLink = ({ to, onClose, title }) => (
	<NavLink 
		className="navigation-link"
		exact
		to={to}
		activeClassName="navigation-link--active"
		onClick={onClose}
	>
		{title}
	</NavLink>
);

NavigationLink.propTypes = {
	to: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired
};

export default NavigationLink;
