import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import { Modal, Button, Toast, DeleteModal } from 'common/components';
import { StepOne } from './StepOne';
import { StepTwo, NOW } from './StepTwo';
import { StepThree } from './StepThree';
import { StepFour } from './StepFour';
import { modifyEvent, deleteEvent } from 'store/actions/events';
import { momentToUnix } from 'common/utils/format-date';
import { eventSchema } from 'common/yup/schemas';
import './EventAddEdit.scss';

const EventAddEditContainer = ({ event, show, onClose, monthInView }) => {
	const dispatch = useDispatch();
	const { _id, documents } = event;
	const [activeStep, setActiveStep] = useState(1);
	const [title, setTitle] = useState(event?.title || '');
	const [description, setDescription] = useState(event?.description || '');
	const [allDay, setAllDay] = useState(event?.allDay || false);
	const [starts, setStarts] = useState(event?.start || NOW);
	const [ends, setEnds] = useState(event?.end || NOW);
	const [file, setFile] = useState(
		(!!documents?.length && documents[0]) || null
	);
	const [emails, setEmails] = useState(event?.emails || []);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isDeleted, setIsDeleted] = useState(false);
	const [notification, setNotification] = useState({
		quantity: 10,
		unit: 'minutes',
	});
	const [validationError, setValidationError] = useState('');

	useEffect(() => {
		if (isDeleted) {
			onClose();
		}
	}, [isDeleted]);

	const propsToParse = {
		1: {
			title,
			setTitle,
			description,
			setDescription,
			validationError,
			setValidationError,
		},
		2: {
			allDay,
			setAllDay,
			starts,
			setStarts,
			ends,
			setEnds,
		},
		3: {
			file,
			setFile,
		},
		4: {
			emails,
			setEmails,
			notification,
			setNotification,
		},
	};

	const isLastStep = activeStep === Object.keys(propsToParse).length;

	const handleSubmit = async () => {
		setLoading(true);
		try {
			const method = _id ? 'update' : 'create';
			const notifications = emails.length ? [notification] : [];
			const data = {
				eventId: _id || v4(),
				title,
				start: momentToUnix(starts),
				end: momentToUnix(ends),
				allDay,
				description,
				emails,
				documents: [file],
				notifications,
			};
			await dispatch(modifyEvent({ data, method, monthInView }));
			setLoading(false);
			onClose(false);
		} catch (err) {
			setError(true);
			setLoading(false);
		}
	};

	const handleDeleteEvent = async () => {
		await dispatch(deleteEvent({ eventId: _id, monthInView }));
		setIsDeleted(true);
	};

	const handleCloseModal = () => {
		setActiveStep(1);
		onClose(false);
	};

	const handleCtaClick = async () => {
		if (isLastStep) {
			eventSchema
				.validate({
					title,
					description,
					start: momentToUnix(starts),
					end: momentToUnix(ends),
				})
				.then(() => {
					handleSubmit();
				})
				.catch(({ errors }) => {
					setValidationError(errors[0]);
				});
		} else {
			setActiveStep(activeStep + 1);
		}
	};

	const renderActiveStep = () => {
		if (activeStep === 2) {
			return <StepTwo {...propsToParse[2]} />;
		} else if (activeStep === 3) {
			return <StepThree {...propsToParse[3]} />;
		} else if (activeStep === 4) {
			return <StepFour {...propsToParse[4]} />;
		}
		return <StepOne {...propsToParse[1]} />;
	};

	const renderButtons = () => {
		const ctaText = isLastStep ? 'submit' : 'continue';
		return (
			<div className="add-event__cta">
				<Button
					disabled={activeStep === 1}
					onClick={() => setActiveStep(activeStep - 1)}
				>
					{'<<'}
				</Button>
				{!!_id && (
					<Button warning onClick={() => setShowDeleteModal(true)}>
						{'Delete'}
					</Button>
				)}
				<div className="add-event__cta__submit">
					<Button loading={loading} onClick={handleCtaClick}>
						{ctaText}
					</Button>
					{isLastStep && validationError && (
						<span className="add-event__cta__validation-error">
							{validationError}
						</span>
					)}
				</div>
			</div>
		);
	};

	const modalTitle = `${_id ? 'Edit' : 'Add'} event`;

	return (
		<>
			<Toast type="error" show={error} onClose={() => setError(false)}>
				{'Error saving event. Please try again later.'}
			</Toast>
			<DeleteModal
				showModal={showDeleteModal}
				onModalClose={() => setShowDeleteModal(false)}
				onDelete={handleDeleteEvent}
			/>
			<Modal title={modalTitle} show={show} handleModalClose={handleCloseModal}>
				<div className="add-event">
					{renderActiveStep()}
					{renderButtons()}
				</div>
			</Modal>
		</>
	);
};

EventAddEditContainer.propTypes = {
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	event: PropTypes.shape({
		_id: PropTypes.string,
		documents: PropTypes.array,
	}),
	monthInView: PropTypes.shape({}).isRequired,
};

EventAddEditContainer.defaultProps = {
	event: {},
	documents: null,
};

export default EventAddEditContainer;
