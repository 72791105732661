import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Iubenda from 'react-iubenda-policy';
import { useCookies } from 'react-cookie';
import Button from './Button';
import { setAcceptCookies } from 'store/actions/gdprActions';
import './CookieBanner.scss';

const CookieBanner = () => {
	const dispatch = useDispatch();
	const [showBanner, setShowBanner] = useState(true);
	const [showSettings, setShowSettings] = useState(false);
	const [allowAnalytics, setAllowAnalytics] = useState(false);
	const [cookies, setCookie] = useCookies([]);

	useEffect(() => {
		if (cookies?.SLiSL_cookie_banner_seen && showBanner) {
			setShowBanner(false);
		}
	}, [cookies]);

	const acceptCookieClick = () => {
		dispatch(setAcceptCookies({ analytics: allowAnalytics }));
		setShowBanner(false);
		setCookie('SLiSL_cookie_banner_seen', true);
	};

	const toggleCookieSettings = () => {
		setShowSettings(!showSettings);
	};

	if (!showBanner) return null;

	const settingsCtaText = showSettings
		? 'Use default settings'
		: 'Adjust Cookie Settings';
	return (
		<div id="iubenda">
			<div id="iubenda__container">
				<div className="cookie">
					<p>
						<strong>{'This website uses cookies.'}</strong>
					</p>
					<p>
						{
							'Saving Lives in Sierra Leone uses cookies on savinglivesinsierraleone.org to analyse usage of the website for a better user experience, and to enable content sharing on social media. Cookies may be placed by third parties. Find out more information in our '
						}
						<Iubenda id={36793374} type="cookie">
							{'cookie policy'}
						</Iubenda>
						{'.'}
					</p>
					{showSettings && (
						<div className="cookie__settings-container">
							<strong>{'We use cookies: '}</strong>
							<label className="cookie__settings-container__checkbox">
								<input type="checkbox" checked disabled />
								<strong>{' Functional cookies: '}</strong>
								{
									'This category of cookies is needed to ensure the website works well.'
								}
							</label>
							<label className="cookie__settings-container__checkbox">
								<input
									type="checkbox"
									checked={allowAnalytics}
									onChange={() => setAllowAnalytics(!allowAnalytics)}
								/>
								<strong>{' Analytics cookies: '}</strong>
								{
									'These cookies are needed to see how users use our website and what we can do to improve.'
								}
							</label>
						</div>
					)}
					<div className="cookie__btn-container">
						<Button
							className="cookie__btn-container__adjust"
							outlined
							onClick={toggleCookieSettings}
						>
							{settingsCtaText}
						</Button>
						<Button lightBlue onClick={acceptCookieClick}>
							{'I Accept'}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CookieBanner;
